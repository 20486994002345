import * as Yup from 'yup';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, InputAdornment, Link } from '@mui/material';

import { RouterLink } from 'src/routes/components';

import { useAppDispatcher } from 'src/redux/store';
import { RESTAURANT_LOCATION_ID } from 'src/config-global';
import {
  createRestaurantItem,
  TCreateRestaurantItem,
  updateRestaurantItem,
} from 'src/redux/slices/restaurant';

import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFTextField } from 'src/components/hook-form';

import { IRestaurantItem } from 'src/types/restaurant';

import { paths } from '../../routes/paths';

export type ConditionalSchema<T> = T extends string
  ? Yup.StringSchema
  : T extends number
  ? Yup.NumberSchema
  : T extends boolean
  ? Yup.BooleanSchema
  : T extends Record<any, any>
  ? Yup.AnyObjectSchema
  : T extends Array<any>
  ? Yup.ArraySchema<any, any>
  : Yup.AnySchema;

export type Shape<Fields> = {
  [Key in keyof Fields]: ConditionalSchema<Fields[Key]>;
};

type Props = {
  currentRestaurantItem?: IRestaurantItem;
};

export default function RestaurantNewEditForm({ currentRestaurantItem }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useAppDispatcher();

  const NewRestaurantItemSchema = Yup.object<Shape<TCreateRestaurantItem>>().shape({
    itemName: Yup.string().required('Item name is required'),
    price: Yup.number().min(1).required('Selling price is required'),
    location: Yup.array(Yup.string().required('Item location is required')).min(1).default([]),
  });

  const defaultValues = useMemo(
    () => ({
      itemName: currentRestaurantItem?.itemName || '',
      price: currentRestaurantItem?.price || 0,
      location: currentRestaurantItem?.location.map((location) => location._id) || [
        RESTAURANT_LOCATION_ID,
      ],
    }),
    [currentRestaurantItem]
  );

  const methods = useForm({
    resolver: yupResolver(NewRestaurantItemSchema),
    defaultValues,
    mode: 'onChange',
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    if (currentRestaurantItem) {
      // update
      try {
        await dispatch(
          updateRestaurantItem({
            inventoryId: currentRestaurantItem._id,
            updatingRestaurantItem: {
              ...data,
              itemName: data.itemName.trimStart().replace(/^\w/, (c) => c.toUpperCase()),
            },
          })
        ).unwrap();

        enqueueSnackbar('Restaurant item edit success');
        navigate(paths.dashboard.restaurant.root());
        reset();
      } catch (e) {
        if (typeof e === 'string') {
          enqueueSnackbar(e, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(e?.message, {
            variant: 'error',
          });
        }
      }
    } else {
      try {
        await dispatch(
          createRestaurantItem({
            newInventory: {
              ...data,
              itemName: data.itemName.trimStart().replace(/^\w/, (c) => c.toUpperCase()),
            },
          })
        ).unwrap();

        enqueueSnackbar('Restaurant item creation success');
        navigate(paths.dashboard.restaurant.root());
        reset();
      } catch (e) {
        if (typeof e === 'string') {
          enqueueSnackbar(e, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(e?.message, {
            variant: 'error',
          });
        }
      }
    }
  });

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <Card sx={{ p: 3 }}>
            <Grid container spacing={2}>
              <Grid
                mb={1}
                display="flex"
                mt={2}
                alignItems="center"
                justifyContent="space-between"
                width={1}
              >
                <Typography variant="subtitle2">Basic Information</Typography>
              </Grid>

              <Grid xs={12} md={6}>
                <RHFTextField required name="itemName" label="Item name" />
              </Grid>

              <Grid xs={12} md={6}>
                <RHFTextField
                  required
                  name="price"
                  label="Selling Price"
                  type="number"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">Rs.</InputAdornment>,
                  }}
                />
              </Grid>
            </Grid>

            <Stack
              direction={{
                xs: 'column',
                sm: 'row',
              }}
              justifyContent={{
                xs: 'unset',
                sm: 'end',
              }}
              gap={2}
              mt={4}
            >
              <Link
                component={RouterLink}
                href={paths.dashboard.restaurant.root()}
                sx={{
                  width: {
                    xs: 1,
                    sm: 'auto',
                  },
                }}
              >
                <Button
                  variant="outlined"
                  color="inherit"
                  sx={{
                    width: 1,
                  }}
                >
                  Cancel
                </Button>
              </Link>

              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {!currentRestaurantItem ? 'Create Restaurant Item' : 'Save Changes'}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}

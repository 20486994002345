import { AxiosError, HttpStatusCode } from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RESTAURANT_LOCATION_ID, UNIT_ID } from 'src/config-global';

import { ILocationListItem } from 'src/types/location';
import { IRestaurantHistory, IRestaurantItem, IRestaurantListItem } from 'src/types/restaurant';

import axios from '../../utils/axios';

interface IInitialState {
  status: 'idle' | 'loading' | 'success' | 'failure';
  error: string | undefined;
  restaurant: {
    count: number;
    page: number;
    pageSize: number;
    pageCount: number;
    items: IRestaurantListItem[];
  };
  item: IRestaurantItem | null;
  transactions: {
    status: 'idle' | 'loading' | 'success' | 'failure';
    error: string | undefined;
    transactions: {
      count: number;
      page: number;
      pageSize: number;
      pageCount: number;
      items: IRestaurantHistory[];
    };
  };
}

const initialState: IInitialState = {
  status: 'idle',
  error: undefined,
  restaurant: {
    count: 0,
    page: 1,
    pageSize: 10,
    pageCount: 0,
    items: [],
  },
  item: null,
  transactions: {
    status: 'idle',
    error: undefined,
    transactions: {
      count: 0,
      page: 1,
      pageSize: 10,
      pageCount: 0,
      items: [],
    },
  },
};

const restaurant = createSlice({
  name: 'restaurant',
  initialState,
  reducers: {
    removeRestaurantItem: (state, action) => {
      const { itemId } = action.payload;

      const index = state.restaurant.items.findIndex((item) => item._id === itemId);

      if (index > -1) {
        action.payload.index = index; // Store the index for rollback
        state.restaurant.items = state.restaurant.items.filter((item) => item._id !== itemId);
      }
    },
    rollbackRestaurantItemDeletion: (state, action) => {
      const { item, index } = action.payload;

      state.restaurant.items.splice(index, 0, item);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRestaurantItems.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getRestaurantItems.fulfilled, (state, action) => {
        state.status = 'success';
        state.restaurant = action.payload;
      })
      .addCase(getRestaurantItems.rejected, (state, action) => {
        state.status = 'failure';
        state.error = action.error.message;
      })
      .addCase(getRestaurantItemById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getRestaurantItemById.fulfilled, (state, action) => {
        state.status = 'success';
        state.item = action.payload;
      })
      .addCase(getRestaurantItemById.rejected, (state, action) => {
        state.status = 'failure';
        state.error = action.error.message;
      });
  },
});

export default restaurant.reducer;

export const { removeRestaurantItem, rollbackRestaurantItemDeletion } = restaurant.actions;

export const getRestaurantItems = createAsyncThunk(
  'restaurant/getInventory',
  async (
    params: {
      page?: number;
      limit?: number;
      generalSearch?: string | null;
      itemName?: string | null;
      itemCode?: string | null;
      minQuantity?: number | null;
      maxQuantity?: number | null;
      minPrice?: number | null;
      maxPrice?: number | null;
      suppliers?: string[];
      supplierSeparator?: ',' | '|';
      units?: string[];
      unitsSeparator?: ',' | '|';
      alertSent?: string | null;
      availability?: string | null;
      alertEnabled?: boolean | null;
      sortBy?: string | null;
      sort?: 'asc' | 'desc' | null;
      status?: 'active' | 'disabled' | null;
    } = {
      page: 1,
      limit: 10,
      suppliers: [],
      supplierSeparator: ',',
      units: [],
      unitsSeparator: ',',
      generalSearch: null,
      alertEnabled: null,
      itemName: null,
      itemCode: null,
      maxPrice: null,
      maxQuantity: null,
      minPrice: null,
      minQuantity: null,
      alertSent: null,
      availability: null,
      sort: 'desc',
      sortBy: 'updatedAt',
      status: null,
    }
  ) => {
    const {
      page,
      limit,
      generalSearch,
      itemName,
      itemCode,
      minQuantity,
      maxQuantity,
      minPrice,
      maxPrice,
      suppliers,
      supplierSeparator,
      units,
      unitsSeparator,
      alertEnabled,
      alertSent,
      availability,
      sortBy,
      sort,
      status,
    } = params;

    try {
      const response = await axios.get(
        `inventory/restaurant/item?page=${page ?? ''}&limit=${limit ?? ''}&generalSearch=${
          generalSearch ?? ''
        }&itemName=${itemName ?? ''}&itemCode=${itemCode ?? ''}&minQuantity=${
          minQuantity ?? ''
        }&maxQuantity=${maxQuantity ?? ''}&minPrice=${minPrice ?? ''}&maxPrice=${
          maxPrice ?? ''
        }&suppliers=${
          suppliers ? suppliers.join(supplierSeparator) : ''
        }&locations=${RESTAURANT_LOCATION_ID}&units=${
          units ? units.join(unitsSeparator) : ''
        }&alertEnabled=${alertEnabled ?? ''}&alertSent=${alertSent ?? ''}&availability=${
          availability ?? ''
        }&sortBy=${sortBy}&sort=${sort ?? ''}&group=&createdBy=&status=${status ?? ''}`
      );

      return response.data.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const getRestaurantItemById = createAsyncThunk(
  'restaurant/getInventoryById',
  async (params: { itemID: string }) => {
    try {
      const { itemID } = params;
      const response = await axios.get(`inventory/restaurant/item/${itemID}`);

      return response.data.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const deleteRestaurantItem = createAsyncThunk(
  'restaurant/deleteInventory',
  async (params: { item: IRestaurantListItem; index: number }, { dispatch }) => {
    const { item, index } = params;

    try {
      const response = await axios.delete(`inventory/restaurant/item/${item._id}`);
      return response.data.data;
    } catch (err) {
      if (err instanceof AxiosError && err.response?.status === HttpStatusCode.FailedDependency) {
        dispatch(rollbackRestaurantItemDeletion({ item, index }));
        throw new AxiosError(err.response.data.message);
      }

      throw err;
    }
  }
);

export const disableRestaurantItem = createAsyncThunk(
  'restaurant/disableKitchenItem',
  async (params: { item: any; index: number }, { dispatch }) => {
    const { item, index } = params;
    try {
      const response = await axios.put(`inventory/restaurant/item/${item._id}`, {
        ...item,
        itemName: item.itemName,
        unit: item.unit._id,
        location: item.location.map((location: ILocationListItem) => location._id),
        status: 'disabled',
      });
      return response.data.data;
    } catch (err) {
      dispatch(rollbackRestaurantItemDeletion({ item, index }));
      throw err;
    }
  }
);

export const reActivateRestaurantItem = createAsyncThunk(
  'restaurant/reActivateKitchenItem',
  async (params: { item: any }) => {
    const { item } = params;
    const response = await axios.put(`inventory/restaurant/item/${item._id}`, {
      ...item,
      itemName: item.itemName,
      unit: item.unit._id,
      location: item.location.map((location: ILocationListItem) => location._id),
      status: 'active',
    });
    return response.data.data;
  }
);

export interface TCreateRestaurantItem
  extends Omit<
    IRestaurantItem,
    '_id' | 'itemCode' | 'createdAt' | 'updatedAt' | 'updatedBy' | 'location'
  > {
  location: string[];
}

export const createRestaurantItem = createAsyncThunk(
  'restaurant/createKitchenItem',
  async (params: { newInventory: TCreateRestaurantItem }) => {
    const { newInventory } = params;
    const response = await axios.post(`inventory/restaurant/item`, {
      ...newInventory,
      suppliers: [],
      unit: UNIT_ID,
      quantity: 0,
    });
    return response.data.data;
  }
);

export const updateRestaurantItem = createAsyncThunk(
  'restaurant/updateInventory',
  async (params: { inventoryId: string; updatingRestaurantItem: TCreateRestaurantItem }) => {
    const { updatingRestaurantItem, inventoryId } = params;
    const response = await axios.put(`inventory/restaurant/item/${inventoryId}`, {
      ...updatingRestaurantItem,
      suppliers: [],
      unit: UNIT_ID,
      quantity: 0,
    });
    return response.data.data;
  }
);

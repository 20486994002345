import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { BAR_LOCATION_ID } from 'src/config-global';

import axios from '../../utils/axios';

export interface IMonthlySalesItem {
  _id: string;
  itemName: string;
  itemCode: string;
  totalQuantity: number;
  totalAmount: number;
}

interface IInitialState {
  summary: {
    status: 'idle' | 'loading' | 'success' | 'failure';
    error: string | undefined;
    data: IMonthlySalesItem[];
  };
}

const initialState: IInitialState = {
  summary: {
    status: 'idle',
    error: undefined,
    data: [],
  },
};

const monthlySalesQuantities = createSlice({
  name: 'monthlySalesQuantities',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMonthlySalesByQuantities.pending, (state) => {
        state.summary.status = 'loading';
      })
      .addCase(getMonthlySalesByQuantities.fulfilled, (state, action) => {
        state.summary.status = 'success';
        state.summary.data = action.payload;
      })
      .addCase(getMonthlySalesByQuantities.rejected, (state, action) => {
        state.summary.status = 'failure';
        state.summary.error = action.error.message;
      });
  },
});

export default monthlySalesQuantities.reducer;

export const getMonthlySalesByQuantities = createAsyncThunk(
  'monthlySalesQuantities',
  async (params: { startDate: string; endDate: string }) => {
    const { startDate, endDate } = params;
    try {
      const response = await axios.get(
        `inventory/analytics/transaction-item-amounts?startDate=${startDate}&endDate=${endDate}&transactionType=dispatch&locations=${BAR_LOCATION_ID}&limit=5&sort=desc&sortBy=totalQuantity`
      );

      return response.data.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

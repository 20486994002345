import * as Yup from 'yup';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { enqueueSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, InputAdornment, Link, Table, TableBody, TableContainer } from '@mui/material';

import { RouterLink } from 'src/routes/components';

import { fCurrencyRupees } from 'src/utils/format-number';
import { PAYMENT_METHOD, TABLE_HEAD_CELL_TYPE } from 'src/utils/common-types';

import { useAppDispatcher } from 'src/redux/store';
import {
  barPurchaseOrderMakePayment,
  TCreateBarPurchaseOrderPayment,
  TCreateBarPurchaseOrderPaymentRow,
  updateBarPurchaseDiscount,
} from 'src/redux/slices/bar-purchase-order';

import Scrollbar from 'src/components/scrollbar';
import { TableHeadCustom } from 'src/components/table';
import FormProvider, { RHFTextField } from 'src/components/hook-form';

import { IBarPurchaseOrderDetailedItem } from 'src/types/bar-purchase';

import { paths } from '../../routes/paths';
import BarPurchasePaymentRow from './bar-purchase-payment-row';

const BarPurchaseOrderPaymentSchema = Yup.object<Shape<TCreateBarPurchaseOrderPayment>>().shape({
  subTotal: Yup.number().required(),
  totalAfterDiscount: Yup.number()
    .typeError('')
    .min(0, 'Total price cannot be negative')
    .required('Total price is required'),
  roundOffTotal: Yup.number()
    .typeError('')
    .min(0, 'Total price cannot be negative')
    .required('Total price is required'),
  paidAmount: Yup.number().required(),
  discount: Yup.number()
    .typeError('')
    .min(0, 'Discount cannot be negative')
    .required('Discount is required'),
  payments: Yup.array().of(
    Yup.object<Shape<TCreateBarPurchaseOrderPaymentRow>>().shape({
      amount: Yup.number()
        .typeError('')
        .moreThan(0, 'Amount cannot be negative')
        .required('Amount is required'),
      method: Yup.mixed<PAYMENT_METHOD>()
        .oneOf(Object.values(PAYMENT_METHOD))
        .required('Payment method is required'),
      paymentDate: Yup.date()
        .typeError('Invalid payment date')
        .required('Payemnt date is required'),
    })
  ),
});

const PAYMENT_HEADERS: TABLE_HEAD_CELL_TYPE[] = [
  { id: 'itemNo', label: '#', align: 'left', primary: true },
  {
    id: 'paymentDate',
    label: 'Payment Date',
    align: 'left',
    primary: true,
  },
  { id: 'amount', label: 'Amount', align: 'left', primary: true },
  { id: 'method', label: 'Payment Method', align: 'left', primary: true },
  { id: '', primary: true },
];

export type ConditionalSchema<T> = T extends string
  ? Yup.StringSchema
  : T extends number
  ? Yup.NumberSchema
  : T extends boolean
  ? Yup.BooleanSchema
  : T extends Record<any, any>
  ? Yup.AnyObjectSchema
  : T extends Array<any>
  ? Yup.ArraySchema<any, any>
  : Yup.AnySchema;

export type Shape<Fields> = {
  [Key in keyof Fields]: ConditionalSchema<Fields[Key]>;
};

type Props = {
  currentBarPurchaseItem: IBarPurchaseOrderDetailedItem;
};

export default function BarPurchaseNewPaymentForm({ currentBarPurchaseItem }: Props) {
  const dispatch = useAppDispatcher();
  const navigate = useNavigate();

  const defaultValues = useMemo<TCreateBarPurchaseOrderPayment>(
    () => ({
      subTotal: calculateSubTotal(currentBarPurchaseItem),
      discount: currentBarPurchaseItem.discount,
      roundOffTotal: Math.round(currentBarPurchaseItem.totalAfterDiscount),
      totalAfterDiscount: currentBarPurchaseItem.totalAfterDiscount,
      paidAmount: currentBarPurchaseItem.paidAmount,
      payments: [
        {
          amount: Math.round(currentBarPurchaseItem.totalAfterDiscount),
          method: PAYMENT_METHOD.Cash,
          paymentDate: new Date(),
          isNew: true,
          _id: '',
        },
      ],
    }),
    [currentBarPurchaseItem]
  );

  const methods = useForm({
    resolver: yupResolver(BarPurchaseOrderPaymentSchema),
    defaultValues,
    mode: 'onChange',
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    setValue,
    watch,
  } = methods;

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'payments',
  });

  const payments = useWatch({
    control,
    name: 'payments',
  });

  const watchDiscount = useWatch({
    control,
    name: 'discount',
  });

  const getRemainingValue = () => {
    if (payments) {
      return (
        Math.round(watch('totalAfterDiscount')) -
        payments.map((item) => item.amount).reduce((a, b) => a + b, 0)
      );
    }

    return Math.round(watch('totalAfterDiscount'));
  };

  const addAnotherPayment = () => {
    append({
      amount: getRemainingValue(),
      method: PAYMENT_METHOD.Cash,
      paymentDate: new Date(),
    });
  };

  const onSubmit = handleSubmit(async (formData) => {
    try {
      if (currentBarPurchaseItem.discount !== watchDiscount) {
        await dispatch(
          updateBarPurchaseDiscount({
            purchaseOrderId: currentBarPurchaseItem._id,
            transaction: currentBarPurchaseItem,
            discount: formData.discount,
            totalAfterDiscount: formData.roundOffTotal,
          })
        ).unwrap();
      }

      const createPromises = formData.payments?.map((payment) =>
        dispatch(
          barPurchaseOrderMakePayment({
            payment: { ...payment, isNew: true },
            purchaseOrderId: currentBarPurchaseItem._id,
            supplier: currentBarPurchaseItem.supplier?._id,
          })
        ).unwrap()
      );

      if (createPromises) {
        await Promise.all(createPromises);
      }

      enqueueSnackbar('Successfully created payments');
      navigate(paths.dashboard.bar.purchase.root());
    } catch (e) {
      enqueueSnackbar('Error while making payments. Please try again.', {
        variant: 'error',
      });
    }
  });

  useEffect(() => {
    setValue('totalAfterDiscount', calculateSubTotal(currentBarPurchaseItem) - watchDiscount);

    setValue(
      'roundOffTotal',
      Math.round(calculateSubTotal(currentBarPurchaseItem) - watchDiscount)
    );
  }, [setValue, watchDiscount, currentBarPurchaseItem]);

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <Card
            sx={{
              p: 3,
            }}
          >
            <Grid container spacing={4}>
              <Grid mb={1} mt={2} xs={12} md={4}>
                <Typography variant="subtitle2">Bill Details</Typography>

                <Stack direction="row" alignItems="center" justifyContent="space-between" mt={2}>
                  <Typography variant="body2">Item Count</Typography>
                  <Typography variant="subtitle2" color="text.disabled">
                    {currentBarPurchaseItem.items.length < 10
                      ? `0${currentBarPurchaseItem.items.length}`
                      : currentBarPurchaseItem.items.length}
                  </Typography>
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="space-between" mt={1}>
                  <Typography variant="body2">Sub Total</Typography>
                  <Typography variant="subtitle2" color="text.disabled">
                    {fCurrencyRupees(calculateSubTotal(currentBarPurchaseItem) * 100)}
                  </Typography>
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="space-between" mt={1}>
                  <Typography variant="body2">Discount</Typography>
                  <RHFTextField
                    name="discount"
                    size="small"
                    type="number"
                    placeholder="0.00"
                    inputProps={{
                      style: {
                        textAlign: 'right',
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box sx={{ typography: 'subtitle2', color: 'text.disabled' }}>Rs.</Box>
                        </InputAdornment>
                      ),
                    }}
                    sx={{ maxWidth: { md: 150 } }}
                  />
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="space-between" mt={1}>
                  <Typography variant="body2">Total</Typography>
                  <Typography variant="subtitle2" color="text.disabled">
                    {fCurrencyRupees(Number(watch('totalAfterDiscount')) * 100)}
                  </Typography>
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="space-between" mt={1}>
                  <Typography variant="body2">Total After Discount</Typography>
                  <Typography variant="subtitle2">
                    {fCurrencyRupees(watch('roundOffTotal') * 100)}
                  </Typography>
                </Stack>
              </Grid>

              <Grid mb={1} mt={2} xs={12} md={8}>
                <Typography variant="subtitle2">Payment Details</Typography>

                <TableContainer sx={{ mt: 2, width: 1 }}>
                  <Scrollbar>
                    <Table stickyHeader size="small" sx={{ width: 1 }}>
                      <TableHeadCustom headLabel={PAYMENT_HEADERS} />
                      <TableBody>
                        {fields.map((item, index) => (
                          <BarPurchasePaymentRow
                            key={item.id}
                            index={index}
                            remove={(itemIndex) => {
                              remove(itemIndex);
                            }}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </Scrollbar>
                </TableContainer>

                {getRemainingValue() > 0 && (
                  <Stack direction="row" mt={4} alignItems="center" justifyContent="space-between">
                    <Stack direction="column" gap={1}>
                      <Typography variant="subtitle2">Due Amount</Typography>
                      <Typography variant="subtitle2">
                        {fCurrencyRupees(getRemainingValue() * 100)}
                      </Typography>
                    </Stack>

                    <Button variant="soft" color="primary" onClick={addAnotherPayment}>
                      {payments?.length === 0 ? 'Add Payment' : 'Add Another Payment'}
                    </Button>
                  </Stack>
                )}
              </Grid>
            </Grid>

            <Stack
              direction={{
                xs: 'column',
                sm: 'row',
              }}
              justifyContent={{
                xs: 'unset',
                sm: 'end',
              }}
              gap={2}
              mt={4}
            >
              <Link
                component={RouterLink}
                href={paths.dashboard.bar.purchase.root()}
                sx={{
                  width: {
                    xs: 1,
                    sm: 'auto',
                  },
                }}
              >
                <Button
                  variant="outlined"
                  color="inherit"
                  sx={{
                    width: 1,
                  }}
                >
                  Cancel
                </Button>
              </Link>

              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                Save
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}

function calculateSubTotal(currentBarPurchaseItem: IBarPurchaseOrderDetailedItem): number {
  let subTotal = 0;

  currentBarPurchaseItem.items.forEach((item) => {
    subTotal += item.totalAfterDiscount;
  });

  return subTotal;
}

import { TRANSACTION_TYPE } from 'src/utils/common-types';

import { PaymentDueStatus } from './common';
import { ICommonInventoryItem } from './item';
import { ILocationListItem } from './location';

export type ISupplierTableFilterValue = string | string[];

export type ISupplierTableFilters = {
  generalSearch: string;
  status: 'active' | 'disabled';
};

export type ISupplierTableSort = {
  sortBy: string;
  order: 'asc' | 'desc';
};

export const SUPPLIER_DEBT_STATUS_OPTIONS = [
  { value: PaymentDueStatus.OUTSTANDING, label: 'Outstanding' },
  { value: PaymentDueStatus.SETTLED, label: 'Settled' },
];

export interface ISupplierListItem {
  _id: string;
  supplierName: string;
  contactPerson: string;
  createdAt: string;
  email: string;
  phoneNumber: string;
  status: string;
  updatedAt: string;
  location: ILocationListItem[];
}

export interface ISupplierItem {
  _id: string;
  supplierName: string;
  contactPerson: string;
  email: string | null;
  phoneNumber: string | null;
  secondaryPhoneNumber: string | null;
  status: string;
  createdAt: string;
  updatedAt: string;
  inventoryItems: ICommonInventoryItem[];
  location: ILocationListItem[];
  totalTransactionAmount?: number;
  totalPaidAmount?: number;
}

export interface ISupplierPaymentHistory {
  _id: string;
  amount: number;
  updatedAt: string;
  paymentMethod: string;
  paymentDate: string;
  inventoryTransaction: string;
  inventoryTransactionType: TRANSACTION_TYPE;
  transactionDetails: {
    _id: string;
    transactionCode?: string;
    location: string;
  };
}

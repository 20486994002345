import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  InputAdornment,
  Link,
  Table,
  TableBody,
  TableContainer,
  TextField,
  Tooltip,
} from '@mui/material';

import { RouterLink } from 'src/routes/components';

import { useBoolean } from 'src/hooks/use-boolean';

import { fAPIDate } from 'src/utils/format-time';
import { fCurrencyRupees } from 'src/utils/format-number';
import {
  PAYMENT_METHOD,
  paymentMethodStringToEnum,
  TABLE_HEAD_CELL_TYPE,
  TRANSACTION_STATUS,
  TRANSACTION_TYPE,
} from 'src/utils/common-types';

import { useAuthContext } from 'src/auth/hooks';
import { getBarItems } from 'src/redux/slices/bar';
import { useAppDispatcher, useAppSelector } from 'src/redux/store';
import { getPaginatedSuppliers } from 'src/redux/slices/suppliers';
import { BAR_LOCATION_ID, UNIT_ID, VITE_DEFAULT_SUPPLIER } from 'src/config-global';
import {
  barPurchaseOrderDeletePayment,
  barPurchaseOrderMakePayment,
  barPurchaseOrderUpdatePayment,
  createBarPurchaseOrder,
  TCreateBarPurchaseOrder,
  TCreateBarPurchaseOrderItem,
  TCreateBarPurchaseOrderPaymentRow,
  updateBarPurchaseOrder,
  updateCompletedBarPurchaseOrder,
} from 'src/redux/slices/bar-purchase-order';

import Scrollbar from 'src/components/scrollbar';
import { TableHeadCustom } from 'src/components/table';
import { ConfirmDialog } from 'src/components/custom-dialog';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import CustomDatePicker from 'src/components/custom-datepicker/custom-datepicker';

import { DocumentStatus } from 'src/types/common';
import { ISupplierListItem } from 'src/types/supplier';
import {
  BarPurchaseOrdersStatus,
  IBarPurchaseOrderDetailedItem,
  IBarPurchaseOrderPayment,
} from 'src/types/bar-purchase';

import { paths } from '../../routes/paths';
import BarPurchasePaymentRow from './bar-purchase-payment-row';
import BarPurchaseNewItemRow from './bar-purchase-new-item-row';

const NewBarPurchaseOrderSchema = Yup.object<Shape<TCreateBarPurchaseOrder>>().shape({
  location: Yup.string().required('Category is required'),
  supplier: Yup.string().required('Supplier is required'),
  reason: Yup.string().nullable(),
  discount: Yup.number()
    .typeError('')
    .min(0, 'Discount cannot be negative')
    .required('Discount is required'),
  isPercentageDiscount: Yup.boolean().required('Discount type is required'),
  subTotal: Yup.number().required(),
  totalAfterDiscount: Yup.number()
    .typeError('')
    .min(0, 'Total price cannot be negative')
    .required('Total price is required'),
  roundOffTotal: Yup.number()
    .typeError('')
    .min(0, 'Total price cannot be negative')
    .required('Total price is required'),
  paidAmount: Yup.number().required(),
  transactionType: Yup.mixed<TRANSACTION_TYPE>()
    .oneOf(Object.values(TRANSACTION_TYPE))
    .required('Transaction type is required'),
  purchaseOrDispatchDate: Yup.date()
    .typeError('Invalid purchase date')
    .required('Purchase date is required'),
  status: Yup.mixed<TRANSACTION_STATUS>()
    .oneOf(Object.values(TRANSACTION_STATUS))
    .required('Status is required'),
  items: Yup.array()
    .of(
      Yup.object<Shape<TCreateBarPurchaseOrderItem>>().shape({
        inventoryItem: Yup.string().required('Var item is required'),
        quantity: Yup.number()
          .typeError('')
          .min(1, 'Quantity cannot be zero or negative')
          .required('Quantity is required'),
        unit: Yup.string().required('Unit is required'),
        unitPrice: Yup.number()
          .typeError('')
          .moreThan(0, 'Unit price cannot be negative')
          .required('Unit price is required'),
        discount: Yup.number()
          .typeError('')
          .min(0, 'Discount cannot be negative')
          .required('Discount is required'),
        totalAfterDiscount: Yup.number()
          .typeError('')
          .min(0, 'Total price cannot be negative')
          .required('Total price is required'),
      })
    )
    .min(1, 'At least one item is required')
    .required('Items are required'),
  payments: Yup.array().of(
    Yup.object<Shape<TCreateBarPurchaseOrderPaymentRow>>().shape({
      amount: Yup.number()
        .typeError('')
        .moreThan(0, 'Amount cannot be negative')
        .required('Amount is required'),
      method: Yup.mixed<PAYMENT_METHOD>()
        .oneOf(Object.values(PAYMENT_METHOD))
        .required('Payment method is required'),
      paymentDate: Yup.date()
        .typeError('Invalid payment date')
        .required('Payemnt date is required'),
      isNew: Yup.boolean().required(),
      _id: Yup.string().optional(),
    })
  ),
});

const LINE_ITEM_HEADERS: TABLE_HEAD_CELL_TYPE[] = [
  { id: 'itemNo', label: '#', align: 'left', primary: true },
  {
    id: 'itemName',
    label: 'Item Name',
    align: 'left',
    primary: true,
    minWidth: 300,
  },
  { id: 'quantity', label: 'Quantity', align: 'left', primary: true },
  {
    id: 'unitPrice',
    label: 'Unit Price',
    align: 'right',
    primary: true,
  },
  { id: 'discount', label: 'Discount', align: 'right', primary: true },
  { id: 'total', label: 'Total', align: 'right', primary: true },
  { id: '', primary: true },
];

const PAYMENT_HEADERS: TABLE_HEAD_CELL_TYPE[] = [
  { id: 'itemNo', label: '#', align: 'left', primary: true },
  {
    id: 'paymentDate',
    label: 'Payment Date',
    align: 'left',
    primary: true,
    minWidth: 300,
  },
  { id: 'amount', label: 'Amount', align: 'left', primary: true },
  { id: 'method', label: 'Payment Method', align: 'left', primary: true, minWidth: 100 },
  { id: '', primary: true },
];

export type ConditionalSchema<T> = T extends string
  ? Yup.StringSchema
  : T extends number
  ? Yup.NumberSchema
  : T extends boolean
  ? Yup.BooleanSchema
  : T extends Record<any, any>
  ? Yup.AnyObjectSchema
  : T extends Array<any>
  ? Yup.ArraySchema<any, any>
  : Yup.AnySchema;

export type Shape<Fields> = {
  [Key in keyof Fields]: ConditionalSchema<Fields[Key]>;
};

type Props = {
  currentBarPurchaseItem?: IBarPurchaseOrderDetailedItem;
  payments?: IBarPurchaseOrderPayment[];
};

export default function BarPurchaseNewEditForm({ currentBarPurchaseItem, payments }: Props) {
  const dispatch = useAppDispatcher();

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const { user: authUser } = useAuthContext();

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const confirm = useBoolean();

  const paymentDelete = useBoolean();

  const { suppliers } = useAppSelector((state) => state.suppliers);

  const [changingSupplier, setChangingSupplier] = useState<ISupplierListItem | null>(null);

  const [removingPaymentIndex, setRemovingPaymentIndex] = useState<number | null>(null);

  const [selectedSupplier, setSelectedSupplier] = useState<ISupplierListItem | null>(null);
  const [localSuppliers, setLocalSuppliers] = useState<ISupplierListItem[]>([]);

  const [removingLineItems, setRemovingLineItems] = useState<TCreateBarPurchaseOrderItem[]>([]);

  const defaultItem = useMemo(
    () => ({
      inventoryItem: '',
      quantity: 0,
      unit: UNIT_ID,
      unitPrice: 0,
      discount: 0,
      totalAfterDiscount: 0,
    }),
    []
  );

  const defaultPaymentItem = useMemo(
    () => ({
      amount: 0,
      method: PAYMENT_METHOD.Cash,
      paymentDate: new Date(),
      isNew: true,
    }),
    []
  );

  const defaultValues = useMemo<TCreateBarPurchaseOrder>(
    () => ({
      location: BAR_LOCATION_ID,
      transactionType: TRANSACTION_TYPE.PURCHASE,
      description: currentBarPurchaseItem?.description ?? null,
      reason: currentBarPurchaseItem?.reason ?? null,
      discount: currentBarPurchaseItem?.discount ?? 0,
      isPercentageDiscount: currentBarPurchaseItem?.isPercentageDiscount ?? false,
      totalAfterDiscount: currentBarPurchaseItem?.totalAfterDiscount ?? 0,
      status: currentBarPurchaseItem?.status ?? TRANSACTION_STATUS.DRAFT,
      supplier: currentBarPurchaseItem?.supplier?._id ?? '',
      items:
        currentBarPurchaseItem?.items.map((item) => ({
          ...item,
          inventoryItem: item.inventoryItem._id,
          unit: item.unit._id,
        })) ?? [],
      purchaseOrDispatchDate: currentBarPurchaseItem?.purchaseOrDispatchDate
        ? new Date(currentBarPurchaseItem.purchaseOrDispatchDate)
        : new Date(),
      payments:
        payments?.map((item) => ({
          amount: item.amount,
          method: paymentMethodStringToEnum(item.paymentMethod),
          paymentDate: item.paymentDate ? new Date(item.paymentDate) : new Date(),
          isNew: false,
          _id: item._id,
        })) ?? [],
      paidAmount: currentBarPurchaseItem?.paidAmount ?? 0,
      roundOffTotal: currentBarPurchaseItem?.totalAfterDiscount ?? 0,
      subTotal:
        Number(currentBarPurchaseItem?.totalAfterDiscount ?? 0) -
        Number(currentBarPurchaseItem?.discount ?? 0),
    }),
    [currentBarPurchaseItem, payments]
  );

  const methods = useForm({
    resolver: yupResolver(NewBarPurchaseOrderSchema),
    defaultValues,
    mode: 'onChange',
  });

  const {
    reset,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting },
  } = methods;

  const {
    fields: paymentFormFields,
    append: appendPaymentFormField,
    remove: removePaymentFormField,
  } = useFieldArray({
    control,
    name: 'payments',
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  });

  const watchSupplier = useWatch({
    control,
    name: 'supplier',
  });

  const watchItems = useWatch({
    control,
    name: 'items',
  });

  const watchPayments = useWatch({
    control,
    name: 'payments',
  });

  const watchDiscount = useWatch({
    control,
    name: 'discount',
  });

  const watchSubtotal = useWatch({
    control,
    name: 'subTotal',
  });

  const watchRoundOffTotal = useWatch({
    control,
    name: 'roundOffTotal',
  });

  const watchPaidAmount = useWatch({
    control,
    name: 'paidAmount',
  });

  const handleSubmitWithStatus = (status: TRANSACTION_STATUS) =>
    handleSubmit(async (data) => {
      const formData: Omit<TCreateBarPurchaseOrder, 'payments' | 'paidAmount'> = {
        ...data,
        status,
        supplier: data.supplier ?? null,
        reason: data.reason ?? `${authUser?.firstName} created PO`,
        items:
          currentBarPurchaseItem?.status === TRANSACTION_STATUS.COMPLETED
            ? [
                ...data.items.map((item) => ({
                  ...item,
                  description: null,
                })),
                ...removingLineItems.map((item) => ({
                  ...item,
                  quantity: 0,
                  unitPrice: 0,
                  discount: 0,
                  totalAfterDiscount: 0,
                })),
              ]
            : data.items.map((item) => ({
                ...item,
              })),
        totalAfterDiscount: data.roundOffTotal,
      };

      if (currentBarPurchaseItem) {
        try {
          if (
            currentBarPurchaseItem.status === TRANSACTION_STATUS.DRAFT &&
            status === TRANSACTION_STATUS.COMPLETED
          ) {
            await dispatch(
              updateBarPurchaseOrder({
                purchaseOrderId: currentBarPurchaseItem._id,
                poTransaction: formData,
              })
            ).unwrap();
          } else if (status === TRANSACTION_STATUS.COMPLETED) {
            await dispatch(
              updateCompletedBarPurchaseOrder({
                purchaseOrderId: currentBarPurchaseItem._id,
                poTransaction: formData,
              })
            ).unwrap();

            if (data.payments) {
              try {
                await handlePayments(currentBarPurchaseItem._id, data.payments, data.supplier);
              } catch (e) {
                enqueueSnackbar('Error while making payments. Please try again.', {
                  variant: 'error',
                });
              }
            }
          } else {
            await dispatch(
              updateBarPurchaseOrder({
                purchaseOrderId: currentBarPurchaseItem._id,
                poTransaction: formData,
              })
            ).unwrap();
          }

          enqueueSnackbar('Bar PO update success');

          if (status === TRANSACTION_STATUS.COMPLETED) {
            navigate(paths.dashboard.bar.purchase.root());
          } else {
            navigate(paths.dashboard.bar.purchase.root(BarPurchaseOrdersStatus.DRAFT));
          }

          reset();
        } catch (e) {
          if (typeof e === 'string') {
            enqueueSnackbar(e, {
              variant: 'error',
            });
          } else {
            enqueueSnackbar(e?.message, {
              variant: 'error',
            });
          }
        }
      } else {
        try {
          const created = await dispatch(
            createBarPurchaseOrder({
              poTransaction: formData,
            })
          ).unwrap();

          enqueueSnackbar('Bar PO creation success');

          if (status === TRANSACTION_STATUS.COMPLETED) {
            navigate(paths.dashboard.bar.purchase.payment(created._id));
          } else {
            navigate(paths.dashboard.bar.purchase.root(BarPurchaseOrdersStatus.DRAFT));
          }

          reset();
        } catch (e) {
          if (typeof e === 'string') {
            enqueueSnackbar(e, {
              variant: 'error',
            });
          } else {
            enqueueSnackbar(e?.message, {
              variant: 'error',
            });
          }
        }
      }
    })();

  const handlePayments = async (
    purchaseOrderId: string,
    updatedPaymentsList: {
      method: NonNullable<PAYMENT_METHOD | undefined>;
      amount: number;
      paymentDate: Date;
      isNew: NonNullable<boolean | undefined>;
      _id?: string | undefined;
    }[],
    supplier: string
  ) =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise<void>(async (resolve, reject) => {
      try {
        const existingPaymentsMap = new Map(payments?.map((payment) => [payment._id, payment]));

        const deletedPayments = payments?.filter(
          (payment) => !updatedPaymentsList.find((updated) => updated._id === payment._id)
        );

        const deletePromises = deletedPayments?.map((payment) =>
          dispatch(
            barPurchaseOrderDeletePayment({
              paymentId: payment._id,
            })
          ).unwrap()
        );

        if (deletePromises) {
          await Promise.all(deletePromises);
        }

        const updatedPayments = updatedPaymentsList.filter((updatedPayment) => {
          const existing = existingPaymentsMap.get(updatedPayment._id ?? '');
          if (!existing) return false;
          return (
            existing.amount !== updatedPayment.amount ||
            existing.paymentMethod !== updatedPayment.method ||
            fAPIDate(existing.paymentDate) !== fAPIDate(updatedPayment.paymentDate)
          );
        });

        const updatePromises = updatedPayments.map((payment) => {
          if (payment._id) {
            return dispatch(
              barPurchaseOrderUpdatePayment({
                paymentId: payment._id,
                payment,
                purchaseOrderId,
                supplier,
              })
            ).unwrap();
          }
          return Promise.resolve();
        });

        await Promise.all(updatePromises);

        const newPayments = updatedPaymentsList.filter((payment) => payment.isNew);

        const createPromises = newPayments.map((payment) =>
          dispatch(
            barPurchaseOrderMakePayment({
              payment,
              purchaseOrderId,
              supplier,
            })
          ).unwrap()
        );

        await Promise.all(createPromises);

        resolve();
      } catch (error) {
        reject(error);
      }
    });

  const fetchPaginatedSuppliers = useCallback(
    () =>
      dispatch(
        getPaginatedSuppliers({
          page: null,
          limit: null,
          generalSearch: null,
          sortBy: 'supplierName',
          sort: 'asc',
        })
      ),
    [dispatch]
  );

  const fetchPaginatedBaritems = useCallback(() => {
    dispatch(
      getBarItems({
        page: null,
        limit: null,
        sortBy: 'itemName',
        sort: 'asc',
        status: currentBarPurchaseItem ? null : DocumentStatus.ACTIVE,
        suppliers: selectedSupplier ? [selectedSupplier._id] : [],
      })
    );
  }, [dispatch, currentBarPurchaseItem, selectedSupplier]);

  const scrollToBottom = () => {
    if (tableContainerRef.current) {
      const scrollContainer = tableContainerRef.current.querySelector('.simplebar-content-wrapper');
      if (scrollContainer) {
        scrollContainer.scrollTop = scrollContainer.scrollHeight;
      }
    }
  };

  const handleAppendItem = () => {
    if (fields.length === 0) {
      fetchPaginatedBaritems();
    }

    append(defaultItem);
    // Use setTimeout to ensure DOM has updated before scrolling
    setTimeout(scrollToBottom, 100);
  };

  const handleAppendPaymentRowItem = () => {
    appendPaymentFormField({ ...defaultPaymentItem, amount: calculateDueAmount() });
  };

  useEffect(() => {
    fetchPaginatedSuppliers();
  }, [fetchPaginatedSuppliers]);

  useEffect(() => {
    setLocalSuppliers(
      suppliers.suppliers.filter(
        (supplier: ISupplierListItem) => supplier._id !== VITE_DEFAULT_SUPPLIER
      )
    );

    setSelectedSupplier(currentBarPurchaseItem?.supplier ?? null);
  }, [suppliers, currentBarPurchaseItem]);

  useEffect(() => {
    if (currentBarPurchaseItem) {
      fetchPaginatedBaritems();
    }
  }, [currentBarPurchaseItem, fetchPaginatedBaritems]);

  useEffect(() => {
    let calculatedSubTotal = 0;

    watchItems.forEach((item, index) => {
      const calculatedTotal = item.unitPrice * item.quantity - item.discount;

      calculatedSubTotal += calculatedTotal;

      if (calculatedTotal !== item.totalAfterDiscount) {
        setValue(`items.${index}.totalAfterDiscount`, calculatedTotal);
      }
    });

    setValue('subTotal', calculatedSubTotal);

    setValue('totalAfterDiscount', calculatedSubTotal - watchDiscount);

    setValue('roundOffTotal', Math.round(calculatedSubTotal - watchDiscount));
  }, [watchItems, setValue, watchDiscount]);

  useEffect(() => {
    let calculatedTotalPaid = 0;

    watchPayments?.forEach((item) => {
      calculatedTotalPaid += item.amount;
    });

    setValue('paidAmount', calculatedTotalPaid);
  }, [setValue, watchPayments]);

  useEffect(() => {
    setValue('totalAfterDiscount', watchSubtotal - watchDiscount);

    setValue('roundOffTotal', Math.round(watchSubtotal - watchDiscount));
  }, [setValue, watchDiscount, watchSubtotal]);

  const renderCreatePurchaseOrderButton = () => {
    if (currentBarPurchaseItem?.status === TRANSACTION_STATUS.DRAFT || !currentBarPurchaseItem) {
      return (
        <LoadingButton
          type="button"
          variant="outlined"
          loading={isSubmitting}
          onClick={() => handleSubmitWithStatus(TRANSACTION_STATUS.COMPLETED)}
        >
          {currentBarPurchaseItem?.status === TRANSACTION_STATUS.DRAFT
            ? 'Mark as completed'
            : 'Create Purchase Order'}
        </LoadingButton>
      );
    }
    return null;
  };

  const handleSubmitAction = () => {
    if (!currentBarPurchaseItem) {
      handleSubmitWithStatus(TRANSACTION_STATUS.DRAFT);
      return;
    }

    if (currentBarPurchaseItem?.status === TRANSACTION_STATUS.DRAFT) {
      handleSubmitWithStatus(TRANSACTION_STATUS.DRAFT);
      return;
    }

    handleSubmitWithStatus(TRANSACTION_STATUS.COMPLETED);
  };

  const getButtonText = () => {
    if (!currentBarPurchaseItem) {
      return 'Save as Draft';
    }

    if (currentBarPurchaseItem?.status === TRANSACTION_STATUS.DRAFT) {
      return 'Update Draft';
    }

    return 'Save Changes';
  };

  const isCompleted = currentBarPurchaseItem?.status === TRANSACTION_STATUS.COMPLETED;

  const calculateDueAmount = () => watchRoundOffTotal - watchPaidAmount;

  return (
    <>
      <FormProvider methods={methods}>
        <Grid container spacing={3}>
          <Grid xs={12}>
            <CardContainer currentBarPurchaseItem={currentBarPurchaseItem}>
              <Grid container spacing={2}>
                <Grid
                  mb={1}
                  display="flex"
                  mt={2}
                  alignItems="center"
                  justifyContent="space-between"
                  width={1}
                >
                  <Typography variant="subtitle2">Bill Information</Typography>
                </Grid>

                <Grid xs={12} md={4}>
                  <Controller
                    name="purchaseOrDispatchDate"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <CustomDatePicker
                        disableFuture
                        disabled={isCompleted}
                        label="Purchase Date"
                        value={field.value}
                        onChange={(newValue) => {
                          field.onChange(newValue);
                        }}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: !!error,
                            helperText: error?.message,
                            required: true,
                          },
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid xs={12} md={4}>
                  <Controller
                    name="supplier"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Autocomplete
                        {...field}
                        disabled={isCompleted}
                        options={localSuppliers}
                        getOptionLabel={(option) =>
                          typeof option === 'string' ? option : option.supplierName
                        }
                        autoComplete
                        isOptionEqualToValue={(option, value) => option._id === value._id}
                        filterSelectedOptions
                        value={selectedSupplier}
                        onChange={(event: any, newValue: ISupplierListItem | null) => {
                          if (watchItems.length > 0 && watchItems[0].inventoryItem !== '') {
                            setChangingSupplier(newValue);
                            confirm.onTrue();
                          } else {
                            setSelectedSupplier(newValue ?? null);
                            setChangingSupplier(newValue);
                            field.onChange(newValue?._id);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            label="Supplier"
                            error={!!error}
                            helperText={error ? error?.message : ''}
                            {...params}
                          />
                        )}
                        renderOption={(props, option) => (
                          <li key={option._id} {...props}>
                            <Typography
                              variant="body2"
                              sx={{ color: 'text.secondary', textTransform: 'capitalize' }}
                            >
                              {option.supplierName}
                            </Typography>
                          </li>
                        )}
                      />
                    )}
                  />
                </Grid>

                <Divider
                  sx={{
                    width: 1,
                    my: 2,
                    borderStyle: 'dashed',
                  }}
                />

                <Grid mb={1} xs={12} width="full">
                  <Typography variant="subtitle2">Line Items</Typography>
                </Grid>

                <Grid xs={12}>
                  <Stack spacing={2}>
                    {currentBarPurchaseItem ? (
                      <TableContainer>
                        <Scrollbar>
                          <Table stickyHeader size="small" sx={{ minWidth: 960 }}>
                            <TableHeadCustom headLabel={LINE_ITEM_HEADERS} />

                            <TableBody>
                              {fields.map((item, index) => (
                                <BarPurchaseNewItemRow
                                  key={item.id}
                                  index={index}
                                  remove={(itemIndex) => {
                                    remove(itemIndex);

                                    fetchPaginatedBaritems();

                                    if (isCompleted) {
                                      setRemovingLineItems((prev) => [...prev, item]);
                                    }
                                  }}
                                  isLast={fields.length === 1 && index === 0}
                                  isCompleted={isCompleted}
                                />
                              ))}
                            </TableBody>
                          </Table>
                        </Scrollbar>
                      </TableContainer>
                    ) : (
                      <TableContainer
                        ref={tableContainerRef}
                        sx={{ position: 'relative', overflow: 'hidden', height: '250px' }}
                      >
                        <Scrollbar>
                          <Table stickyHeader size="small" sx={{ minWidth: 960 }}>
                            <TableHeadCustom headLabel={LINE_ITEM_HEADERS} />

                            <TableBody>
                              {fields.map((item, index) => (
                                <BarPurchaseNewItemRow
                                  key={item.id}
                                  index={index}
                                  remove={(itemIndex) => {
                                    remove(itemIndex);

                                    fetchPaginatedBaritems();

                                    if (isCompleted) {
                                      setRemovingLineItems((prev) => [...prev, item]);
                                    }
                                  }}
                                  isLast={fields.length === 1 && index === 0}
                                  isCompleted={isCompleted}
                                />
                              ))}
                            </TableBody>
                          </Table>
                        </Scrollbar>
                      </TableContainer>
                    )}
                  </Stack>

                  {!isCompleted && (
                    <Tooltip title={watchSupplier === '' ? 'Please select a supplier' : ''}>
                      <Stack mt={4}>
                        <Button
                          variant="soft"
                          disabled={watchSupplier === ''}
                          color="primary"
                          onClick={handleAppendItem}
                        >
                          Add
                        </Button>
                      </Stack>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>

              <Stack direction="row" justifyContent="flex-end" mb={4}>
                <Grid
                  container
                  spacing={2}
                  mt={3}
                  sx={{
                    maxWidth: {
                      md: '400px',
                    },
                  }}
                >
                  {/* Item Count */}
                  <Grid xs={6}>
                    <Typography variant="subtitle2">Item Count</Typography>
                  </Grid>
                  <Grid xs={6} display="flex" justifyContent="end">
                    <Typography variant="subtitle2">
                      {fields.length < 10 ? `0${fields.length}` : fields.length}
                    </Typography>
                  </Grid>

                  {/* Sub Total */}
                  <Grid xs={6}>
                    <Typography variant="subtitle2">Sub Total</Typography>
                  </Grid>
                  <Grid xs={6} display="flex" justifyContent="end">
                    <Typography variant="subtitle2">
                      {fCurrencyRupees(watchSubtotal * 100)}
                    </Typography>
                  </Grid>

                  {/* Discount */}
                  <Grid xs={6}>
                    <Typography variant="subtitle2">Discount</Typography>
                  </Grid>

                  <Grid xs={6} display="flex" justifyContent="end">
                    <RHFTextField
                      name="discount"
                      size="small"
                      type="number"
                      placeholder="0.00"
                      inputProps={{
                        style: {
                          textAlign: 'right',
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Box sx={{ typography: 'subtitle2', color: 'text.disabled' }}>Rs.</Box>
                          </InputAdornment>
                        ),
                      }}
                      sx={{ maxWidth: { md: 150 } }}
                    />
                  </Grid>

                  {/* Total */}
                  <Grid xs={6}>
                    <Typography variant="subtitle2">Total</Typography>
                  </Grid>
                  <Grid xs={6} display="flex" justifyContent="end">
                    <Typography variant="subtitle2">
                      {fCurrencyRupees(watch('totalAfterDiscount') * 100)}
                    </Typography>
                  </Grid>

                  {/* Total After Discount */}
                  <Grid xs={6}>
                    <Typography variant="subtitle2">Total After Discount</Typography>
                  </Grid>
                  <Grid xs={6} display="flex" justifyContent="end">
                    <Typography variant="subtitle2">
                      {fCurrencyRupees(watch('roundOffTotal') * 100)}
                    </Typography>
                  </Grid>
                </Grid>
              </Stack>

              {currentBarPurchaseItem && (
                <>
                  <Divider
                    sx={{
                      width: 1,
                      my: 2,
                      borderStyle: 'dashed',
                    }}
                  />

                  <Grid mb={1} xs={12} width="full">
                    <Typography variant="subtitle2">Payments</Typography>
                  </Grid>

                  <Grid xs={12}>
                    <Stack spacing={2}>
                      <TableContainer>
                        <Table stickyHeader size="small" sx={{ minWidth: 960 }}>
                          <TableHeadCustom headLabel={PAYMENT_HEADERS} />

                          <TableBody>
                            {paymentFormFields.map((item, index) => (
                              <BarPurchasePaymentRow
                                key={item.id}
                                index={index}
                                remove={(itemIndex) => {
                                  if (item.isNew) {
                                    removePaymentFormField(itemIndex);
                                  } else {
                                    setRemovingPaymentIndex(itemIndex);
                                    paymentDelete.onTrue();
                                  }
                                }}
                              />
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Stack>

                    {calculateDueAmount() > 0 && (
                      <Stack
                        direction="row"
                        mt={4}
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Stack direction="column" gap={1}>
                          <Typography variant="subtitle2">Due Amount</Typography>
                          <Typography variant="subtitle2">
                            {' '}
                            {fCurrencyRupees(calculateDueAmount() * 100)}
                          </Typography>
                        </Stack>

                        <Button variant="soft" color="primary" onClick={handleAppendPaymentRowItem}>
                          {paymentFormFields.length === 0 ? 'Add Payment' : 'Add Another Payment'}
                        </Button>
                      </Stack>
                    )}
                  </Grid>
                </>
              )}

              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                justifyContent="space-between"
                gap={2}
                mt={5}
              >
                <Link
                  component={RouterLink}
                  href={paths.dashboard.bar.purchase.root()}
                  sx={{
                    width: {
                      xs: 1,
                      sm: 'auto',
                    },
                  }}
                >
                  <Button
                    variant="outlined"
                    color="inherit"
                    sx={{
                      width: 1,
                    }}
                  >
                    Cancel
                  </Button>
                </Link>

                <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="flex-end" gap={2}>
                  {renderCreatePurchaseOrderButton()}

                  <LoadingButton
                    type="button"
                    variant="contained"
                    loading={isSubmitting}
                    onClick={handleSubmitAction}
                  >
                    {getButtonText()}
                  </LoadingButton>
                </Stack>
              </Stack>
            </CardContainer>
          </Grid>
        </Grid>
      </FormProvider>

      <ConfirmDialog
        open={confirm.value}
        onClose={() => {
          setChangingSupplier(null);
          confirm.onFalse();
        }}
        title="Changing Supplier?"
        content="This action will permanently reset the purchase order"
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setValue('items', [defaultItem]);

              if (changingSupplier) {
                setValue('supplier', changingSupplier?._id);
              }

              setSelectedSupplier(changingSupplier);
              confirm.onFalse();
            }}
          >
            Reset
          </Button>
        }
      />

      <ConfirmDialog
        open={paymentDelete.value}
        onClose={() => {
          setRemovingPaymentIndex(null);
          paymentDelete.onFalse();
        }}
        title="Deleting Payment?"
        content="This action will permanently delete the payment"
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              if (removingPaymentIndex !== null) {
                removePaymentFormField(removingPaymentIndex);
              }

              setRemovingPaymentIndex(null);
              paymentDelete.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

function CardContainer({
  currentBarPurchaseItem,
  children,
}: {
  currentBarPurchaseItem?: IBarPurchaseOrderDetailedItem;
  children: ReactNode;
}) {
  if (currentBarPurchaseItem) {
    return (
      <Card
        sx={{
          position: 'relative',
          height: '700px',
          overflow: 'hidden',
        }}
      >
        <Scrollbar sx={{ p: 3 }}>{children}</Scrollbar>
      </Card>
    );
  }

  return (
    <Card
      sx={{
        p: 3,
      }}
    >
      {children}
    </Card>
  );
}

import { isEqual } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import { alpha, Tab, Tabs } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';

import { useResponsive } from 'src/hooks/use-responsive';

import { TAB_QUERY } from 'src/utils/common-types';
import { hasPermission } from 'src/utils/has-permissions';
import logToucodeEvent, { APP_EVENTS } from 'src/utils/log-google-events';

import { useAuthContext } from 'src/auth/hooks';
import { AuthPermissions } from 'src/auth/permissions';
import { useAppDispatcher, useAppSelector } from 'src/redux/store';
import {
  deleteCustomer,
  disableCustomer,
  getPaginatedCustomers,
  removeCustomer,
} from 'src/redux/slices/customers';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import TableErrorData from 'src/components/table/table-error-data';
import {
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSkeleton,
  useTable,
} from 'src/components/table';

import { getPaymentDueAPIStatus, PaymentDueStatus } from 'src/types/common';
import {
  CUSTOMER_DEBT_STATUS_OPTIONS,
  ICustomerItem,
  ICustomerTableFilters,
  ICustomerTableFilterValue,
  ICustomerTableSort,
} from 'src/types/customer';

import CustomerTableRow from '../customer-table-row';
import CustomerTableToolbar from '../customer-table-toolbar';

const STATUS_OPTIONS = [{ value: 'all', label: 'All' }, ...CUSTOMER_DEBT_STATUS_OPTIONS];

const TABLE_HEAD = [
  { id: 'customerName', label: 'Name', align: 'left', primary: true },
  { id: 'address', label: 'Address', align: 'left', primary: true },
  { id: 'phoneNumber', label: 'Phone Number', align: 'left', primary: true },
  { id: 'email', label: 'Email Address', align: 'left', primary: true },
  { id: 'updatedAt', label: 'Updated', align: 'left', primary: true },
  { id: '', primary: true },
];

const defaultFilters: ICustomerTableFilters = {
  generalSearch: '',
};

const defaultSortBy: ICustomerTableSort = {
  sortBy: 'updatedAt',
  order: 'desc',
};

const disablingCustomerInitialState: {
  state: boolean;
  index: number | null;
  message: string;
  customer: ICustomerItem | null;
} = {
  state: false,
  index: null,
  message: '',
  customer: null,
};

export default function CustomerListView() {
  const [searchParams, setSearchParams] = useSearchParams();

  const currentTab = searchParams.get(TAB_QUERY);

  const { status, customers, error } = useAppSelector((state) => state.customers);

  const { user: authUser } = useAuthContext();
  const table = useTable({
    defaultRowsPerPage: 25,
  });

  const smDown = useResponsive('down', 'sm');

  const dispatch = useAppDispatcher();

  const { page, rowsPerPage } = table;

  const settings = useSettingsContext();

  const denseHeight = table.dense ? 60 : 80;

  const router = useRouter();

  const [disablingCustomer, setDisablingCustomer] = useState(disablingCustomerInitialState);

  const [filters, setFilters] = useState(defaultFilters);

  const [sort, setSort] = useState(defaultSortBy);

  const [statusTab, setStatusTab] = useState<string>(currentTab || PaymentDueStatus.ALL);

  const canReset = !isEqual(defaultFilters, filters);

  const handleFilters = useCallback(
    (name: string, value: ICustomerTableFilterValue) => {
      table.onResetPage();

      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleEditRow = useCallback(
    (id: string) => {
      router.push(paths.dashboard.customers.edit(id));
    },
    [router]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const handleFilterStatus = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setStatusTab(newValue);

      setSearchParams({ [TAB_QUERY]: newValue });
    },
    [setSearchParams]
  );

  const fetchPaginatedCustomers = useCallback(() => {
    dispatch(
      getPaginatedCustomers({
        page,
        limit: rowsPerPage,
        generalSearch: filters.generalSearch,
        sort: sort.order,
        sortBy: sort.sortBy,
        paymentStatus: getPaymentDueAPIStatus(statusTab),
      })
    );
    // }
  }, [dispatch, page, rowsPerPage, sort.order, sort.sortBy, filters, statusTab]);

  const handleDeleteRow = useCallback(
    async (customer: ICustomerItem, index: number) => {
      dispatch(removeCustomer({ customerId: customer._id }));

      try {
        await dispatch(deleteCustomer({ customer, index })).unwrap();

        fetchPaginatedCustomers();
        enqueueSnackbar('Customer deleted');
      } catch (e) {
        if ('name' in e && e.name === 'AxiosError') {
          setDisablingCustomer({
            state: true,
            message: e.message,
            customer,
            index,
          });
        } else if (typeof e === 'string') {
          enqueueSnackbar(e, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(e?.message, {
            variant: 'error',
          });
        }
      }
    },
    [dispatch, fetchPaginatedCustomers]
  );

  const handleDisableRow = useCallback(
    async (customer: ICustomerItem, index: number) => {
      dispatch(removeCustomer({ customerId: customer._id }));

      try {
        await dispatch(disableCustomer({ customer, index })).unwrap();

        fetchPaginatedCustomers();
        enqueueSnackbar('Customer disabled');
      } catch (e) {
        if (typeof e === 'string') {
          enqueueSnackbar(e, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(e?.message, {
            variant: 'error',
          });
        }
      }
    },
    [dispatch, fetchPaginatedCustomers]
  );

  useEffect(() => {
    fetchPaginatedCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPaginatedCustomers]);

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Customers"
          links={[]}
          action={
            <>
              {hasPermission(
                authUser,
                'any',
                AuthPermissions.CREATE_CUSTOMER,
                AuthPermissions.ADMIN_PERMISSION,
                AuthPermissions.SUPER_ADMIN_PERMISSION
              ) && (
                <Button
                  component={RouterLink}
                  href={paths.dashboard.customers.new}
                  variant="contained"
                  startIcon={<Iconify icon="mingcute:add-line" />}
                >
                  {smDown ? 'New' : 'New Customer'}
                </Button>
              )}
            </>
          }
          sx={{
            mb: { xs: 3 },
          }}
        />

        <Card>
          <Tabs
            value={statusTab}
            onChange={handleFilterStatus}
            sx={{
              px: 2.5,
              boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
            }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab key={tab.value} iconPosition="end" value={tab.value} label={tab.label} />
            ))}
          </Tabs>

          <CustomerTableToolbar
            filters={filters}
            onFilters={handleFilters}
            handleResetFilters={handleResetFilters}
            canReset={canReset}
          />

          <TableContainer sx={{ position: 'relative', overflow: 'hidden', height: '450px' }}>
            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                <TableHeadCustom
                  order={sort.order}
                  orderBy={sort.sortBy}
                  headLabel={TABLE_HEAD.filter(({ primary }) => {
                    if (settings.themeLayout === 'vertical') {
                      return primary;
                    }
                    return true;
                  })}
                  rowCount={customers.customers.length}
                  numSelected={table.selected.length}
                  onSort={(id) => {
                    if (id === sort.sortBy) {
                      setSort((prev) => ({
                        ...prev,
                        order: prev.order === 'asc' ? 'desc' : 'asc',
                      }));
                    } else {
                      setSort({
                        sortBy: id,
                        order: 'asc',
                      });
                    }
                  }}
                />

                <TableBody>
                  {status === 'loading' ? (
                    [...Array(table.rowsPerPage)].map((i, index) => (
                      <TableSkeleton key={index} sx={{ height: denseHeight }} />
                    ))
                  ) : (
                    <>
                      {customers?.customers.map((row: ICustomerItem, index: number) => (
                        <CustomerTableRow
                          key={row._id}
                          row={row}
                          selected={table.selected.includes(row._id)}
                          onDeleteRow={() => handleDeleteRow(row, index)}
                          onEditRow={() => handleEditRow(row._id)}
                        />
                      ))}
                    </>
                  )}

                  {status !== 'failure' && customers.count === 0 && (
                    <TableNoData message="No customers found" notFound={customers.count === 0} />
                  )}

                  {status === 'failure' && <TableErrorData notFound error={error} />}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={customers.count}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={(value) => {
              table.onChangeDense(value);

              logToucodeEvent(APP_EVENTS.TABLE_COMPACT_MODE_TOGGLE, {
                compact: value,
                table: 'customers_table',
              });
            }}
          />
        </Card>
      </Container>
      <ConfirmDialog
        open={disablingCustomer.state}
        onClose={() => setDisablingCustomer(disablingCustomerInitialState)}
        title={`Action Required: Cannot Delete ${disablingCustomer.customer?.customerName}`}
        content={disablingCustomer.message}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              if (disablingCustomer.customer !== null && disablingCustomer.index !== null) {
                handleDisableRow(disablingCustomer.customer, disablingCustomer.index);
                setDisablingCustomer(disablingCustomerInitialState);
              }
            }}
          >
            Temporarily Delete
          </Button>
        }
      />
    </>
  );
}

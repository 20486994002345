import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  Divider,
  InputAdornment,
  Link,
  MenuItem,
  Table,
  TableBody,
  TableContainer,
} from '@mui/material';

import { RouterLink } from 'src/routes/components';

import { Constants } from 'src/utils/constants';
import { fCurrencyRupees } from 'src/utils/format-number';
import {
  TABLE_HEAD_CELL_TYPE,
  TRANSACTION_DISPATCH_TYPES,
  TRANSACTION_PAYMENT_STATUS,
  TRANSACTION_STATUS,
  TRANSACTION_TYPE,
} from 'src/utils/common-types';

import { useAuthContext } from 'src/auth/hooks';
import { useAppDispatcher } from 'src/redux/store';
import { getBarItems } from 'src/redux/slices/bar';
import {
  createBarPurchaseOrder,
  TCreateBarPurchaseOrder,
} from 'src/redux/slices/bar-purchase-order';
import {
  BAR_LOCATION_ID,
  UNIT_ID,
  VITE_DEFAULT_SUPPLIER,
  VITE_EMPTY_BOTTLE_ITEM_ID,
} from 'src/config-global';
import {
  createBarDispatchOrder,
  TCreateBarDispatchOrder,
  TCreateBarDispatchOrderItem,
  updateBarDispatchOrder,
  updateCompletedBarDispatchOrder,
} from 'src/redux/slices/bar-dispatch-order';

import Scrollbar from 'src/components/scrollbar';
import { TableHeadCustom } from 'src/components/table';
import FormProvider, { RHFSelect, RHFTextField } from 'src/components/hook-form';
import CustomDatePicker from 'src/components/custom-datepicker/custom-datepicker';

import { DocumentStatus } from 'src/types/common';
import { BarDispatchOrdersStatus, IBarDispatchOrderDetailedItem } from 'src/types/bar-dispatches';

import { paths } from '../../routes/paths';
import BarDispatchNewItemRow from './bar-dispatch-new-item-row';

const LINE_ITEM_HEADERS: TABLE_HEAD_CELL_TYPE[] = [
  { id: 'itemNo', label: '#', align: 'left', primary: true },
  {
    id: 'itemName',
    label: 'Item Name',
    align: 'left',
    primary: true,
    minWidth: 300,
  },
  { id: 'quantity', label: 'Quantity', align: 'left', primary: true, minWidth: 150 },
  {
    id: 'unitPrice',
    label: 'Unit Price',
    align: 'right',
    primary: true,
  },
  { id: 'discount', label: 'Discount', align: 'right', primary: true },
  { id: 'total', label: 'Total', align: 'right', primary: true },
  { id: '', primary: true },
];

export type ConditionalSchema<T> = T extends string
  ? Yup.StringSchema
  : T extends number
  ? Yup.NumberSchema
  : T extends boolean
  ? Yup.BooleanSchema
  : T extends Record<any, any>
  ? Yup.AnyObjectSchema
  : T extends Array<any>
  ? Yup.ArraySchema<any, any>
  : Yup.AnySchema;

export type Shape<Fields> = {
  [Key in keyof Fields]: ConditionalSchema<Fields[Key]>;
};

type Props = {
  currentBarDispatchItem?: IBarDispatchOrderDetailedItem;
};

interface TCreateBarDispatchOrderWithBottleCount extends TCreateBarDispatchOrder {
  bottleCount: number;
}

function calculateEmptyBottleDiscount(bottleCount: number): number {
  return bottleCount * Constants.EMPTY_BOTTLE_DISCOUNT;
}

export default function BarDispatchNewEditForm({ currentBarDispatchItem }: Props) {
  const dispatch = useAppDispatcher();

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const { user: authUser } = useAuthContext();

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [subTotal, setSubTotal] = useState<number>(0);
  const [removingLineItems, setRemovingLineItems] = useState<TCreateBarDispatchOrderItem[]>([]);

  const NewBarDispatchOrderSchema = Yup.object<
    Shape<TCreateBarDispatchOrderWithBottleCount>
  >().shape({
    location: Yup.string().required('Category is required'),
    reason: Yup.string().nullable(),
    bottleCount: Yup.number()
      .min(0, 'Bottle count cannot be negative')
      .integer('Invalid bottle count')
      .optional()
      .nullable(),
    discount: Yup.number()
      .typeError('')
      .min(0, 'Discount cannot be negative')
      .required('Discount is required'),
    totalAfterDiscount: Yup.number()
      .typeError('')
      .min(0, 'Total price cannot be negative')
      .required('Total price is required'),
    transactionType: Yup.mixed<TRANSACTION_DISPATCH_TYPES>()
      .oneOf(
        Object.values(TRANSACTION_DISPATCH_TYPES).filter(
          (type) => type !== TRANSACTION_DISPATCH_TYPES.RESTOCK
        )
      )
      .required('Transaction type is required'),
    paymentStatus: Yup.mixed<TRANSACTION_PAYMENT_STATUS>()
      .oneOf(Object.values(TRANSACTION_PAYMENT_STATUS))
      .required('Payment status is required'),
    purchaseOrDispatchDate: Yup.date()
      .typeError('Invalid dispatch date')
      .required('Purchase date is required'),
    status: Yup.mixed<TRANSACTION_STATUS>()
      .oneOf(Object.values(TRANSACTION_STATUS))
      .required('Status is required'),
    items: Yup.array()
      .of(
        Yup.object<Shape<TCreateBarDispatchOrderItem>>().shape({
          inventoryItem: Yup.string().required('Bar item is required'),
          quantity: Yup.number()
            .typeError('Quantity must be a whole number')
            .min(1, 'Quantity cannot be zero or negative')
            .required('Quantity is required'),
          unit: Yup.string().required('Unit is required'),
          unitPrice: Yup.number()
            .typeError('')
            .moreThan(0, 'Unit price cannot be negative')
            .required('Unit price is required'),
          discount: Yup.number()
            .typeError('')
            .min(0, 'Discount cannot be negative')
            .required('Discount is required'),
          totalAfterDiscount: Yup.number()
            .typeError('')
            .min(0, 'Total price cannot be negative')
            .required('Total price is required'),
        })
      )
      .min(1, 'At least one item is required')
      .required('Items are required'),
  });

  const defaultItem = useMemo(
    () => ({
      inventoryItem: '',
      quantity: 0,
      unit: UNIT_ID,
      unitPrice: 0,
      discount: 0,
      isPercentageDiscount: false,
      totalAfterDiscount: 0,
    }),
    []
  );

  const defaultValues = useMemo<TCreateBarDispatchOrderWithBottleCount>(
    () => ({
      location: BAR_LOCATION_ID,
      transactionType:
        currentBarDispatchItem?.transactionType ?? TRANSACTION_DISPATCH_TYPES.DISPATCH,
      description: currentBarDispatchItem?.description ?? null,
      reason: currentBarDispatchItem?.reason ?? null,
      discount: currentBarDispatchItem?.discount ?? 0,
      paymentStatus: currentBarDispatchItem?.paymentStatus ?? TRANSACTION_PAYMENT_STATUS.PAID,
      totalAfterDiscount: currentBarDispatchItem?.totalAfterDiscount ?? 0,
      status: currentBarDispatchItem?.status ?? TRANSACTION_STATUS.DRAFT,
      items: currentBarDispatchItem?.items.map((item) => ({
        ...item,
        inventoryItem: item.inventoryItem._id,
        unit: item.unit._id,
      })) ?? [defaultItem],
      purchaseOrDispatchDate: currentBarDispatchItem?.purchaseOrDispatchDate
        ? new Date(currentBarDispatchItem.purchaseOrDispatchDate)
        : new Date(),
      bottleCount: 0,
    }),
    [defaultItem, currentBarDispatchItem]
  );

  const methods = useForm({
    resolver: yupResolver(NewBarDispatchOrderSchema),
    defaultValues,
    mode: 'onChange',
  });

  const {
    reset,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting },
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  });

  const watchItems = useWatch({
    control,
    name: 'items',
  });

  const watchDiscount = useWatch({
    control,
    name: 'discount',
  });

  const watchEmptyBottles = useWatch({
    control,
    name: 'bottleCount',
  });

  const handleSubmitWithStatus = (status: TRANSACTION_STATUS) =>
    handleSubmit(async (data) => {
      const formData: TCreateBarDispatchOrder = {
        ...data,
        status,
        reason: data.reason ?? `${authUser?.firstName} created DO`,
        items:
          currentBarDispatchItem?.status === TRANSACTION_STATUS.COMPLETED
            ? [
                ...data.items,
                ...removingLineItems.map((item) => ({
                  ...item,
                  quantity: 0,
                  unitPrice: 0,
                  discount: 0,
                  totalAfterDiscount: 0,
                })),
              ]
            : data.items,
      };

      if (currentBarDispatchItem) {
        try {
          if (
            currentBarDispatchItem.status === TRANSACTION_STATUS.DRAFT &&
            status === TRANSACTION_STATUS.COMPLETED
          ) {
            await dispatch(
              updateBarDispatchOrder({
                dispatchOrderId: currentBarDispatchItem._id,
                dispatchTransaction: formData,
              })
            ).unwrap();
          } else if (status === TRANSACTION_STATUS.COMPLETED) {
            await dispatch(
              updateCompletedBarDispatchOrder({
                dispatchOrderId: currentBarDispatchItem._id,
                dispatchTransaction: formData,
              })
            ).unwrap();
          } else {
            await dispatch(
              updateBarDispatchOrder({
                dispatchOrderId: currentBarDispatchItem._id,
                dispatchTransaction: formData,
              })
            ).unwrap();
          }

          enqueueSnackbar('Bar dispatch order update success');

          if (status === TRANSACTION_STATUS.COMPLETED) {
            navigate(paths.dashboard.bar.dispatch.root());
          } else {
            navigate(paths.dashboard.bar.dispatch.root(BarDispatchOrdersStatus.DRAFT));
          }

          reset();
        } catch (e) {
          if (typeof e === 'string') {
            enqueueSnackbar(e, {
              variant: 'error',
            });
          } else {
            enqueueSnackbar(e?.message, {
              variant: 'error',
            });
          }
        }
      } else {
        try {
          await dispatch(
            createBarDispatchOrder({
              dispatchTransaction: formData,
            })
          ).unwrap();

          enqueueSnackbar('Bar dispatch order creation success');

          if (watchEmptyBottles && watchEmptyBottles > 0) {
            const emptyBottlePOFormData: Omit<TCreateBarPurchaseOrder, 'payments' | 'paidAmount'> =
              {
                status,
                supplier: VITE_DEFAULT_SUPPLIER,
                reason: `${authUser?.firstName} created empty bottle PO`,
                location: BAR_LOCATION_ID,
                discount: 0,
                purchaseOrDispatchDate: data.purchaseOrDispatchDate,
                transactionType: TRANSACTION_TYPE.PURCHASE,
                isPercentageDiscount: false,
                totalAfterDiscount: watchEmptyBottles * Constants.EMPTY_BOTTLE_DISCOUNT,
                roundOffTotal: watchEmptyBottles * Constants.EMPTY_BOTTLE_DISCOUNT,
                subTotal: watchEmptyBottles * Constants.EMPTY_BOTTLE_DISCOUNT,
                items: [
                  {
                    discount: 0,
                    totalAfterDiscount: watchEmptyBottles * Constants.EMPTY_BOTTLE_DISCOUNT,
                    inventoryItem: VITE_EMPTY_BOTTLE_ITEM_ID,
                    quantity: watchEmptyBottles,
                    unit: UNIT_ID,
                    unitPrice: Constants.EMPTY_BOTTLE_DISCOUNT,
                  },
                ],
              };

            await dispatch(
              createBarPurchaseOrder({
                poTransaction: emptyBottlePOFormData,
              })
            ).unwrap();
          }

          if (status === TRANSACTION_STATUS.COMPLETED) {
            navigate(paths.dashboard.bar.dispatch.root());
          } else {
            navigate(paths.dashboard.bar.dispatch.root(BarDispatchOrdersStatus.DRAFT));
          }
          reset();
        } catch (e) {
          if (typeof e === 'string') {
            enqueueSnackbar(e, {
              variant: 'error',
            });
          } else {
            enqueueSnackbar(e?.message, {
              variant: 'error',
            });
          }
        }
      }
    })();

  const fetchPaginatedBaritems = useCallback(() => {
    dispatch(
      getBarItems({
        page: null,
        limit: null,
        sortBy: 'itemName',
        sort: 'asc',
        status: currentBarDispatchItem ? null : DocumentStatus.ACTIVE,
      })
    );
  }, [dispatch, currentBarDispatchItem]);

  const scrollToBottom = () => {
    if (tableContainerRef.current) {
      const scrollContainer = tableContainerRef.current.querySelector('.simplebar-content-wrapper');
      if (scrollContainer) {
        scrollContainer.scrollTop = scrollContainer.scrollHeight;
      }
    }
  };

  const handleAppendItem = () => {
    append(defaultItem);
    // Use setTimeout to ensure DOM has updated before scrolling
    setTimeout(scrollToBottom, 100);
  };

  useEffect(() => {
    fetchPaginatedBaritems();
  }, [fetchPaginatedBaritems]);

  useEffect(() => {
    let calculatedSubTotal = 0;

    watchItems.forEach((item, index) => {
      const calculatedTotal = item.unitPrice * item.quantity - item.discount;

      calculatedSubTotal += calculatedTotal;

      if (calculatedTotal !== item.totalAfterDiscount) {
        setValue(`items.${index}.totalAfterDiscount`, calculatedTotal);
      }
    });

    setSubTotal(calculatedSubTotal);

    const emptyBottleDiscount = watchEmptyBottles
      ? calculateEmptyBottleDiscount(watchEmptyBottles)
      : 0;

    setValue('totalAfterDiscount', calculatedSubTotal - watchDiscount - emptyBottleDiscount);
  }, [watchItems, setValue, watchDiscount, watchEmptyBottles]);

  useEffect(() => {
    const emptyBottleDiscount = watchEmptyBottles
      ? calculateEmptyBottleDiscount(watchEmptyBottles)
      : 0;

    setValue('totalAfterDiscount', subTotal - watchDiscount - emptyBottleDiscount);
  }, [setValue, watchDiscount, subTotal, watch, watchEmptyBottles]);

  const renderCreatePurchaseOrderButton = () => {
    if (currentBarDispatchItem?.status === TRANSACTION_STATUS.DRAFT || !currentBarDispatchItem) {
      return (
        <LoadingButton
          type="button"
          variant="outlined"
          loading={isSubmitting}
          onClick={() => handleSubmitWithStatus(TRANSACTION_STATUS.COMPLETED)}
        >
          {currentBarDispatchItem?.status === TRANSACTION_STATUS.DRAFT
            ? 'Mark as completed'
            : 'Create Dispatch Order'}
        </LoadingButton>
      );
    }
    return null;
  };

  const handleSubmitAction = () => {
    if (!currentBarDispatchItem) {
      handleSubmitWithStatus(TRANSACTION_STATUS.DRAFT);
      return;
    }

    if (currentBarDispatchItem?.status === TRANSACTION_STATUS.DRAFT) {
      handleSubmitWithStatus(TRANSACTION_STATUS.DRAFT);
      return;
    }

    handleSubmitWithStatus(TRANSACTION_STATUS.COMPLETED);
  };

  const getButtonText = () => {
    if (!currentBarDispatchItem) {
      return 'Save as Draft';
    }

    if (currentBarDispatchItem?.status === TRANSACTION_STATUS.DRAFT) {
      return 'Update Draft';
    }

    return 'Save Changes';
  };

  const isCompleted = currentBarDispatchItem?.status === TRANSACTION_STATUS.COMPLETED;

  return (
    <FormProvider methods={methods}>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <Card sx={{ p: 3 }}>
            <Grid container spacing={2}>
              <Grid
                mb={1}
                display="flex"
                mt={2}
                alignItems="center"
                justifyContent="space-between"
                width={1}
              >
                <Typography variant="subtitle2">Bill Information</Typography>
              </Grid>

              <Grid xs={12} md={4}>
                <Controller
                  name="purchaseOrDispatchDate"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomDatePicker
                      disabled={isCompleted}
                      disableFuture
                      label="Dispatch Date"
                      value={field.value}
                      onChange={(newValue) => {
                        field.onChange(newValue);
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!error,
                          helperText: error?.message,
                          required: true,
                        },
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid xs={12} md={4}>
                <RHFSelect fullWidth name="paymentStatus" label="Payment Status" required>
                  {Object.values(TRANSACTION_PAYMENT_STATUS).map((type) => (
                    <MenuItem
                      key={type}
                      value={type}
                      sx={{
                        textTransform: 'capitalize',
                      }}
                    >
                      {type.split('_').join(' ')}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>

              <Grid xs={12} md={4}>
                <RHFSelect fullWidth name="transactionType" label="Dispatch Status" required>
                  {Object.values(TRANSACTION_DISPATCH_TYPES)
                    .filter((type) => type !== TRANSACTION_DISPATCH_TYPES.RESTOCK)
                    .map((type) => (
                      <MenuItem
                        key={type}
                        value={type}
                        sx={{
                          textTransform: 'capitalize',
                        }}
                      >
                        {type.split('_').join(' ')}
                      </MenuItem>
                    ))}
                </RHFSelect>
              </Grid>

              <Divider
                sx={{
                  width: 1,
                  my: 2,
                  borderStyle: 'dashed',
                }}
              />

              <Grid mb={1} xs={12} width="full">
                <Typography variant="subtitle2">Line Items</Typography>
              </Grid>

              <Grid xs={12}>
                <Stack spacing={2}>
                  <TableContainer
                    ref={tableContainerRef}
                    sx={{ position: 'relative', overflow: 'hidden', height: '250px' }}
                  >
                    <Scrollbar>
                      <Table stickyHeader size="small" sx={{ minWidth: 960 }}>
                        <TableHeadCustom headLabel={LINE_ITEM_HEADERS} />

                        <TableBody>
                          {fields.map((item, index) => (
                            <BarDispatchNewItemRow
                              key={item.id}
                              index={index}
                              remove={(itemIndex) => {
                                remove(itemIndex);

                                fetchPaginatedBaritems();

                                if (isCompleted) {
                                  setRemovingLineItems((prev) => [...prev, item]);
                                }
                              }}
                              isLast={fields.length === 1 && index === 0}
                              isCompleted={isCompleted}
                              dispatchOrder={currentBarDispatchItem}
                            />
                          ))}
                        </TableBody>
                      </Table>
                    </Scrollbar>
                  </TableContainer>
                </Stack>

                {!isCompleted && (
                  <Stack mt={4}>
                    <Button variant="soft" color="primary" onClick={handleAppendItem}>
                      Add
                    </Button>
                  </Stack>
                )}
              </Grid>
            </Grid>

            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              justifyContent="flex-end"
              alignItems="center"
              gap={2}
              mt={3}
              divider={<Divider orientation="vertical" flexItem sx={{ borderStyle: 'dashed' }} />}
            >
              <Stack direction="row" gap={2}>
                <Typography variant="subtitle2">Sub Total</Typography>
                <Typography variant="subtitle2">{fCurrencyRupees(subTotal * 100)}</Typography>
              </Stack>

              <Stack direction="row" gap={2} alignItems="center">
                <Typography variant="caption">Discount</Typography>
                <RHFTextField
                  size="small"
                  name="discount"
                  type="number"
                  placeholder="0.00"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Box sx={{ typography: 'subtitle2', color: 'text.disabled' }}>Rs.</Box>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ maxWidth: { md: 150 } }}
                />
              </Stack>

              {!currentBarDispatchItem && (
                <Stack direction="row" gap={2} alignItems="center">
                  <Typography variant="caption">Empty Bottles</Typography>
                  <RHFTextField
                    size="small"
                    name="bottleCount"
                    type="number"
                    sx={{ maxWidth: { md: 150 } }}
                  />
                </Stack>
              )}

              <Stack direction="row" gap={2}>
                <Typography variant="subtitle1">Grand Total</Typography>
                <Typography variant="subtitle1">
                  {fCurrencyRupees(watch('totalAfterDiscount') * 100)}
                </Typography>
              </Stack>
            </Stack>

            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              justifyContent="space-between"
              gap={2}
              mt={5}
            >
              <Link
                component={RouterLink}
                href={paths.dashboard.bar.dispatch.root()}
                sx={{
                  width: {
                    xs: 1,
                    sm: 'auto',
                  },
                }}
              >
                <Button
                  variant="outlined"
                  color="inherit"
                  sx={{
                    width: 1,
                  }}
                >
                  Cancel
                </Button>
              </Link>

              <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="flex-end" gap={2}>
                {renderCreatePurchaseOrderButton()}

                <LoadingButton
                  type="button"
                  variant="contained"
                  loading={isSubmitting}
                  onClick={handleSubmitAction}
                >
                  {getButtonText()}
                </LoadingButton>
              </Stack>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}

import { AxiosError, HttpStatusCode } from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IBarListItem } from 'src/types/bar';
import { ILocationListItem } from 'src/types/location';
import { ICommonItemTransactionListItem } from 'src/types/item';
import { ISupplierItem, ISupplierListItem, ISupplierPaymentHistory } from 'src/types/supplier';

import axios from '../../utils/axios';

export interface ISupplyListItem extends IBarListItem {
  location: ILocationListItem[];
}

interface IInitialState {
  status: 'idle' | 'loading' | 'success' | 'failure';
  error: string | undefined;
  suppliers: {
    count: number;
    page: number;
    pageSize: number;
    pageCount: number;
    suppliers: ISupplierListItem[];
  };
  supplier: ISupplierItem | null;
  supplies: {
    error: string | undefined;
    status: 'idle' | 'loading' | 'success' | 'failure';
    items: ISupplyListItem[];
  };
  transactions: {
    status: 'idle' | 'loading' | 'success' | 'failure';
    error: string | undefined;
    transactions: {
      count: number;
      page: number;
      pageSize: number;
      pageCount: number;
      transactions: ICommonItemTransactionListItem[];
    };
  };
  payments: {
    status: 'idle' | 'loading' | 'success' | 'failure';
    error: string | undefined;
    payments: {
      count: number;
      page: number;
      pageSize: number;
      pageCount: number;
      payments: ISupplierPaymentHistory[];
    };
  };
}

const initialState: IInitialState = {
  status: 'idle',
  error: undefined,
  suppliers: {
    count: 0,
    page: 0,
    pageSize: 0,
    pageCount: 0,
    suppliers: [],
  },
  supplier: null,
  supplies: {
    status: 'idle',
    error: undefined,
    items: [],
  },
  transactions: {
    status: 'idle',
    error: undefined,
    transactions: {
      count: 0,
      page: 0,
      pageSize: 0,
      pageCount: 0,
      transactions: [],
    },
  },
  payments: {
    status: 'idle',
    error: undefined,
    payments: {
      count: 0,
      page: 0,
      pageSize: 0,
      pageCount: 0,
      payments: [],
    },
  },
};

const suppliersSlice = createSlice({
  name: 'suppliers',
  initialState,
  reducers: {
    removeSupplier: (state, action) => {
      const { supplierId } = action.payload;

      const index = state.suppliers.suppliers.findIndex((supplier) => supplier._id === supplierId);

      if (index > -1) {
        action.payload.index = index;
        state.suppliers.suppliers = state.suppliers.suppliers.filter(
          (supplier) => supplier._id !== supplierId
        );
      }
    },
    rollbackSupplierDeletion: (state, action) => {
      const { supplier, index } = action.payload;

      state.suppliers.suppliers.splice(index, 0, supplier);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPaginatedSuppliers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getPaginatedSuppliers.fulfilled, (state, action) => {
        state.status = 'success';
        state.suppliers = action.payload;
      })
      .addCase(getPaginatedSuppliers.rejected, (state, action) => {
        state.suppliers = initialState.suppliers;
        state.status = 'failure';
        state.error = action.error.message;
      })
      // Get User By ID
      .addCase(getSupplierById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getSupplierById.fulfilled, (state, action) => {
        state.status = 'success';
        state.supplier = action.payload;
        state.error = initialState.error;
      })
      .addCase(getSupplierById.rejected, (state, action) => {
        state.supplier = initialState.supplier;
        state.status = 'failure';
        state.error = action.error.message;
      })

      // get supplies by supplier

      .addCase(getInventoryItemsBySupplier.pending, (state) => {
        state.supplies.status = 'loading';
      })
      .addCase(getInventoryItemsBySupplier.fulfilled, (state, action) => {
        state.supplies.status = 'success';
        state.supplies.items = action.payload;
        state.supplies.error = undefined;
      })
      .addCase(getInventoryItemsBySupplier.rejected, (state, action) => {
        state.supplies.items = initialState.supplies.items;
        state.supplies.status = 'failure';
        state.supplies.error = action.error.message;
      })

      .addCase(getItemTransactionsBySupplier.pending, (state) => {
        state.transactions.status = 'loading';
      })
      .addCase(getItemTransactionsBySupplier.fulfilled, (state, action) => {
        state.transactions.status = 'success';
        state.transactions.transactions = action.payload;
      })
      .addCase(getItemTransactionsBySupplier.rejected, (state, action) => {
        state.transactions.status = 'failure';
        state.transactions.error = action.error.message;
      })

      .addCase(getPaymentsBySupplier.pending, (state, action) => {
        state.payments.status = 'loading';
      })
      .addCase(getPaymentsBySupplier.fulfilled, (state, action) => {
        state.payments.status = 'success';
        state.payments.payments = action.payload;
      })
      .addCase(getPaymentsBySupplier.rejected, (state, action) => {
        state.payments.status = 'failure';
        state.payments.error = action.error.message;
      });
  },
});

export default suppliersSlice.reducer;

export const { removeSupplier, rollbackSupplierDeletion } = suppliersSlice.actions;

export const getPaginatedSuppliers = createAsyncThunk(
  'suppliers/paginated',
  async (params: {
    page: number | null;
    limit: number | null;
    generalSearch: string | null;
    supplierName?: string | null;
    contactPerson?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    secondaryPhoneNumber?: string | null;
    locations?: string[] | null;
    locationsSeparator?: string | null;
    sortBy: string | null;
    sort: 'asc' | 'desc' | null;
    status?: 'active' | 'disabled' | null;
    paymentStatus?: 'settled' | 'outstanding' | 'over_paid' | null;
  }) => {
    try {
      const response = await axios.get(
        `supplier?page=${params.page !== null ? params.page + 1 : ''}&limit=${
          params.limit ?? ''
        }&generalSearch=${params.generalSearch ?? ''}&supplierName=${
          params.supplierName ?? ''
        }&contactPerson=${params.contactPerson ?? ''}&email=${params.email ?? ''}&phoneNumber=${
          params.phoneNumber ?? ''
        }&secondaryPhoneNumber=${params.secondaryPhoneNumber ?? ''}&status=${
          params.status ?? ''
        }&sortBy=${params.sortBy}&sort=${params.sort}&locations=${
          params.locations ? params.locations.join(params.locationsSeparator ?? ',') : ''
        }&createdBy=&paymentStatus=${params.paymentStatus ?? ''}`
      );

      return response.data.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const getSupplierById = createAsyncThunk(
  'suppliers/getSupplierById',
  async (params: { supplierID: string }) => {
    const { supplierID } = params;

    try {
      const response = await axios.get(`supplier/${supplierID}`);

      return response.data.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const deleteSupplier = createAsyncThunk(
  'suppliers/deleteSupplier',
  async (params: { supplier: ISupplierListItem; index: number }, { dispatch }) => {
    const { supplier, index } = params;

    try {
      const response = await axios.delete(`supplier/${supplier._id}`);
      return response.data.data;
    } catch (err) {
      if (err instanceof AxiosError && err.response?.status === HttpStatusCode.FailedDependency) {
        dispatch(rollbackSupplierDeletion({ supplier, index }));
        throw new AxiosError(err.response.data.message);
      }
      throw err;
    }
  }
);

export const disableSupplier = createAsyncThunk(
  'suppliers/disableSupplier',
  async (params: { supplier: any; index: number }, { dispatch }) => {
    const { supplier, index } = params;
    try {
      const response = await axios.put(`supplier/${supplier._id}`, {
        ...supplier,
        supplierName: supplier.supplierName,
        contactPerson: supplier.contactPerson,
        email: supplier.email,
        phoneNumber: supplier.phoneNumber,
        secondaryPhoneNumber: supplier.secondaryPhoneNumber,
        location: supplier.location.map((location: ILocationListItem) => location._id),
        status: 'disabled',
      });
      return response.data.data;
    } catch (err) {
      dispatch(rollbackSupplierDeletion({ supplier, index }));
      throw err;
    }
  }
);

export const enableSupplier = createAsyncThunk(
  'suppliers/enableSupplier',
  async (params: { supplier: any }) => {
    const { supplier } = params;
    const response = await axios.put(`supplier/${supplier._id}`, {
      ...supplier,
      supplierName: supplier.supplierName,
      contactPerson: supplier.contactPerson,
      email: supplier.email,
      phoneNumber: supplier.phoneNumber,
      secondaryPhoneNumber: supplier.secondaryPhoneNumber,
      location: supplier.location.map((location: ILocationListItem) => location._id),
      status: 'active',
    });
    return response.data.data;
  }
);

export interface TCreateSupplier
  extends Omit<
    ISupplierItem,
    | '_id'
    | 'createdAt'
    | 'status'
    | 'updatedAt'
    | 'inventoryItems'
    | 'location'
    | 'totalTransactionAmount'
    | 'totalPaidAmount'
  > {
  location: string[];
}

export const createSupplier = createAsyncThunk(
  'suppliers/createSupplier',
  async (params: { supplier: TCreateSupplier }) => {
    const { supplier } = params;

    const response = await axios.post(`supplier`, supplier);

    return response.data.data;
  }
);

export const updateSupplier = createAsyncThunk(
  'suppliers/updateSupplier',
  async (params: { supplierId: string; supplier: TCreateSupplier }) => {
    const { supplier, supplierId } = params;
    const response = await axios.put(`/supplier/${supplierId}`, supplier);
    return response.data.data;
  }
);

export const getInventoryItemsBySupplier = createAsyncThunk(
  'suppliers/getInventoryItems',
  async (params: { supplierId: string }) => {
    const { supplierId } = params;
    try {
      const response = await axios.get(
        `/inventory/item/by-supplier/${supplierId}?page&limit&sortBy=itemName&sort=asc`
      );
      return response.data.data.items;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const getItemTransactionsBySupplier = createAsyncThunk(
  'suppliers/getItemTransactionsBySupplier',
  async (params: {
    supplier: string;
    page: number;
    limit: number;
    sort: 'asc' | 'desc';
    sortBy: string;
  }) => {
    const { supplier, page, limit, sort, sortBy } = params;
    const response = await axios.get(
      `inventory/kitchen/transaction?page=${
        page + 1
      }&limit=${limit}&sortBy=${sortBy}&sort=${sort}&suppliers=${supplier}&status=completed&generalSearch=&transactionCode=&locations=&isDiscounted=&minTotalValue=&maxTotalValue=&transactionType=&paymentStatus=&updatedBy=&purchaseOrDispatchDate=&createdBy=&customers=`
    );

    return response.data.data;
  }
);

export const getPaymentsBySupplier = createAsyncThunk(
  'suppliers/payments',
  async (params: {
    supplierId: string;
    page?: number;
    limit?: number;
    generalSearch?: string;
    sortBy?: string;
    sort?: string;
  }) => {
    const { supplierId, page, limit, generalSearch, sort, sortBy } = params;
    try {
      const searchParams = new URLSearchParams({
        page: page?.toString() ?? '',
        limit: limit?.toString() ?? '',
        generalSearch: generalSearch ?? '',
        suppliers: supplierId,
        sort: sort ?? '',
        sortBy: sortBy ?? '',
        invoiceNumber: '',
        inventoryTransactions: '',
        inventoryTransactionDrafts: '',
        customers: '',
        transactionType: '',
        paymentMethod: '',
        minTotalValue: '',
        maxTotalValue: '',
        currency: '',
        status: 'completed',
        transactionStatus: '',
        startDate: '',
        endDate: '',
        createdBy: '',
        updatedBy: '',
      });

      const response = await axios.get(`/payment?${searchParams.toString()}`);
      return response.data.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

/* eslint-disable @typescript-eslint/no-unused-vars */
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';
import MaintenancePage from 'src/pages/maintenance';
import BarListPage from 'src/pages/dashboard/bar/list';
import BarEditPage from 'src/pages/dashboard/bar/edit';
import UserListPage from 'src/pages/dashboard/user/list';
import UserEditPage from 'src/pages/dashboard/user/edit';
import BarDetailPage from 'src/pages/dashboard/bar/view';
import UserCreatePage from 'src/pages/dashboard/user/new';
import UserDetailPage from 'src/pages/dashboard/user/view';
import BarItemCreatePage from 'src/pages/dashboard/bar/new';
import KitchenListPage from 'src/pages/dashboard/kitchen/list';
import KitchenEditPage from 'src/pages/dashboard/kitchen/edit';
import KitchenCreatePage from 'src/pages/dashboard/kitchen/new';
import KitchenDetailPage from 'src/pages/dashboard/kitchen/view';
import SupplierEditPage from 'src/pages/dashboard/suppliers/edit';
import CustomerEditPage from 'src/pages/dashboard/customers/edit';
import OverviewAnalyticsPage from 'src/pages/dashboard/analytics';
import SuppliersListPage from 'src/pages/dashboard/suppliers/list';
import CustomersListPage from 'src/pages/dashboard/customers/list';
import SupplierCreatePage from 'src/pages/dashboard/suppliers/new';
import CustomerCreatePage from 'src/pages/dashboard/customers/new';
import SupplierDetailPage from 'src/pages/dashboard/suppliers/view';
import CustomersDetailPage from 'src/pages/dashboard/customers/view';
import RestaurantListPage from 'src/pages/dashboard/restaurant/list';
import RestaurantEditPage from 'src/pages/dashboard/restaurant/edit';
import RestaurantCreatePage from 'src/pages/dashboard/restaurant/new';
import RestaurantDetailPage from 'src/pages/dashboard/restaurant/view';
import BarPurchaseListPage from 'src/pages/dashboard/bar/purchase/list';
import BarPurchaseCreatePage from 'src/pages/dashboard/bar/purchase/new';
import BarPurchaseOrderEditPage from 'src/pages/dashboard/bar/purchase/edit';
import BarDispatchOrderEditPage from 'src/pages/dashboard/bar/dispatch/edit';
import BarDispatchOrdersListPage from 'src/pages/dashboard/bar/dispatch/list';
import BarDispatchOrderCreatePage from 'src/pages/dashboard/bar/dispatch/new';
import BarPurchaseOrderDetailPage from 'src/pages/dashboard/bar/purchase/view';
import BarDispatchOrderDetailPage from 'src/pages/dashboard/bar/dispatch/view';
import KitchenPurchaseListPage from 'src/pages/dashboard/kitchen/purchase/list';
import KitchenDispatchListPage from 'src/pages/dashboard/kitchen/dispatch/list';
import KitchenPurchaseCreatePage from 'src/pages/dashboard/kitchen/purchase/new';
import KitchenDispatchCreatePage from 'src/pages/dashboard/kitchen/dispatch/new';
import BarPurchaseOrderPaymentPage from 'src/pages/dashboard/bar/purchase/payment';
import KitchenPurchaseOrderEditPage from 'src/pages/dashboard/kitchen/purchase/edit';
import KitchenDispatchOrderEditPage from 'src/pages/dashboard/kitchen/dispatch/edit';
import KitchenPurchaseOrderDetailPage from 'src/pages/dashboard/kitchen/purchase/view';
import KitchenDispatchOrderDetailPage from 'src/pages/dashboard/kitchen/dispatch/view';
import KitchenPurchaseOrderPaymentPage from 'src/pages/dashboard/kitchen/purchase/payment';

import { LoadingScreen } from 'src/components/loading-screen';

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { path: 'analytics', element: <OverviewAnalyticsPage /> },
      {
        path: 'user',
        children: [
          { element: <UserListPage />, index: true },
          { path: 'new', element: <UserCreatePage /> },
          { path: ':id/edit', element: <UserEditPage /> },
          { path: ':id/view', element: <UserDetailPage /> },
        ],
      },
      {
        path: 'kitchen',
        children: [
          { element: <KitchenListPage />, index: true },
          { path: 'new', element: <KitchenCreatePage /> },
          { path: ':id/edit', element: <KitchenEditPage /> },
          { path: ':id/view', element: <KitchenDetailPage /> },
        ],
      },
      {
        path: 'kitchen-purchase',
        children: [
          { element: <KitchenPurchaseListPage />, index: true },
          { path: 'new', element: <KitchenPurchaseCreatePage /> },
          { path: ':id/edit', element: <KitchenPurchaseOrderEditPage /> },
          { path: ':id/view', element: <KitchenPurchaseOrderDetailPage /> },
          { path: ':id/payment', element: <KitchenPurchaseOrderPaymentPage /> },
        ],
      },
      {
        path: 'kitchen-dispatch',
        children: [
          { element: <KitchenDispatchListPage />, index: true },
          { path: 'new', element: <KitchenDispatchCreatePage /> },
          { path: ':id/edit', element: <KitchenDispatchOrderEditPage /> },
          { path: ':id/view', element: <KitchenDispatchOrderDetailPage /> },
        ],
      },
      {
        path: 'bar',
        children: [
          { element: <BarListPage />, index: true },
          { path: 'new', element: <BarItemCreatePage /> },
          { path: ':id/edit', element: <BarEditPage /> },
          { path: ':id/view', element: <BarDetailPage /> },
        ],
      },
      {
        path: 'bar-purchase',
        children: [
          { element: <BarPurchaseListPage />, index: true },
          { path: 'new', element: <BarPurchaseCreatePage /> },
          { path: ':id/view', element: <BarPurchaseOrderDetailPage /> },
          { path: ':id/edit', element: <BarPurchaseOrderEditPage /> },
          { path: ':id/payment', element: <BarPurchaseOrderPaymentPage /> },
        ],
      },
      {
        path: 'bar-dispatch',
        children: [
          { element: <BarDispatchOrdersListPage />, index: true },
          { path: 'new', element: <BarDispatchOrderCreatePage /> },
          { path: ':id/view', element: <BarDispatchOrderDetailPage /> },
          { path: ':id/edit', element: <BarDispatchOrderEditPage /> },
        ],
      },
      {
        path: 'restaurant',
        children: [
          { element: <RestaurantListPage />, index: true },
          { path: 'new', element: <RestaurantCreatePage /> },
          { path: ':id/edit', element: <RestaurantEditPage /> },
          { path: ':id/view', element: <RestaurantDetailPage /> },
        ],
      },
      {
        path: 'suppliers',
        children: [
          { element: <SuppliersListPage />, index: true },
          { path: 'new', element: <SupplierCreatePage /> },
          { path: ':id/edit', element: <SupplierEditPage /> },
          { path: ':id/view', element: <SupplierDetailPage /> },
        ],
      },
      {
        path: 'customers',
        children: [
          { element: <CustomersListPage />, index: true },
          { path: 'new', element: <CustomerCreatePage /> },
          { path: ':id/edit', element: <CustomerEditPage /> },
          { path: ':id/view', element: <CustomersDetailPage /> },
        ],
      },
      {
        path: 'sales',
        children: [{ element: <MaintenancePage />, index: true }],
      },

      // {
      //   path: 'order',
      //   children: [
      //     { element: <OrderListPage />, index: true },
      //     { path: 'list', element: <OrderListPage /> },
      //     { path: ':id', element: <OrderDetailsPage /> },
      //   ],
      // },

      // {
      //   path: 'invoice',
      //   children: [
      //     { element: <InvoiceListPage />, index: true },
      //     { path: 'list', element: <InvoiceListPage /> },
      //     { path: ':id', element: <InvoiceDetailsPage /> },
      //     { path: ':id/edit', element: <InvoiceEditPage /> },
      //     { path: 'new', element: <InvoiceCreatePage /> },
      //   ],
      // },
      // {
      //   path: 'post',
      //   children: [
      //     { element: <BlogPostsPage />, index: true },
      //     { path: 'list', element: <BlogPostsPage /> },
      //     { path: ':title', element: <BlogPostPage /> },
      //     { path: ':title/edit', element: <BlogEditPostPage /> },
      //     { path: 'new', element: <BlogNewPostPage /> },
      //   ],
      // },

      // { path: 'file-manager', element: <FileManagerPage /> },
      // { path: 'mail', element: <MailPage /> },
      // { path: 'chat', element: <ChatPage /> },
      // { path: 'calendar', element: <CalendarPage /> },
      // { path: 'kanban', element: <KanbanPage /> },
    ],
  },
];

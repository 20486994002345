import { paths } from 'src/routes/paths';

export const HOST_API = import.meta.env.VITE_HOST_API;
export const ASSETS_API = import.meta.env.VITE_ASSETS_API;

export const APP_ENVIRONMENT = import.meta.env.VITE_APP_ENVIRONMENT as string;

export const KITCHEN_LOCATION_ID = import.meta.env.VITE_KITCHEN_LOCATION_ID as string;
export const BAR_LOCATION_ID = import.meta.env.VITE_BAR_LOCATION_ID as string;

export const UNIT_ID = import.meta.env.VITE_UNIT_ID as string;
export const RESTAURANT_LOCATION_ID = import.meta.env.VITE_RESTAURANT_LOCATION_ID as string;

export const VITE_DEFAULT_SUPPLIER = import.meta.env.VITE_DEFAULT_SUPPLIER as string;
export const VITE_EMPTY_BOTTLE_ITEM_ID = import.meta.env.VITE_EMPTY_BOTTLE_ITEM_ID as string;

export const APP_VERSION_NUMBER = import.meta.env.VITE_APP_VERSION_TAG as string;

export const FIREBASE_CLOUD_MESSAGING_VAPID_KEY =
  'BLO1g7a5xhPJn9CSij9N-LhwZjlzjLpvqzOy8CRandTKqa5Q70V-5B4OzCdspuDR1kG4uRZQnO08f-rxuP__wh0';

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.root; // as '/dashboard'

export const APP_BRAND_NAME = import.meta.env.VITE_BRAND_NAME as string;

export const DEFAULT_INTERNAL_SERVER_ERROR_MESSAGE =
  'Something went wrong, we are actively working on this issue.';

export const AccountStatus = {
  BLOCKED: 'disabled',
  ACTIVE: 'active',
};

export const RESPONSE_STATUS = {
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
};

export const CUSTOMER_STATUS = {
  PENDING: 'Pending',
  VERIFY: 'Verified',
  REJECTED: 'Rejected',
};

export const ProjectStatus = {
  PENDING: 'Pending',
  VERIFIED: 'Verified',
  REJECTED: 'Rejected',
};

export const QuotStatus = {
  SENT: 'Sent',
  COMPLETED: 'Completed',
  DRAFT: 'Draft',
};

export const FROM_DETAILS = {
  name: 'Taucan Electricals',
  address: 'Taucan Electricals, UK',
  phone: '+562343434343',
};

import { IUser } from 'src/redux/slices/users';
import { AuthPermissions } from 'src/auth/permissions';
import { BAR_LOCATION_ID, KITCHEN_LOCATION_ID } from 'src/config-global';

export const hasPermission = (
  user: IUser | null,
  permissionsType: 'any' | 'all',
  ...requiredPermissions: string[]
): boolean => {
  if (!user || !user.permissions) return false;

  const userPermissionNames = user.permissions.map((permission) => permission.name);

  if (permissionsType === 'all') {
    return requiredPermissions.every((permission) => userPermissionNames.includes(permission));
  }

  if (permissionsType === 'any') {
    return requiredPermissions.some((permission) => userPermissionNames.includes(permission));
  }

  return false;
};

export const authLocations = (user: IUser | null): string[] => {
  const locations = [];

  if (
    hasPermission(
      user,
      'any',
      AuthPermissions.ADMIN_PERMISSION,
      AuthPermissions.SUPER_ADMIN_PERMISSION
    )
  ) {
    console.log(KITCHEN_LOCATION_ID, BAR_LOCATION_ID);

    locations.push(KITCHEN_LOCATION_ID);
    locations.push(BAR_LOCATION_ID);
    return locations;
  }

  const hasKitchenPermissions = hasPermission(user, 'all', AuthPermissions.READ_KITCHEN_ITEM);

  if (hasKitchenPermissions) {
    locations.push(KITCHEN_LOCATION_ID);
  }

  const hasBarPermissions = hasPermission(user, 'all', AuthPermissions.READ_BAR_ITEM);

  if (hasBarPermissions) {
    locations.push(BAR_LOCATION_ID);
  }

  return locations;
};

import { Controller, useFormContext } from 'react-hook-form';

import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';

import { PAYMENT_METHOD } from 'src/utils/common-types';

import { TCreateKitchenPurchaseOrder } from 'src/redux/slices/kitchen-purchase-order';

import Iconify from 'src/components/iconify';
import { RHFTextField } from 'src/components/hook-form';
import CustomDatePicker from 'src/components/custom-datepicker/custom-datepicker';

interface IKitchenPurchasePaymentRow {
  index: number;
  remove: (index: number) => void;
}

const PAYMENT_METHODS = [
  {
    label: 'Cash',
    value: PAYMENT_METHOD.Cash,
  },
  {
    label: 'Card',
    value: PAYMENT_METHOD.Card,
  },
  {
    label: 'Cheque',
    value: PAYMENT_METHOD.Cheque,
  },
  {
    label: 'Bank Transfer',
    value: PAYMENT_METHOD.BankTransfer,
  },
  {
    label: 'Voucher',
    value: PAYMENT_METHOD.Voucher,
  },
];

function KitchenPurchasePaymentRow({ index, remove }: IKitchenPurchasePaymentRow) {
  const {
    control,
    formState: { errors },
  } = useFormContext<TCreateKitchenPurchaseOrder>();

  return (
    <TableRow>
      <TableCell>{index + 1 < 10 ? `0${index + 1}` : index + 1}</TableCell>

      <TableCell align="left">
        <Controller
          name={`payments.${index}.paymentDate`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <CustomDatePicker
              label=""
              disableFuture
              value={field.value}
              onChange={(newValue) => {
                field.onChange(newValue);
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  error: !!error,
                  required: true,
                  size: 'small',
                  sx: {
                    maxWidth: 200,
                  },
                },
              }}
            />
          )}
        />
      </TableCell>

      <TableCell align="right">
        <RHFTextField
          size="small"
          required
          type="number"
          name={`payments.${index}.amount`}
          placeholder="0.00"
          label=""
          fullWidth
          inputProps={{
            style: {
              textAlign: 'right',
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Box
                  sx={{
                    typography: 'subtitle2',
                    color: 'text.disabled',
                  }}
                >
                  Rs.
                </Box>
              </InputAdornment>
            ),
            endAdornment: errors?.payments?.[index]?.amount ? (
              <InputAdornment position="end">
                <Tooltip title={errors?.payments?.[index]?.amount?.message || 'Invalid Amount'}>
                  <Iconify icon="eva:info-outline" color="error.main" />
                </Tooltip>
              </InputAdornment>
            ) : null,
          }}
          showError={false}
        />
      </TableCell>

      <TableCell>
        <Controller
          name={`payments.${index}.method`}
          control={control}
          render={({ field, fieldState }) => (
            <FormControl
              required
              sx={{
                width: 1,
                minWidth: 200,
              }}
            >
              <Select
                {...field}
                size="small"
                multiple={false}
                input={<OutlinedInput fullWidth label="" />}
              >
                {PAYMENT_METHODS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </TableCell>

      <TableCell align="right">
        <Tooltip title="Delete">
          <span>
            <IconButton color="error" onClick={() => remove(index)}>
              <Iconify icon="solar:trash-bin-trash-bold" />
            </IconButton>
          </span>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
}

export default KitchenPurchasePaymentRow;

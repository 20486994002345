import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

import barReducer from './slices/bar';
import itemsReducer from './slices/item';
import usersReducer from './slices/users';
import unitsReducer from './slices/units';
import inventoryReducer from './slices/kitchen';
import locationsReducer from './slices/location';
import suppliersReducer from './slices/suppliers';
import customersReducer from './slices/customers';
import restaurantReducer from './slices/restaurant';
import barSummaryReducer from './slices/bar-summary';
import permissionsReducer from './slices/permissions';
import monthlySalesReducer from './slices/monthly-sales';
import purchaseAnalysisReducer from './slices/purchase-analysis';
import inventoryAnalysisReducer from './slices/inventory-analysis';
import barDispatchOrdersReducer from './slices/bar-dispatch-order';
import barPurchaseOrdersReducer from './slices/bar-purchase-order';
import kitchenPurchaseOrdersReducer from './slices/kitchen-purchase-order';
import kitchenDispatchOrdersReducer from './slices/kitchen-dispatch-order';
import monthlySalesQuantitiesReducer from './slices/monthly-sales-quantities';

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  users: usersReducer,
  inventory: inventoryReducer,
  permissions: permissionsReducer,
  units: unitsReducer,
  locations: locationsReducer,
  bar: barReducer,
  restaurant: restaurantReducer,
  suppliers: suppliersReducer,
  customers: customersReducer,
  kitchenPurchaseOrders: kitchenPurchaseOrdersReducer,
  kitchenDispatchOrders: kitchenDispatchOrdersReducer,
  barDispatchOrders: barDispatchOrdersReducer,
  barPurchaseOrders: barPurchaseOrdersReducer,
  items: itemsReducer,
  barSummary: barSummaryReducer,
  inventoryAnalysis: inventoryAnalysisReducer,
  purchaseAnalysis: purchaseAnalysisReducer,
  monthlySales: monthlySalesReducer,
  monthlySalesQuantities: monthlySalesQuantitiesReducer,
});

export { rootPersistConfig, rootReducer };

import { isEqual } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';

import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import { Button, Stack } from '@mui/material';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';

import { useResponsive } from 'src/hooks/use-responsive';

import { hasPermission } from 'src/utils/has-permissions';
import logToucodeEvent, { APP_EVENTS } from 'src/utils/log-google-events';

import { useAuthContext } from 'src/auth/hooks';
import { AuthPermissions } from 'src/auth/permissions';
import { useAppDispatcher, useAppSelector } from 'src/redux/store';
import {
  deleteRestaurantItem,
  disableRestaurantItem,
  getRestaurantItems,
  reActivateRestaurantItem,
  removeRestaurantItem,
} from 'src/redux/slices/restaurant';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import TableErrorData from 'src/components/table/table-error-data';
import {
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSkeleton,
  useTable,
} from 'src/components/table';

import { DocumentStatus, getDocumentAPIStatus } from 'src/types/common';
import {
  IRestaurantListItem,
  IRestaurantTableFilters,
  IRestaurantTableFilterValue,
  IRestaurantTableSort,
} from 'src/types/restaurant';

import RestaurantTableRow from '../restaurant-table-row';
import RestaurantTableToolbar from '../restaurant-table-toolbar';

const TABLE_HEAD = [
  { id: 'itemName', label: 'Item Name', align: 'left', primary: true },
  { id: 'price', label: 'Selling Price', align: 'right', primary: true },
  { id: 'updatedAt', label: 'Updated', align: 'left', primary: true },
  { id: '', primary: true },
];

const defaultFilters: IRestaurantTableFilters = {
  generalSearch: '',
  status: DocumentStatus.ACTIVE,
};

const defaultSortBy: IRestaurantTableSort = {
  sortBy: 'updatedAt',
  order: 'desc',
};

const disablingRestaurantItemInitialState: {
  state: boolean;
  index: number | null;
  message: string;
  item: IRestaurantListItem | null;
} = {
  state: false,
  index: null,
  message: '',
  item: null,
};

export default function RestaurantListView() {
  const smDown = useResponsive('down', 'sm');

  const { status, restaurant, error } = useAppSelector((state) => state.restaurant);

  const { user: authUser } = useAuthContext();

  const table = useTable({
    defaultRowsPerPage: 25,
  });

  const dispatch = useAppDispatcher();

  const { page, rowsPerPage } = table;

  const settings = useSettingsContext();

  const denseHeight = table.dense ? 60 : 80;

  const router = useRouter();

  const [disablingRestaurantItem, setDisablingRestaurantItem] = useState(
    disablingRestaurantItemInitialState
  );

  const [filters, setFilters] = useState(defaultFilters);

  const [sort, setSort] = useState(defaultSortBy);

  const canReset = !isEqual(defaultFilters, filters);

  const handleFilters = useCallback(
    (name: string, value: IRestaurantTableFilterValue) => {
      table.onResetPage();

      setFilters((prev) => ({
        ...prev,
        [name]: value,
      }));
    },
    [table]
  );

  const handleEditRow = useCallback(
    (id: string) => {
      router.push(paths.dashboard.restaurant.edit(id));
    },
    [router]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const fetchPaginatedRestaurantItems = useCallback(() => {
    dispatch(
      getRestaurantItems({
        page: page + 1,
        limit: rowsPerPage,
        sortBy: sort.sortBy,
        sort: sort.order,
        generalSearch: filters.generalSearch,
        unitsSeparator: ',',
        status: getDocumentAPIStatus(filters.status),
      })
    );

    if (page > 1) {
      logToucodeEvent(APP_EVENTS.TABLE_PAGINATION_MORE_THAN_TWO, {
        page,
        rowsPerPage,
      });
    }
  }, [dispatch, page, rowsPerPage, sort, filters]);

  const handleDeleteRow = useCallback(
    async (item: IRestaurantListItem, index: number) => {
      dispatch(removeRestaurantItem({ itemId: item._id }));

      try {
        await dispatch(deleteRestaurantItem({ item, index })).unwrap();

        fetchPaginatedRestaurantItems();
        enqueueSnackbar('Restaurant item deleted');
      } catch (e) {
        if ('name' in e && e.name === 'AxiosError') {
          setDisablingRestaurantItem({
            state: true,
            message: e.message,
            item,
            index,
          });
        } else if (typeof e === 'string') {
          enqueueSnackbar(e, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(e?.message, {
            variant: 'error',
          });
        }
      }
    },
    [dispatch, fetchPaginatedRestaurantItems]
  );

  const handleDisableRow = useCallback(
    async (item: IRestaurantListItem, index: number) => {
      dispatch(removeRestaurantItem({ itemId: item._id }));

      try {
        await dispatch(disableRestaurantItem({ item, index })).unwrap();

        fetchPaginatedRestaurantItems();
        enqueueSnackbar('Restaurant item disabled');
      } catch (e) {
        if (typeof e === 'string') {
          enqueueSnackbar(e, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(e?.message, {
            variant: 'error',
          });
        }
      }
    },
    [dispatch, fetchPaginatedRestaurantItems]
  );

  const handleReActivateRow = useCallback(
    async (item: IRestaurantListItem, index: number) => {
      try {
        await dispatch(reActivateRestaurantItem({ item })).unwrap();

        setFilters(defaultFilters);

        enqueueSnackbar('Restaurant item re-activated');
      } catch (e) {
        if (typeof e === 'string') {
          enqueueSnackbar(e, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(e?.message, {
            variant: 'error',
          });
        }
      }
    },
    [dispatch]
  );

  useEffect(() => {
    fetchPaginatedRestaurantItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPaginatedRestaurantItems]);

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Restaurant Items"
          links={[]}
          action={
            <Stack direction="row" gap={2}>
              {hasPermission(
                authUser,
                'any',
                AuthPermissions.CREATE_RESTAURANT_ITEM,
                AuthPermissions.ADMIN_PERMISSION,
                AuthPermissions.SUPER_ADMIN_PERMISSION
              ) && (
                <Button
                  component={RouterLink}
                  href={paths.dashboard.restaurant.new}
                  variant="contained"
                  startIcon={<Iconify icon="mingcute:add-line" />}
                >
                  {smDown ? 'New' : 'New Restaurant Item'}
                </Button>
              )}
            </Stack>
          }
          sx={{
            mb: { xs: 3 },
          }}
        />

        <Card>
          <RestaurantTableToolbar
            filters={filters}
            onFilters={handleFilters}
            handleResetFilters={handleResetFilters}
            canReset={canReset}
          />

          <TableContainer sx={{ position: 'relative', overflow: 'hidden', height: '450px' }}>
            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                <TableHeadCustom
                  order={sort.order}
                  orderBy={sort.sortBy}
                  headLabel={TABLE_HEAD.filter(({ primary }) => {
                    if (settings.themeLayout === 'vertical') {
                      return primary;
                    }
                    return true;
                  })}
                  rowCount={restaurant.items.length}
                  numSelected={table.selected.length}
                  onSort={(id) => {
                    if (id === sort.sortBy) {
                      setSort((prev) => ({
                        ...prev,
                        order: prev.order === 'asc' ? 'desc' : 'asc',
                      }));
                    } else {
                      setSort({
                        sortBy: id,
                        order: 'asc',
                      });
                    }
                  }}
                />

                <TableBody>
                  {status === 'loading' ? (
                    [...Array(table.rowsPerPage)].map((i, index) => (
                      <TableSkeleton key={index} sx={{ height: denseHeight }} />
                    ))
                  ) : (
                    <>
                      {restaurant?.items.map((row: IRestaurantListItem, index: number) => (
                        <RestaurantTableRow
                          key={row._id}
                          row={row}
                          selected={table.selected.includes(row._id)}
                          onDeleteRow={() => handleDeleteRow(row, index)}
                          onEditRow={() => handleEditRow(row._id)}
                          onReActivateRow={() => handleReActivateRow(row, index)}
                        />
                      ))}
                    </>
                  )}

                  {status !== 'failure' && restaurant.count === 0 && (
                    <TableNoData
                      message="No restaurant items found"
                      notFound={restaurant.count === 0}
                    />
                  )}

                  {status === 'failure' && <TableErrorData notFound error={error} />}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={restaurant.count}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={(value) => {
              table.onChangeDense(value);

              logToucodeEvent(APP_EVENTS.TABLE_COMPACT_MODE_TOGGLE, {
                compact: value,
                table: 'restaurant_table',
              });
            }}
          />
        </Card>
      </Container>
      <ConfirmDialog
        open={disablingRestaurantItem.state}
        onClose={() => setDisablingRestaurantItem(disablingRestaurantItemInitialState)}
        title={`Action Required: Cannot Delete ${disablingRestaurantItem.item?.itemName}`}
        content={disablingRestaurantItem.message}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              if (disablingRestaurantItem.item !== null && disablingRestaurantItem.index !== null) {
                handleDisableRow(disablingRestaurantItem.item, disablingRestaurantItem.index);
                setDisablingRestaurantItem(disablingRestaurantItemInitialState);
              }
            }}
          >
            Temporarily Delete
          </Button>
        }
      />
    </>
  );
}

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { PAYMENT_METHOD, TRANSACTION_STATUS, TRANSACTION_TYPE } from 'src/utils/common-types';

import { BAR_LOCATION_ID } from 'src/config-global';

import {
  IBarPurchaseOrderDetailedItem,
  IBarPurchaseOrderListItem,
  IBarPurchaseOrderPayment,
} from 'src/types/bar-purchase';

import axios from '../../utils/axios';

interface IInitialState {
  status: 'idle' | 'loading' | 'success' | 'failure';
  error: string | undefined;
  transactions: {
    count: number;
    page: number;
    pageSize: number;
    pageCount: number;
    transactions: IBarPurchaseOrderListItem[];
  };
  item: IBarPurchaseOrderDetailedItem | null;

  payments: {
    status: 'idle' | 'loading' | 'success' | 'failure';
    error: string | undefined;
    payments: {
      count: number;
      page: number;
      pageSize: number;
      pageCount: number;
      payments: IBarPurchaseOrderPayment[];
    };
  };
}

const initialState: IInitialState = {
  status: 'idle',
  error: undefined,
  transactions: {
    count: 0,
    page: 1,
    pageSize: 10,
    pageCount: 0,
    transactions: [],
  },
  item: null,

  payments: {
    status: 'idle',
    error: undefined,
    payments: {
      count: 0,
      page: 0,
      pageSize: 0,
      pageCount: 0,
      payments: [],
    },
  },
};

const barPurchaseOrders = createSlice({
  name: 'barPurchaseOrders',
  initialState,
  reducers: {
    removeBarPurchaseOrderItem: (state, action) => {
      const { itemId } = action.payload;

      const index = state.transactions.transactions.findIndex((item) => item._id === itemId);

      if (index > -1) {
        action.payload.index = index; // Store the index for rollback
        state.transactions.transactions = state.transactions.transactions.filter(
          (item) => item._id !== itemId
        );
      }
    },
    rollbackBarPurchaseOrderDeletion: (state, action) => {
      const { item, index } = action.payload;

      state.transactions.transactions.splice(index, 0, item);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBarPurchaseOrders.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getBarPurchaseOrders.fulfilled, (state, action) => {
        state.status = 'success';
        state.transactions = action.payload;
      })
      .addCase(getBarPurchaseOrders.rejected, (state, action) => {
        state.status = 'failure';
        state.error = action.error.message;
      })
      .addCase(getBarPurchaseOrderById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getBarPurchaseOrderById.fulfilled, (state, action) => {
        state.status = 'success';
        state.item = action.payload;
      })
      .addCase(getBarPurchaseOrderById.rejected, (state, action) => {
        state.status = 'failure';
        state.error = action.error.message;
      })
      .addCase(getBarPurchasePayments.pending, (state) => {
        state.payments.status = 'loading';
      })
      .addCase(getBarPurchasePayments.fulfilled, (state, action) => {
        state.payments.status = 'success';
        state.payments.payments = action.payload;
      })
      .addCase(getBarPurchasePayments.rejected, (state, action) => {
        state.payments.status = 'failure';
        state.payments.error = action.error.message;
      });
  },
});

export default barPurchaseOrders.reducer;

export const { removeBarPurchaseOrderItem, rollbackBarPurchaseOrderDeletion } =
  barPurchaseOrders.actions;

export const getBarPurchaseOrders = createAsyncThunk(
  'bar/purchase/transactions',
  async (
    params: {
      page: number;
      limit: number;
      generalSearch?: string | null;
      status?: string | null;
      paymentStatus?: string | null;
      purchaseDate?: string | null;
      minTotalValue?: number | null;
      maxTotalValue?: number | null;
      sortBy?: string | null;
      sort?: 'asc' | 'desc' | null;
    } = {
      page: 1,
      limit: 10,
      generalSearch: null,
      status: null,
      paymentStatus: null,
      purchaseDate: null,
      minTotalValue: null,
      maxTotalValue: null,
      sort: 'desc',
      sortBy: 'updatedAt',
    }
  ) => {
    const {
      page,
      limit,
      generalSearch,
      status,
      paymentStatus,
      purchaseDate,
      minTotalValue,
      maxTotalValue,
      sortBy,
      sort,
    } = params;

    try {
      const response = await axios.get(
        `inventory/bar/transaction?page=${page + 1}&limit=${limit}&generalSearch=${
          generalSearch ?? ''
        }&transactionCode=&status=${status ?? ''}&transactionType=purchase&paymentStatus=${
          paymentStatus ?? ''
        }&purchaseOrDispatchDate=${purchaseDate ?? ''}&minTotalValue=${
          minTotalValue ?? ''
        }&maxTotalValue=${
          maxTotalValue ?? ''
        }&isDiscounted=&suppliers=&locations=${BAR_LOCATION_ID}&updatedBy=&sortBy=${
          sortBy ?? ''
        }&sort=${sort ?? ''}&createdBy=&customers=`
      );

      return response.data.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const getBarPurchaseOrderById = createAsyncThunk(
  'bar/purchase/transaction/id',
  async (params: { itemID: string }) => {
    try {
      const { itemID } = params;
      const response = await axios.get(`inventory/bar/transaction/${itemID}`);

      return response.data.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const deleteBarPurchaseOrder = createAsyncThunk(
  'bar/purchase/delete',
  async (params: { item: IBarPurchaseOrderListItem; index: number }, { dispatch }) => {
    const { item, index } = params;

    try {
      const response = await axios.delete(`inventory/bar/transaction/${item._id}`);
      return response.data.data;
    } catch (err) {
      dispatch(rollbackBarPurchaseOrderDeletion({ item, index }));
      throw err;
    }
  }
);

export interface TCreateBarPurchaseOrderItem {
  inventoryItem: string;
  quantity: number;
  unit: string;
  unitPrice: number;
  discount: number;
  totalAfterDiscount: number;
}

export interface TCreateBarPurchaseOrder {
  location: string;
  supplier: string;
  reason: string | null;
  discount: number;
  isPercentageDiscount: boolean;
  totalAfterDiscount: number;
  roundOffTotal: number;
  transactionType: TRANSACTION_TYPE;
  purchaseOrDispatchDate: Date;
  status: TRANSACTION_STATUS;
  items: TCreateBarPurchaseOrderItem[];
  payments: TCreateBarPurchaseOrderPaymentRow[];
  paidAmount: number;
  subTotal: number;
}

export interface TCreateBarPurchaseOrderPayment {
  subTotal: number;
  totalAfterDiscount: number;
  roundOffTotal: number;
  discount: number;
  paidAmount: number;
  payments: TCreateBarPurchaseOrderPaymentRow[];
}

export interface TCreateBarPurchaseOrderPaymentRow {
  method: PAYMENT_METHOD;
  amount: number;
  paymentDate: Date;
  isNew: boolean;
  _id?: string;
}

export const createBarPurchaseOrder = createAsyncThunk(
  'bar/purchaseOrder/create',
  async (params: { poTransaction: Omit<TCreateBarPurchaseOrder, 'payments' | 'paidAmount'> }) => {
    const { poTransaction } = params;
    const response = await axios.post(`inventory/bar/transaction`, poTransaction);
    return response.data.data;
  }
);

export const updateBarPurchaseOrder = createAsyncThunk(
  'bar/purchaseOrder/update',
  async (params: {
    purchaseOrderId: string;
    poTransaction: Omit<TCreateBarPurchaseOrder, 'payments' | 'paidAmount'>;
  }) => {
    const { poTransaction, purchaseOrderId } = params;
    const response = await axios.put(`inventory/bar/transaction/${purchaseOrderId}`, poTransaction);
    return response.data.data;
  }
);

export const updateBarPurchaseDiscount = createAsyncThunk(
  'bar/purchaseOrder/update/discount',
  async (params: {
    purchaseOrderId: string;
    transaction: IBarPurchaseOrderDetailedItem;
    discount: number;
    totalAfterDiscount: number;
  }) => {
    const { transaction, purchaseOrderId, discount, totalAfterDiscount } = params;
    const response = await axios.put(`inventory/transaction/${purchaseOrderId}`, {
      ...{
        ...transaction,
        items: transaction.items.map((item) => ({
          ...item,
          inventoryItem: item.inventoryItem._id,
          unit: item.unit._id,
        })),
        supplier: transaction.supplier._id,
      },
      discount,
      totalAfterDiscount,
    });
    return response.data.data;
  }
);

export const updateCompletedBarPurchaseOrder = createAsyncThunk(
  'bar/purchaseOrder/completed/update',
  async (params: {
    purchaseOrderId: string;
    poTransaction: Omit<TCreateBarPurchaseOrder, 'payments' | 'paidAmount'>;
  }) => {
    const { poTransaction, purchaseOrderId } = params;
    const response = await axios.put(`inventory/transaction/${purchaseOrderId}`, poTransaction);
    return response.data.data;
  }
);

export const barPurchaseOrderMarkAsCompleted = createAsyncThunk(
  'bar/purchaseOrder/marAsCompleted',
  async (params: { purchaseOrderId: string; purchaseOrder: IBarPurchaseOrderDetailedItem }) => {
    const { purchaseOrderId, purchaseOrder } = params;

    const response = await axios.put(`/inventory/bar/transaction/${purchaseOrderId}`, {
      ...purchaseOrder,
      status: TRANSACTION_STATUS.COMPLETED,
      supplier: purchaseOrder.supplier?._id,
      items: purchaseOrder.items.map((item) => ({
        ...item,
        inventoryItem: item.inventoryItem._id,
        unit: item.unit._id,
      })),
    });
    return response.data.data;
  }
);

export const barPurchaseOrderMakePayment = createAsyncThunk(
  'bar/purchaseOrder/payments/create',
  async (params: {
    purchaseOrderId: string;
    payment: Omit<TCreateBarPurchaseOrderPaymentRow, '_id'>;
    supplier: string;
  }) => {
    const { payment, purchaseOrderId, supplier } = params;
    const response = await axios.post('payment', {
      inventoryTransaction: purchaseOrderId,
      supplier,
      amount: payment.amount,
      paymentMethod: payment.method,
      paymentDate: payment.paymentDate,
    });
    return response.data.data;
  }
);

export const getBarPurchasePayments = createAsyncThunk(
  'bar/purchaseOrder/payments',
  async (params: { purchaseOrderId: string; page?: number; limit?: number }) => {
    try {
      const response = await axios.get(
        `payment?page=${params.page ?? ''}&limit=${
          params.limit ?? ''
        }&generalSearch=&invoiceNumber=&inventoryTransactions=${
          params.purchaseOrderId
        }&inventoryTransactionDrafts&suppliers=&customers=&transactionType=&paymentMethod&minTotalValue=&maxTotalValue=&currency&status=&startDate&endDate&createdBy=&updatedBy=&sortBy=createdAt&sort=asc&transactionStatus=`
      );

      return response.data.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const barPurchaseOrderDeletePayment = createAsyncThunk(
  'bar/purchaseOrder/payments/delete',
  async (params: { paymentId: string }) => {
    const { paymentId } = params;
    const response = await axios.delete(`payment/${paymentId}`);
    return response.data.data;
  }
);

export const barPurchaseOrderUpdatePayment = createAsyncThunk(
  'bar/purchaseOrder/payments/update',
  async (params: {
    paymentId: string;
    purchaseOrderId: string;
    payment: Omit<TCreateBarPurchaseOrderPaymentRow, '_id'>;
    supplier: string;
  }) => {
    const { payment, purchaseOrderId, supplier, paymentId } = params;
    const response = await axios.put(`payment/${paymentId}`, {
      inventoryTransaction: purchaseOrderId,
      supplier,
      amount: payment.amount,
      paymentMethod: payment.method,
      paymentDate: payment.paymentDate,
    });
    return response.data.data;
  }
);

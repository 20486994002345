import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { useCallback, useEffect, useState } from 'react';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Divider,
  Link,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';

import { RouterLink } from 'src/routes/components';

import { fDateTime } from 'src/utils/format-time';
import { fCurrencyRupees } from 'src/utils/format-number';
import { hasPermission } from 'src/utils/has-permissions';
import {
  PAYMENT_METHOD,
  paymentMethodStringToFormattedString,
  TABLE_HEAD_CELL_TYPE,
  TRANSACTION_PAYMENT_STATUS,
  TRANSACTION_STATUS,
} from 'src/utils/common-types';

import { useAuthContext } from 'src/auth/hooks';
import { AuthPermissions } from 'src/auth/permissions';
import { useAppDispatcher, useAppSelector } from 'src/redux/store';
import {
  barPurchaseOrderMarkAsCompleted,
  getBarPurchasePayments,
} from 'src/redux/slices/bar-purchase-order';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import DatePoint from 'src/components/data-point/data-point';
import { TableHeadCustom, TableNoData } from 'src/components/table';

import { IBarPurchaseOrderDetailedItem, IBarPurchaseOrderPayment } from 'src/types/bar-purchase';

import { paths } from '../../routes/paths';

const LINE_ITEM_HEADERS: TABLE_HEAD_CELL_TYPE[] = [
  { id: 'itemNo', label: '#', align: 'left', primary: true },
  {
    id: 'itemName',
    label: 'Item Name',
    align: 'left',
    primary: true,
    minWidth: 300,
  },
  { id: 'quantity', label: 'Quantity', align: 'left', primary: true },
  {
    id: 'unitPrice',
    label: 'Unit Price',
    align: 'right',
    primary: true,
  },
  { id: 'discount', label: 'Discount', align: 'right', primary: true },
  { id: 'total', label: 'Total', align: 'right', primary: true },
];

const PAYMENTS_HEADERS: TABLE_HEAD_CELL_TYPE[] = [
  { id: 'itemNo', label: '#', align: 'left', primary: true },
  { id: 'paymentDate', label: 'Payment Date', align: 'left', primary: true },
  {
    id: 'amount',
    label: 'Settling Amount',
    align: 'right',
    primary: true,
    minWidth: 300,
  },
  { id: 'method', label: 'Settling Method', align: 'left', primary: true },
];

type Props = {
  currentBarPurchaseOrder: IBarPurchaseOrderDetailedItem;
};

export default function BarPurchaseOrderDetails({ currentBarPurchaseOrder }: Props) {
  const { payments } = useAppSelector((state) => state.barPurchaseOrders);

  const dispatch = useAppDispatcher();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { user: authUser } = useAuthContext();

  const [isLoading, setIsLoading] = useState(false);

  const markAsCompleted = useCallback(async () => {
    setIsLoading(true);
    try {
      await dispatch(
        barPurchaseOrderMarkAsCompleted({
          purchaseOrderId: currentBarPurchaseOrder?._id,
          purchaseOrder: currentBarPurchaseOrder,
        })
      ).unwrap();
      setIsLoading(false);

      enqueueSnackbar('Bar purchase order marked as completed');
      navigate(paths.dashboard.bar.purchase.root());
    } catch (e) {
      if (typeof e === 'string') {
        enqueueSnackbar(e, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(e?.message, {
          variant: 'error',
        });
      }
      setIsLoading(false);
    }
  }, [dispatch, currentBarPurchaseOrder, enqueueSnackbar, navigate]);

  useEffect(() => {
    dispatch(
      getBarPurchasePayments({
        purchaseOrderId: currentBarPurchaseOrder?._id ?? '',
      })
    );
  }, [currentBarPurchaseOrder, dispatch]);

  const calculateDue = () =>
    Number(currentBarPurchaseOrder.totalAfterDiscount) - Number(currentBarPurchaseOrder.paidAmount);

  const hasRequiredPermissions =
    (currentBarPurchaseOrder.status === TRANSACTION_STATUS.DRAFT &&
      hasPermission(
        authUser,
        'any',
        AuthPermissions.CREATE_BAR_INVENTORY_TRANSACTION,
        AuthPermissions.UPDATE_BAR_INVENTORY_TRANSACTION,
        AuthPermissions.DELETE_BAR_INVENTORY_TRANSACTION,
        AuthPermissions.ADMIN_PERMISSION,
        AuthPermissions.SUPER_ADMIN_PERMISSION
      )) ||
    (currentBarPurchaseOrder.status !== TRANSACTION_STATUS.DRAFT &&
      hasPermission(
        authUser,
        'any',
        AuthPermissions.UPDATE_BAR_INVENTORY_TRANSACTION,
        AuthPermissions.DELETE_BAR_INVENTORY_TRANSACTION,
        AuthPermissions.ADMIN_PERMISSION,
        AuthPermissions.SUPER_ADMIN_PERMISSION
      ));

  return (
    <Grid container spacing={3}>
      <Grid xs={12}>
        <Card sx={{ p: 3 }}>
          <Grid container spacing={2}>
            <Grid container xs={12} width="full">
              <Grid
                mb={1}
                display="flex"
                mt={2}
                alignItems="center"
                justifyContent="space-between"
                width={1}
              >
                <Typography variant="subtitle2">Basic Information</Typography>

                {hasRequiredPermissions && (
                  <Link
                    component={RouterLink}
                    href={paths.dashboard.bar.purchase.edit(currentBarPurchaseOrder._id)}
                  >
                    <Button variant="text" startIcon={<Iconify icon="solar:pen-bold" />}>
                      Edit
                    </Button>
                  </Link>
                )}
              </Grid>

              <Grid xs={12} md={4}>
                <ListItemText
                  primary="Purchase Date"
                  secondary={fDateTime(currentBarPurchaseOrder?.purchaseOrDispatchDate)}
                  primaryTypographyProps={{ typography: 'body2' }}
                  secondaryTypographyProps={{
                    component: 'span',
                    color: 'text.disabled',
                  }}
                />
              </Grid>

              <Grid xs={12} md={4}>
                <DatePoint
                  isLink
                  primary="Supplier"
                  linkText={currentBarPurchaseOrder.supplier.supplierName}
                  secondary={
                    <Link
                      component={RouterLink}
                      href={paths.dashboard.suppliers.view(currentBarPurchaseOrder.supplier._id)}
                    >
                      {currentBarPurchaseOrder.supplier.supplierName}
                    </Link>
                  }
                />
              </Grid>

              <Grid xs={12} md={4}>
                <ListItemText
                  primary="Transaction No"
                  secondary={
                    currentBarPurchaseOrder?.status === TRANSACTION_STATUS.COMPLETED
                      ? currentBarPurchaseOrder?.transactionCode
                      : 'N/A'
                  }
                  primaryTypographyProps={{ typography: 'body2' }}
                  secondaryTypographyProps={{
                    component: 'span',
                    color: 'text.disabled',
                  }}
                />
              </Grid>

              <Divider
                sx={{
                  width: 1,
                  my: 2,
                  borderStyle: 'dashed',
                }}
              />

              <Grid mb={1} xs={12} width="full">
                <Typography variant="subtitle2">Payment Information</Typography>
              </Grid>

              <Grid xs={12} md={4}>
                <DatePoint
                  primary="Sub Total"
                  secondary={fCurrencyRupees(calculateSubTotal(currentBarPurchaseOrder) * 100)}
                />
              </Grid>

              <Grid xs={12} md={4}>
                <DatePoint
                  primary="Payment Due"
                  secondary={fCurrencyRupees(calculateDue() * 100)}
                />
              </Grid>

              <Grid xs={12} md={4}>
                {hasRequiredPermissions && calculateDue() > 0 && (
                  <Link
                    component={RouterLink}
                    href={paths.dashboard.bar.purchase.edit(currentBarPurchaseOrder._id)}
                  >
                    <Button variant="outlined" color="primary">
                      Settle Due Amount
                    </Button>
                  </Link>
                )}
              </Grid>

              <Grid xs={12} md={4}>
                <DatePoint
                  primary="Discount"
                  secondary={fCurrencyRupees(Number(currentBarPurchaseOrder?.discount ?? 0) * 100)}
                />
              </Grid>

              <Grid xs={12} md={8}>
                <ListItemText
                  primary="Payment Status"
                  secondary={
                    <Label
                      variant="soft"
                      color={
                        (currentBarPurchaseOrder?.paymentStatus ===
                          TRANSACTION_PAYMENT_STATUS.PAID &&
                          'success') ||
                        (currentBarPurchaseOrder?.paymentStatus ===
                          TRANSACTION_PAYMENT_STATUS.NOT_PAID &&
                          'error') ||
                        (currentBarPurchaseOrder?.paymentStatus ===
                          TRANSACTION_PAYMENT_STATUS.PARTIALLY_PAID &&
                          'warning') ||
                        'default'
                      }
                    >
                      {currentBarPurchaseOrder?.paymentStatus?.split('_').join(' ')}
                    </Label>
                  }
                  primaryTypographyProps={{ typography: 'body2' }}
                  secondaryTypographyProps={{
                    component: 'span',
                    color: 'text.disabled',
                  }}
                />
              </Grid>

              <Grid xs={12}>
                <DatePoint
                  primary="Total"
                  secondary={fCurrencyRupees(
                    Number(currentBarPurchaseOrder?.totalAfterDiscount ?? 0) * 100
                  )}
                />
              </Grid>

              <Grid xs={12}>
                <DatePoint
                  primary="Total After Discount"
                  secondary={fCurrencyRupees(
                    Number(currentBarPurchaseOrder?.totalAfterDiscount ?? 0) * 100
                  )}
                />
              </Grid>
            </Grid>

            <Divider
              sx={{
                width: 1,
                my: 2,
                borderStyle: 'dashed',
              }}
            />

            <Grid mb={1} xs={12} width="full">
              <Typography variant="subtitle2">Payments</Typography>
            </Grid>

            <Grid xs={12}>
              <TableContainer sx={{ position: 'relative', overflow: 'hidden', height: 'auto' }}>
                <Scrollbar>
                  <Table stickyHeader size="small" sx={{ minWidth: 960 }}>
                    <TableHeadCustom headLabel={PAYMENTS_HEADERS} />

                    <TableBody>
                      {payments.payments.payments.map(
                        (payment: IBarPurchaseOrderPayment, index: number) => (
                          <TableRow hover key={payment._id}>
                            <TableCell align="left">
                              {index + 1 < 10 ? `0${index + 1}` : index + 1}
                            </TableCell>

                            <TableCell align="left">{fDateTime(payment.paymentDate)}</TableCell>

                            <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'right' }}>
                              {fCurrencyRupees(payment.amount * 100)}
                            </TableCell>

                            <TableCell
                              sx={{
                                whiteSpace: 'nowrap',
                                textAlign: 'left',
                              }}
                            >
                              <Label
                                variant="soft"
                                color={
                                  (payment.paymentMethod === PAYMENT_METHOD.Cash && 'success') ||
                                  (payment.paymentMethod === PAYMENT_METHOD.Cheque && 'warning') ||
                                  (payment.paymentMethod === PAYMENT_METHOD.Card && 'error') ||
                                  'default'
                                }
                              >
                                {paymentMethodStringToFormattedString(payment.paymentMethod)}
                              </Label>
                            </TableCell>
                          </TableRow>
                        )
                      )}

                      {payments.status !== 'failure' && payments.payments.count === 0 && (
                        <TableNoData
                          message="No payments found"
                          notFound={payments.payments.count === 0}
                        />
                      )}
                    </TableBody>
                  </Table>
                </Scrollbar>
              </TableContainer>
            </Grid>

            <Divider
              sx={{
                width: 1,
                my: 2,
                borderStyle: 'dashed',
              }}
            />

            <Grid mb={1} xs={12} width="full">
              <Typography variant="subtitle2">Line Items</Typography>
            </Grid>

            <Grid xs={12}>
              <TableContainer sx={{ position: 'relative', overflow: 'hidden', height: '300px' }}>
                <Scrollbar>
                  <Table stickyHeader size="small" sx={{ minWidth: 960 }}>
                    <TableHeadCustom headLabel={LINE_ITEM_HEADERS} />

                    <TableBody>
                      {currentBarPurchaseOrder?.items.map((item, index) => (
                        <TableRow hover key={item._id}>
                          <TableCell align="left">
                            {index + 1 < 10 ? `0${index + 1}` : index + 1}
                          </TableCell>

                          <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                            {item.inventoryItem.itemName}
                          </TableCell>

                          <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                            {item.quantity} {item.unit.abbreviation}
                          </TableCell>

                          <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'right' }}>
                            {fCurrencyRupees(item.unitPrice * 100)}
                          </TableCell>

                          <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'right' }}>
                            {fCurrencyRupees(item.discount * 100)}
                          </TableCell>

                          <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'right' }}>
                            {fCurrencyRupees(item.totalAfterDiscount * 100)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Scrollbar>
              </TableContainer>
            </Grid>
          </Grid>

          <Stack direction="row" justifyContent="flex-end" gap={2}>
            <Link component={RouterLink} href={paths.dashboard.bar.purchase.root()}>
              <Button variant="outlined" color="inherit">
                Cancel
              </Button>
            </Link>

            {currentBarPurchaseOrder.status === TRANSACTION_STATUS.DRAFT && (
              <LoadingButton
                type="button"
                variant="contained"
                loading={isLoading}
                onClick={markAsCompleted}
              >
                Mark as completed
              </LoadingButton>
            )}
          </Stack>
        </Card>
      </Grid>
    </Grid>
  );
}

function calculateSubTotal(currentBarPurchaseItem: IBarPurchaseOrderDetailedItem): number {
  let subTotal = 0;

  currentBarPurchaseItem.items.forEach((item) => {
    subTotal += item.totalAfterDiscount;
  });

  return subTotal;
}

import { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import {
  Autocomplete,
  Box,
  FormHelperText,
  IconButton,
  InputAdornment,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import ItemUnitConverter from 'src/utils/units';
import { TRANSACTION_DISPATCH_TYPES } from 'src/utils/common-types';

import { UNIT_ID } from 'src/config-global';
import { useAppSelector } from 'src/redux/store';
import { TCreateKitchenDispatchOrder } from 'src/redux/slices/kitchen-dispatch-order';

import Iconify from 'src/components/iconify';
import { RHFTextField } from 'src/components/hook-form';

import { IUnitListItem } from 'src/types/unit';
import { IKitchenListItem } from 'src/types/kitchen';
import { IKitchenDispatchOrderDetailedItem } from 'src/types/kitchen-dispatches';

interface IKitchenDispatchNewItemRow {
  index: number;
  remove: (index: number) => void;
  isLast: boolean;
  isCompleted: boolean;
  dispatchOrder: IKitchenDispatchOrderDetailedItem | undefined;
  transactionType: TRANSACTION_DISPATCH_TYPES;
}

export default function KitchenDispatchNewItemRow({
  index,
  remove,
  isLast,
  isCompleted,
  dispatchOrder,
  transactionType,
}: IKitchenDispatchNewItemRow) {
  const isReturnOrder = transactionType === TRANSACTION_DISPATCH_TYPES.RETURN;
  const isRestockOrder = transactionType === TRANSACTION_DISPATCH_TYPES.RESTOCK;

  const { inventory } = useAppSelector((state) => state.inventory);

  const { units } = useAppSelector((state) => state.units);

  const [unitFamily, setUnitFamily] = useState<string | null>(null);

  const [localInventory, setLocalInventory] = useState<IKitchenListItem[]>([]);

  const [selectedItem, setSelectedItem] = useState<IKitchenListItem | null>(null);

  const {
    control,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
  } = useFormContext<TCreateKitchenDispatchOrder>();

  const defaultHelperText: string = errors.items?.[index] ? ' ' : '';

  const adjustingQuantity = dispatchOrder && isCompleted ? dispatchOrder.items[index].quantity : 0;

  const watchItems = useWatch({
    control,
    name: 'items',
  });

  const onUnitChange = useCallback(
    (unitID: string) => {
      try {
        if (unitID !== UNIT_ID) {
          const unit: IUnitListItem | undefined = units.units.find(
            (item: IUnitListItem) => item._id === unitID
          );

          if (unit && selectedItem) {
            const newQuantity = ItemUnitConverter.makeConvert(
              selectedItem.quantity,
              ItemUnitConverter.valueToUnit(selectedItem.unit.abbreviation),
              ItemUnitConverter.valueToUnit(unit.abbreviation)
            );

            setSelectedItem({
              ...selectedItem,
              quantity: newQuantity,
              unit,
            });

            handleQuantityChange(0);
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [units, selectedItem]
  );

  const handleQuantityChange = (newQuantity: number) => {
    if (isRestockOrder) {
      setValue(`items.${index}.quantity`, newQuantity);
      clearErrors(`items.${index}.quantity`);
      return;
    }

    if (selectedItem && newQuantity > selectedItem.quantity + adjustingQuantity) {
      setError(`items.${index}.quantity`, {
        message:
          selectedItem.quantity > 0
            ? `Only ${selectedItem.quantity + adjustingQuantity} ${
                selectedItem.unit.abbreviation
              } left`
            : 'Out of stock',
        type: 'max',
      });
      setValue(`items.${index}.quantity`, newQuantity);
    } else {
      setValue(`items.${index}.quantity`, newQuantity);
      clearErrors(`items.${index}.quantity`);
    }
  };

  const setValues = (newValue: IKitchenListItem | null) => {
    setSelectedItem(newValue);

    setValue(`items.${index}.unit`, newValue?.unit._id ?? '');
    clearErrors(`items.${index}.unit`);
    setUnitFamily(newValue?.unit.category ?? null);
    //
  };

  useEffect(() => {
    if (index === 0) {
      setLocalInventory(inventory.items);
    } else {
      const existing = watchItems.map((item) => item.inventoryItem);

      const unique = inventory.items.filter(
        (item: IKitchenListItem) => !existing.includes(item._id)
      );

      setLocalInventory(unique);
    }

    const foundItem = inventory.items.find(
      (item: IKitchenListItem) => item._id === watchItems[index].inventoryItem
    );

    if (foundItem) {
      setValues(foundItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventory]);

  return (
    <TableRow>
      <TableCell>
        {index + 1 < 10 ? `0${index + 1}` : index + 1}

        <FormHelperText>{defaultHelperText}</FormHelperText>
      </TableCell>

      <TableCell>
        <Controller
          name={`items.${index}.inventoryItem`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              {...field}
              fullWidth
              size="small"
              disabled={isCompleted}
              options={localInventory}
              getOptionLabel={(option) => (typeof option === 'string' ? option : option.itemName)}
              autoComplete
              isOptionEqualToValue={(option, value) => option._id === value._id}
              filterSelectedOptions
              value={selectedItem}
              onChange={(event: any, newValue: IKitchenListItem | null) => {
                setValues(newValue);
                setValue(`items.${index}.quantity`, 0);
                field.onChange(newValue?._id);
              }}
              renderInput={(params) => (
                <TextField
                  error={!!error}
                  required
                  label="Kitchen item"
                  placeholder="Search for items..."
                  helperText={error ? error.message : defaultHelperText}
                  {...params}
                />
              )}
              renderOption={(props, option) => (
                <li key={option._id} {...props}>
                  <Typography
                    variant="body2"
                    sx={{ color: 'text.secondary', textTransform: 'capitalize' }}
                  >
                    {option.itemName}
                  </Typography>
                </li>
              )}
            />
          )}
        />
      </TableCell>

      <TableCell align="left" sx={{ maxWidth: 150 }}>
        <RHFTextField
          required
          type="number"
          placeholder="Quantity"
          size="small"
          disabled={!selectedItem}
          name={`items.${index}.quantity`}
          onChange={(e) => handleQuantityChange(Number(e.target.value))}
          InputProps={{
            endAdornment: errors?.items?.[index]?.quantity ? (
              <InputAdornment position="end">
                <Tooltip title={errors?.items?.[index]?.quantity?.message || 'Invalid quantity'}>
                  <Iconify icon="eva:info-outline" color="error.main" />
                </Tooltip>
              </InputAdornment>
            ) : (
              <InputAdornment position="end">
                {selectedItem && !isRestockOrder && (
                  <Box
                    sx={{
                      typography: 'subtitle2',
                      color: 'text.disabled',
                    }}
                  >
                    {`/ ${selectedItem.quantity + adjustingQuantity} ${
                      selectedItem.unit.abbreviation
                    }`}
                  </Box>
                )}
              </InputAdornment>
            ),
          }}
          helperText={defaultHelperText}
          showError={false}
          sx={{ maxWidth: 200 }}
        />
      </TableCell>

      <TableCell align="left">
        <Controller
          name={`items.${index}.unit`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              select
              fullWidth
              placeholder="Unit"
              SelectProps={{
                sx: { textTransform: 'lowercase' },
              }}
              error={!!error}
              helperText={defaultHelperText}
              onChange={(e) => {
                field.onChange(e.target.value);

                onUnitChange(e.target.value);
              }}
              size="small"
              inputProps={{
                autoComplete: 'off',
              }}
            >
              {units.units
                .filter((unit: IUnitListItem) => (unitFamily ? unit.category === unitFamily : true))
                .map((unit: IUnitListItem) => (
                  <MenuItem key={unit._id} value={unit._id} sx={{ textTransform: 'unset' }}>
                    {unit.abbreviation}
                  </MenuItem>
                ))}
            </TextField>
          )}
        />
      </TableCell>

      {isReturnOrder && (
        <>
          <TableCell align="right">
            <RHFTextField
              fullWidth
              size="small"
              required
              type="number"
              name={`items.${index}.unitPrice`}
              placeholder="0.00"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box
                      sx={{
                        typography: 'subtitle2',
                        color: 'text.disabled',
                      }}
                    >
                      Rs.
                    </Box>
                  </InputAdornment>
                ),
                endAdornment: errors?.items?.[index]?.unitPrice ? (
                  <InputAdornment position="end">
                    <Tooltip
                      title={errors?.items?.[index]?.unitPrice?.message || 'Invalid Unit Price'}
                    >
                      <Iconify icon="eva:info-outline" color="error.main" />
                    </Tooltip>
                  </InputAdornment>
                ) : null,
              }}
              helperText={defaultHelperText}
              showError={false}
            />
          </TableCell>

          <TableCell align="right">
            <RHFTextField
              fullWidth
              size="small"
              type="number"
              name={`items.${index}.discount`}
              placeholder="0.00"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box
                      sx={{
                        typography: 'subtitle2',
                        color: 'text.disabled',
                      }}
                    >
                      Rs.
                    </Box>
                  </InputAdornment>
                ),
                endAdornment: errors?.items?.[index]?.discount ? (
                  <InputAdornment position="end">
                    <Tooltip
                      title={errors?.items?.[index]?.discount?.message || 'Invalid Discount'}
                    >
                      <Iconify icon="eva:info-outline" color="error.main" />
                    </Tooltip>
                  </InputAdornment>
                ) : null,
              }}
              helperText={defaultHelperText}
              showError={false}
            />
          </TableCell>

          <TableCell align="right">
            <RHFTextField
              fullWidth
              size="small"
              type="number"
              name={`items.${index}.totalAfterDiscount`}
              placeholder="0.00"
              onChange={(event) => {
                setValue(
                  `items.${index}.unitPrice`,
                  Number(event.target.value) / watchItems[index].quantity
                );
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box
                      sx={{
                        typography: 'subtitle2',
                        color: 'text.disabled',
                      }}
                    >
                      Rs.
                    </Box>
                  </InputAdornment>
                ),
                endAdornment: errors?.items?.[index]?.totalAfterDiscount ? (
                  <InputAdornment position="end">
                    <Tooltip title={errors?.items?.[index]?.totalAfterDiscount?.message || ''}>
                      <Iconify icon="eva:info-outline" color="error.main" />
                    </Tooltip>
                  </InputAdornment>
                ) : null,
              }}
              helperText={defaultHelperText}
              showError={false}
            />
          </TableCell>
        </>
      )}

      <TableCell align="right">
        <Tooltip title={isLast ? 'Last item cannot be deleted' : 'Delete'}>
          <span>
            <IconButton disabled={isLast} color="error" onClick={() => remove(index)}>
              <Iconify icon="solar:trash-bin-trash-bold" />
            </IconButton>
          </span>
        </Tooltip>

        <FormHelperText>{defaultHelperText}</FormHelperText>
      </TableCell>
    </TableRow>
  );
}

import { isEqual } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import { alpha, Tab, Tabs } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';

import { useResponsive } from 'src/hooks/use-responsive';

import { TAB_QUERY } from 'src/utils/common-types';
import { authLocations, hasPermission } from 'src/utils/has-permissions';
import logToucodeEvent, { APP_EVENTS } from 'src/utils/log-google-events';

import { useAuthContext } from 'src/auth/hooks';
import { AuthPermissions } from 'src/auth/permissions';
import { useAppDispatcher, useAppSelector } from 'src/redux/store';
import {
  deleteSupplier,
  disableSupplier,
  enableSupplier,
  getPaginatedSuppliers,
  removeSupplier,
} from 'src/redux/slices/suppliers';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import TableErrorData from 'src/components/table/table-error-data';
import {
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSkeleton,
  useTable,
} from 'src/components/table';

import {
  DocumentStatus,
  getDocumentAPIStatus,
  getPaymentDueAPIStatus,
  PaymentDueStatus,
} from 'src/types/common';
import {
  ISupplierListItem,
  ISupplierTableFilters,
  ISupplierTableFilterValue,
  ISupplierTableSort,
  SUPPLIER_DEBT_STATUS_OPTIONS,
} from 'src/types/supplier';

import SupplierTableRow from '../supplier-table-row';
import SupplierTableToolbar from '../supplier-table-toolbar';

const STATUS_OPTIONS = [{ value: 'all', label: 'All' }, ...SUPPLIER_DEBT_STATUS_OPTIONS];

const TABLE_HEAD = [
  { id: 'supplierName', label: 'Name', align: 'left', primary: true },
  { id: 'contactPerson', label: 'Contact Person', align: 'left', primary: true },
  { id: 'phoneNumber', label: 'Phone Number', align: 'left', primary: true },
  { id: 'email', label: 'Email Address', align: 'left', primary: true },
  { id: 'updatedAt', label: 'Updated', align: 'left', primary: true },
  { id: '', primary: true },
];

const defaultFilters: ISupplierTableFilters = {
  generalSearch: '',
  status: DocumentStatus.ACTIVE,
};

const defaultSortBy: ISupplierTableSort = {
  sortBy: 'updatedAt',
  order: 'desc',
};

const disablingSupplierInitialState: {
  state: boolean;
  index: number | null;
  message: string;
  supplier: ISupplierListItem | null;
} = {
  state: false,
  index: null,
  message: '',
  supplier: null,
};

export default function SupplierListView() {
  const [searchParams, setSearchParams] = useSearchParams();

  const currentTab = searchParams.get(TAB_QUERY);

  const { status, suppliers, error } = useAppSelector((state) => state.suppliers);

  const { user: authUser } = useAuthContext();

  const table = useTable({
    defaultRowsPerPage: 25,
  });

  const smDown = useResponsive('down', 'sm');

  const dispatch = useAppDispatcher();

  const { page, rowsPerPage } = table;

  const settings = useSettingsContext();

  const denseHeight = table.dense ? 60 : 80;

  const router = useRouter();

  const [disablingSupplier, setDisablingSupplier] = useState(disablingSupplierInitialState);

  const [filters, setFilters] = useState(defaultFilters);

  const [sort, setSort] = useState(defaultSortBy);

  const [statusTab, setStatusTab] = useState<string>(currentTab || PaymentDueStatus.ALL);

  const canReset = !isEqual(defaultFilters, filters);

  const handleFilters = useCallback(
    (name: string, value: ISupplierTableFilterValue) => {
      table.onResetPage();

      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleEditRow = useCallback(
    (id: string) => {
      router.push(paths.dashboard.suppliers.edit(id));
    },
    [router]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const handleFilterStatus = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setStatusTab(newValue);

      setSearchParams({ [TAB_QUERY]: newValue });
    },
    [setSearchParams]
  );

  const fetchPaginatedSuppliers = useCallback(() => {
    const authorizedLocations = authLocations(authUser);

    if (authorizedLocations.length > 0) {
      dispatch(
        getPaginatedSuppliers({
          page,
          limit: rowsPerPage,
          generalSearch: filters.generalSearch,
          sort: sort.order,
          sortBy: sort.sortBy,
          locations: authorizedLocations,
          locationsSeparator: '|',
          status: getDocumentAPIStatus(filters.status),
          paymentStatus: getPaymentDueAPIStatus(statusTab),
        })
      );
    }
  }, [dispatch, page, rowsPerPage, sort.order, sort.sortBy, filters, authUser, statusTab]);

  const handleDeleteRow = useCallback(
    async (supplier: ISupplierListItem, index: number) => {
      dispatch(removeSupplier({ supplierId: supplier._id }));

      try {
        await dispatch(deleteSupplier({ supplier, index })).unwrap();

        fetchPaginatedSuppliers();
        enqueueSnackbar('Supplier deleted');
      } catch (e) {
        if ('name' in e && e.name === 'AxiosError') {
          setDisablingSupplier({
            state: true,
            message: e.message,
            supplier,
            index,
          });
        } else if (typeof e === 'string') {
          enqueueSnackbar(e, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(e?.message, {
            variant: 'error',
          });
        }
      }
    },
    [dispatch, fetchPaginatedSuppliers]
  );

  const handleDisableRow = useCallback(
    async (supplier: ISupplierListItem, index: number) => {
      dispatch(removeSupplier({ supplierId: supplier._id }));

      try {
        await dispatch(disableSupplier({ supplier, index })).unwrap();

        fetchPaginatedSuppliers();
        enqueueSnackbar('Supplier disabled');
      } catch (e) {
        if (typeof e === 'string') {
          enqueueSnackbar(e, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(e?.message, {
            variant: 'error',
          });
        }
      }
    },
    [dispatch, fetchPaginatedSuppliers]
  );

  const handleReActivateRow = useCallback(
    async (supplier: ISupplierListItem, index: number) => {
      try {
        await dispatch(enableSupplier({ supplier })).unwrap();

        setFilters(defaultFilters);

        enqueueSnackbar('Supplier re-activated');
      } catch (e) {
        if (typeof e === 'string') {
          enqueueSnackbar(e, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(e?.message, {
            variant: 'error',
          });
        }
      }
    },
    [dispatch]
  );

  useEffect(() => {
    fetchPaginatedSuppliers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPaginatedSuppliers]);

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Suppliers"
          links={[]}
          action={
            <>
              {hasPermission(
                authUser,
                'any',
                AuthPermissions.CREATE_SUPPLIER,
                AuthPermissions.ADMIN_PERMISSION,
                AuthPermissions.SUPER_ADMIN_PERMISSION
              ) && (
                <Button
                  component={RouterLink}
                  href={paths.dashboard.suppliers.new}
                  variant="contained"
                  startIcon={<Iconify icon="mingcute:add-line" />}
                >
                  {smDown ? 'New' : ' New Supplier'}
                </Button>
              )}
            </>
          }
          sx={{
            mb: { xs: 3 },
          }}
        />

        <Card>
          <Tabs
            value={statusTab}
            onChange={handleFilterStatus}
            sx={{
              px: 2.5,
              boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
            }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab key={tab.value} iconPosition="end" value={tab.value} label={tab.label} />
            ))}
          </Tabs>

          <SupplierTableToolbar
            filters={filters}
            onFilters={handleFilters}
            handleResetFilters={handleResetFilters}
            canReset={canReset}
          />

          <TableContainer sx={{ position: 'relative', overflow: 'hidden', height: '450px' }}>
            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                <TableHeadCustom
                  order={sort.order}
                  orderBy={sort.sortBy}
                  headLabel={TABLE_HEAD.filter(({ primary }) => {
                    if (settings.themeLayout === 'vertical') {
                      return primary;
                    }
                    return true;
                  })}
                  rowCount={suppliers.suppliers.length}
                  numSelected={table.selected.length}
                  onSort={(id) => {
                    if (id === sort.sortBy) {
                      setSort((prev) => ({
                        ...prev,
                        order: prev.order === 'asc' ? 'desc' : 'asc',
                      }));
                    } else {
                      setSort({
                        sortBy: id,
                        order: 'asc',
                      });
                    }
                  }}
                />

                <TableBody>
                  {status === 'loading' ? (
                    [...Array(table.rowsPerPage)].map((i, index) => (
                      <TableSkeleton key={index} sx={{ height: denseHeight }} />
                    ))
                  ) : (
                    <>
                      {suppliers?.suppliers.map((row: ISupplierListItem, index: number) => (
                        <SupplierTableRow
                          key={row._id}
                          row={row}
                          selected={table.selected.includes(row._id)}
                          onEditRow={() => handleEditRow(row._id)}
                          onDeleteRow={() => handleDeleteRow(row, index)}
                          onReActivateRow={() => handleReActivateRow(row, index)}
                        />
                      ))}
                    </>
                  )}

                  {status !== 'failure' && suppliers.count === 0 && (
                    <TableNoData message="No suppliers found" notFound={suppliers.count === 0} />
                  )}

                  {status === 'failure' && <TableErrorData notFound error={error} />}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={suppliers.count}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={(value) => {
              table.onChangeDense(value);

              logToucodeEvent(APP_EVENTS.TABLE_COMPACT_MODE_TOGGLE, {
                compact: value,
                table: 'suppliers_table',
              });
            }}
          />
        </Card>
      </Container>
      <ConfirmDialog
        open={disablingSupplier.state}
        onClose={() => setDisablingSupplier(disablingSupplierInitialState)}
        title={`Action Required: Cannot Delete ${disablingSupplier.supplier?.supplierName}`}
        content={disablingSupplier.message}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              if (disablingSupplier.supplier !== null && disablingSupplier.index !== null) {
                handleDisableRow(disablingSupplier.supplier, disablingSupplier.index);
                setDisablingSupplier(disablingSupplierInitialState);
              }
            }}
          >
            Temporarily Delete
          </Button>
        }
      />
    </>
  );
}

import { useEffect, useState } from 'react';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import {
  alpha,
  Button,
  Link,
  ListItemText,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
} from '@mui/material';

import { RouterLink } from 'src/routes/components';

import { fDateTime } from 'src/utils/format-time';
import { hasPermission } from 'src/utils/has-permissions';
import { fCurrencyRupees } from 'src/utils/format-number';
import {
  PAYMENT_METHOD,
  paymentMethodStringToFormattedString,
  TABLE_HEAD_CELL_TYPE,
  TRANSACTION_PAYMENT_STATUS,
} from 'src/utils/common-types';

import { useAuthContext } from 'src/auth/hooks';
import { AuthPermissions } from 'src/auth/permissions';
import { useAppDispatcher, useAppSelector } from 'src/redux/store';
import { BAR_LOCATION_ID, KITCHEN_LOCATION_ID, VITE_DEFAULT_SUPPLIER } from 'src/config-global';
import {
  getItemTransactionsBySupplier,
  getPaymentsBySupplier,
  ISupplyListItem,
} from 'src/redux/slices/suppliers';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import DatePoint from 'src/components/data-point/data-point';
import TableErrorData from 'src/components/table/table-error-data';
import {
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSkeleton,
  useTable,
} from 'src/components/table';

import { ICommonItemTransactionListItem } from 'src/types/item';
import { ISupplierItem, ISupplierPaymentHistory } from 'src/types/supplier';

import { paths } from '../../routes/paths';

const SUPPLIES_LINE_ITEM_HEADERS: TABLE_HEAD_CELL_TYPE[] = [
  { id: 'itemName', label: 'Item name', align: 'left', primary: true },
  { id: 'locations', label: 'Locations', align: 'left', primary: true },
  { id: 'itemCode', label: 'Item #', align: 'left', primary: true },
];

const LINE_ITEM_HEADERS: TABLE_HEAD_CELL_TYPE[] = [
  {
    id: 'purchaseOrDispatchDate',
    label: 'Transaction Date',
    align: 'left',
    primary: true,
  },
  { id: 'paymentStatus', label: 'Payment Status', align: 'left', primary: true },
  { id: 'location', label: 'Location', align: 'left', primary: true },
  { id: 'updatedAt', label: 'Updated At', primary: true, align: 'left' },
  { id: 'transactionNo', label: 'Transaction #', align: 'left', primary: true },
];

const SUPPLIER_PAYMENTS_HEADERS: TABLE_HEAD_CELL_TYPE[] = [
  {
    id: 'paymentDate',
    label: 'Payment Date',
    align: 'left',
    primary: true,
  },
  { id: 'transactionNo', label: 'Associated Transaction', align: 'left', primary: true },
  { id: 'amount', label: 'Transaction Amount', align: 'right', primary: true },
  { id: 'type', label: 'Transaction Method', align: 'left', primary: true },
  { id: 'updatedAt', label: 'Updated At', primary: true, align: 'left' },
];

const TABS = [
  { value: 'overview', label: 'Overview' },
  { value: 'items', label: 'Items' },
  { value: 'transactions', label: 'Transactions History' },
  { value: 'paymentsHistory', label: 'Payment History' },
];

type Props = {
  currentSupplier?: ISupplierItem;
};

const defaultSortBy: { sortBy: string; order: 'asc' | 'desc' } = {
  sortBy: 'purchaseOrDispatchDate',
  order: 'desc',
};

export default function SupplierDetails({ currentSupplier }: Props) {
  const dispatch = useAppDispatcher();

  const { user: authUser } = useAuthContext();

  const {
    supplies,
    transactions: { status, transactions, error },
    payments: { payments, status: paymentsStatus, error: paymentsError },
  } = useAppSelector((state) => state.suppliers);

  const [tabValue, setTabValue] = useState('overview');

  const table = useTable();

  const { page, rowsPerPage } = table;

  const paymentsTable = useTable();

  const { page: paymentsPage, rowsPerPage: paymentsRowsPerPage } = paymentsTable;

  const [sort, setSort] = useState(defaultSortBy);

  const isDefaultSupplier = currentSupplier?._id === VITE_DEFAULT_SUPPLIER;

  useEffect(() => {
    if (tabValue === 'transactions') {
      dispatch(
        getItemTransactionsBySupplier({
          supplier: currentSupplier?._id ?? '',
          page,
          limit: rowsPerPage,
          sortBy: sort.sortBy,
          sort: sort.order,
        })
      );
    } else if (tabValue === 'paymentsHistory') {
      dispatch(
        getPaymentsBySupplier({
          supplierId: currentSupplier?._id ?? '',
          page: paymentsPage + 1,
          limit: paymentsRowsPerPage,
          // sortBy: sort.sortBy,
          // sort: sort.order,
        })
      );
    }
  }, [
    tabValue,
    currentSupplier,
    dispatch,
    page,
    rowsPerPage,
    sort,
    paymentsPage,
    paymentsRowsPerPage,
  ]);

  const calculateOverdue = (total?: number, totalPaid?: number) => {
    if (total && totalPaid && total > totalPaid) {
      return fCurrencyRupees(Number(total - totalPaid) * 100);
    }

    return fCurrencyRupees(0);
  };

  return (
    <Grid container spacing={3}>
      <Grid xs={12}>
        <Card sx={{ p: 3 }}>
          <Tabs
            value={tabValue}
            onChange={(_, value) => setTabValue(value)}
            sx={{
              px: 2.5,
              boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
            }}
          >
            {TABS.map((tab) => (
              <Tab key={tab.value} iconPosition="end" value={tab.value} label={tab.label} />
            ))}
          </Tabs>

          {tabValue === 'overview' && (
            <Grid container spacing={2} mt={2}>
              <Grid
                mb={1}
                display="flex"
                mt={2}
                alignItems="center"
                justifyContent="space-between"
                width={1}
              >
                <Typography variant="subtitle1">Basic Information</Typography>

                {hasPermission(
                  authUser,
                  'any',
                  AuthPermissions.UPDATE_SUPPLIER,
                  AuthPermissions.ADMIN_PERMISSION,
                  AuthPermissions.SUPER_ADMIN_PERMISSION
                ) &&
                  !isDefaultSupplier && (
                    <Link
                      component={RouterLink}
                      href={paths.dashboard.suppliers.edit(currentSupplier?._id ?? '')}
                    >
                      <Button variant="text" startIcon={<Iconify icon="solar:pen-bold" />}>
                        Edit
                      </Button>
                    </Link>
                  )}
              </Grid>

              <Grid xs={12} md={4}>
                <DatePoint primary="Company Name" secondary={currentSupplier?.supplierName} />
              </Grid>

              <Grid xs={12} md={4}>
                <DatePoint primary="Contact Person" secondary={currentSupplier?.contactPerson} />
              </Grid>

              <Grid xs={12} md={4}>
                <ListItemText
                  primary="Locations"
                  secondary={
                    <Stack direction="row" spacing={1}>
                      {currentSupplier?.location.map((location) => (
                        <Label
                          key={location._id}
                          variant="soft"
                          color={location._id === KITCHEN_LOCATION_ID ? 'secondary' : 'warning'}
                        >
                          <Link
                            component={RouterLink}
                            href={
                              location._id === KITCHEN_LOCATION_ID
                                ? paths.dashboard.kitchen.root()
                                : paths.dashboard.bar.root()
                            }
                          >
                            {location.name}
                          </Link>
                        </Label>
                      ))}
                    </Stack>
                  }
                  primaryTypographyProps={{ typography: 'body2' }}
                  secondaryTypographyProps={{
                    component: 'span',
                    color: 'text.disabled',
                  }}
                />
              </Grid>

              <Grid xs={12} md={4}>
                <DatePoint primary="Email Address" secondary={currentSupplier?.email} />
              </Grid>

              <Grid xs={12} md={4}>
                <DatePoint primary="Phone Number" secondary={currentSupplier?.phoneNumber} />
              </Grid>

              <Grid xs={12} md={4}>
                <DatePoint
                  primary="Secondary Phone Number"
                  secondary={currentSupplier?.secondaryPhoneNumber}
                />
              </Grid>

              {currentSupplier?._id !== VITE_DEFAULT_SUPPLIER && (
                <Grid xs={12} md={4}>
                  <DatePoint
                    primary="Total Overdue"
                    secondary={calculateOverdue(
                      currentSupplier?.totalTransactionAmount,
                      currentSupplier?.totalPaidAmount
                    )}
                  />
                </Grid>
              )}
            </Grid>
          )}

          {tabValue === 'items' && (
            <Stack>
              <Typography variant="subtitle1" sx={{ mt: 2 }}>
                {currentSupplier?.supplierName}
              </Typography>
              <Scrollbar>
                <Table stickyHeader size="small" sx={{ minWidth: 960, mt: 2 }}>
                  <TableHeadCustom headLabel={SUPPLIES_LINE_ITEM_HEADERS} />

                  <TableBody>
                    {supplies.status === 'loading' ? (
                      [...Array(10)].map((i, index) => <TableSkeleton key={index} />)
                    ) : (
                      <>
                        {supplies?.items.map((item: ISupplyListItem) => (
                          <TableRow hover key={item._id}>
                            <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                              {item.itemName}
                            </TableCell>

                            <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                              {item.location.map((location) => (
                                <Label
                                  color={
                                    location._id === KITCHEN_LOCATION_ID ? 'secondary' : 'warning'
                                  }
                                  key={location._id}
                                  mr={2}
                                >
                                  <Link
                                    component={RouterLink}
                                    href={
                                      location._id === KITCHEN_LOCATION_ID
                                        ? paths.dashboard.kitchen.root()
                                        : paths.dashboard.bar.root()
                                    }
                                  >
                                    {location.name}
                                  </Link>
                                </Label>
                              ))}
                            </TableCell>

                            <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                              {item.itemCode}
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    )}

                    {supplies.status !== 'failure' && supplies.items.length === 0 && (
                      <TableNoData
                        message="No items found"
                        notFound={supplies.items.length === 0}
                      />
                    )}

                    {supplies.status === 'failure' && (
                      <TableErrorData notFound error={supplies.error} />
                    )}
                  </TableBody>
                </Table>
              </Scrollbar>
            </Stack>
          )}

          {tabValue === 'transactions' && (
            <Stack>
              <Typography variant="subtitle1" sx={{ mt: 2 }}>
                {currentSupplier?.supplierName}
              </Typography>
              <TableContainer
                sx={{ position: 'relative', overflow: 'hidden', height: '400px', mt: 2 }}
              >
                <Scrollbar>
                  <Table stickyHeader size="medium" sx={{ minWidth: 960 }}>
                    <TableHeadCustom
                      headLabel={LINE_ITEM_HEADERS}
                      order={sort.order}
                      orderBy={sort.sortBy}
                      rowCount={transactions.transactions.length}
                      onSort={(id) => {
                        if (id === sort.sortBy) {
                          setSort((prev) => ({
                            ...prev,
                            order: prev.order === 'asc' ? 'desc' : 'asc',
                          }));
                        } else {
                          setSort({
                            sortBy: id,
                            order: 'asc',
                          });
                        }
                      }}
                    />

                    <TableBody>
                      {status === 'loading' ? (
                        [...Array(10)].map((i, index) => <TableSkeleton key={index} />)
                      ) : (
                        <>
                          {transactions?.transactions.map(
                            (row: ICommonItemTransactionListItem, index: number) => (
                              <TableRow hover key={row._id}>
                                <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                                  {fDateTime(row.purchaseOrDispatchDate)}
                                </TableCell>

                                <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                                  <Label
                                    variant="soft"
                                    color={
                                      (row.paymentStatus === TRANSACTION_PAYMENT_STATUS.PAID &&
                                        'success') ||
                                      (row.paymentStatus ===
                                        TRANSACTION_PAYMENT_STATUS.PARTIALLY_PAID &&
                                        'warning') ||
                                      (row.paymentStatus === TRANSACTION_PAYMENT_STATUS.NOT_PAID &&
                                        'error') ||
                                      'default'
                                    }
                                  >
                                    {row.paymentStatus.split('_').join(' ')}
                                  </Label>
                                </TableCell>

                                <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                                  <Label
                                    color={
                                      row.location._id === KITCHEN_LOCATION_ID
                                        ? 'secondary'
                                        : 'warning'
                                    }
                                    mr={2}
                                  >
                                    <Link
                                      component={RouterLink}
                                      href={
                                        row.location._id === KITCHEN_LOCATION_ID
                                          ? paths.dashboard.kitchen.root()
                                          : paths.dashboard.bar.root()
                                      }
                                    >
                                      {row.location.name}
                                    </Link>
                                  </Label>
                                </TableCell>

                                <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                                  {fDateTime(row.updatedAt)}
                                </TableCell>

                                <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                                  <Link
                                    component={RouterLink}
                                    href={
                                      row.location._id === BAR_LOCATION_ID
                                        ? paths.dashboard.bar.purchase.view(row._id)
                                        : paths.dashboard.kitchen.purchase.view(row._id)
                                    }
                                  >
                                    {row.transactionCode}
                                  </Link>
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </>
                      )}

                      {status !== 'failure' && transactions.count === 0 && (
                        <TableNoData
                          message="No transactions found"
                          notFound={transactions.count === 0}
                        />
                      )}

                      {status === 'failure' && <TableErrorData notFound error={error} />}
                    </TableBody>
                  </Table>
                </Scrollbar>
              </TableContainer>
              <TablePaginationCustom
                count={transactions.count}
                page={table.page}
                rowsPerPage={table.rowsPerPage}
                onPageChange={table.onChangePage}
                onRowsPerPageChange={table.onChangeRowsPerPage}
                dense={table.dense}
                onChangeDense={table.onChangeDense}
              />
            </Stack>
          )}

          {tabValue === 'paymentsHistory' && (
            <Stack>
              <Typography variant="subtitle1" sx={{ mt: 2 }}>
                {currentSupplier?.supplierName}
              </Typography>
              <TableContainer
                sx={{ position: 'relative', overflow: 'hidden', height: '400px', mt: 2 }}
              >
                <Scrollbar>
                  <Table stickyHeader size="medium" sx={{ minWidth: 960 }}>
                    <TableHeadCustom
                      headLabel={SUPPLIER_PAYMENTS_HEADERS}
                      order={sort.order}
                      orderBy={sort.sortBy}
                      rowCount={payments.payments.length}
                      onSort={(id) => {
                        if (id === sort.sortBy) {
                          setSort((prev) => ({
                            ...prev,
                            order: prev.order === 'asc' ? 'desc' : 'asc',
                          }));
                        } else {
                          setSort({
                            sortBy: id,
                            order: 'asc',
                          });
                        }
                      }}
                    />

                    <TableBody>
                      {paymentsStatus === 'loading' ? (
                        [...Array(10)].map((i, index) => <TableSkeleton key={index} />)
                      ) : (
                        <>
                          {payments?.payments.map((row: ISupplierPaymentHistory, index: number) => (
                            <TableRow hover key={row._id}>
                              <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                                {fDateTime(row.paymentDate)}
                              </TableCell>

                              <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                                <Link
                                  component={RouterLink}
                                  href={
                                    row.transactionDetails.location === BAR_LOCATION_ID
                                      ? paths.dashboard.bar.purchase.view(
                                          row.transactionDetails._id
                                        )
                                      : paths.dashboard.kitchen.purchase.view(
                                          row.transactionDetails._id
                                        )
                                  }
                                >
                                  {row.transactionDetails.transactionCode}
                                </Link>
                              </TableCell>

                              <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'right' }}>
                                {fCurrencyRupees(row.amount * 100)}
                              </TableCell>

                              <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                                <Label
                                  variant="soft"
                                  color={
                                    (row.paymentMethod === PAYMENT_METHOD.Cash && 'success') ||
                                    (row.paymentMethod === PAYMENT_METHOD.Cheque && 'warning') ||
                                    (row.paymentMethod === PAYMENT_METHOD.Card && 'error') ||
                                    'default'
                                  }
                                >
                                  {paymentMethodStringToFormattedString(row.paymentMethod)}
                                </Label>
                              </TableCell>

                              <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                                {fDateTime(row.updatedAt)}
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      )}

                      {paymentsStatus !== 'failure' && payments.count === 0 && (
                        <TableNoData message="No payments found" notFound={payments.count === 0} />
                      )}

                      {paymentsStatus === 'failure' && (
                        <TableErrorData notFound error={paymentsError} />
                      )}
                    </TableBody>
                  </Table>
                </Scrollbar>
              </TableContainer>
              <TablePaginationCustom
                count={payments.count}
                page={paymentsTable.page}
                rowsPerPage={paymentsTable.rowsPerPage}
                onPageChange={paymentsTable.onChangePage}
                onRowsPerPageChange={paymentsTable.onChangeRowsPerPage}
                dense={paymentsTable.dense}
                onChangeDense={paymentsTable.onChangeDense}
              />
            </Stack>
          )}

          <Stack direction="row" justifyContent="flex-end" gap={2} mt={2}>
            <Link
              component={RouterLink}
              href={paths.dashboard.suppliers.root}
              sx={{
                width: {
                  xs: 1,
                  sm: 'auto',
                },
              }}
            >
              <Button
                variant="outlined"
                color="inherit"
                sx={{
                  width: 1,
                }}
              >
                Cancel
              </Button>
            </Link>
          </Stack>
        </Card>
      </Grid>
    </Grid>
  );
}

import { useState } from 'react';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { alpha, Button, Link, Tab, Tabs } from '@mui/material';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { hasPermission } from 'src/utils/has-permissions';
import { fCurrencyRupees } from 'src/utils/format-number';

import { useAuthContext } from 'src/auth/hooks';
import { AuthPermissions } from 'src/auth/permissions';

import Iconify from 'src/components/iconify';
import DatePoint from 'src/components/data-point/data-point';

import { IRestaurantItem } from 'src/types/restaurant';

const TABS = [{ value: 'overview', label: 'Overview' }];

type Props = {
  currentRestaurantItem?: IRestaurantItem;
};

export default function RestaurantItemDetails({ currentRestaurantItem }: Props) {
  const [tabValue, setTabValue] = useState('overview');

  const { user: authUser } = useAuthContext();

  return (
    <Grid container spacing={3}>
      <Grid xs={12}>
        <Card sx={{ p: 3 }}>
          <Tabs
            value={tabValue}
            onChange={(_, value) => setTabValue(value)}
            sx={{
              px: 2.5,
              boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
            }}
          >
            {TABS.map((tab) => (
              <Tab key={tab.value} iconPosition="end" value={tab.value} label={tab.label} />
            ))}
          </Tabs>

          {tabValue === 'overview' && (
            <Grid container spacing={2} mt={2}>
              <Grid
                mb={1}
                display="flex"
                mt={2}
                alignItems="center"
                justifyContent="space-between"
                width={1}
              >
                <Typography variant="subtitle1">Basic Information</Typography>

                {hasPermission(
                  authUser,
                  'any',
                  AuthPermissions.UPDATE_RESTAURANT_ITEM,
                  AuthPermissions.ADMIN_PERMISSION,
                  AuthPermissions.SUPER_ADMIN_PERMISSION
                ) && (
                  <Link
                    component={RouterLink}
                    href={paths.dashboard.restaurant.edit(currentRestaurantItem?._id ?? '')}
                  >
                    <Button variant="text" startIcon={<Iconify icon="solar:pen-bold" />}>
                      Edit
                    </Button>
                  </Link>
                )}
              </Grid>

              <Grid xs={12} md={3}>
                <DatePoint
                  primary="Item Name"
                  secondary={`${currentRestaurantItem?.itemName} (${currentRestaurantItem?.itemCode})`}
                />
              </Grid>

              <Grid xs={12} md={3}>
                <DatePoint
                  primary="Price"
                  secondary={fCurrencyRupees(Number(currentRestaurantItem?.price ?? 0) * 100)}
                />
              </Grid>

              <Grid xs={12} md={3}>
                <DatePoint
                  isLink
                  primary="Last Updated"
                  linkText={`${currentRestaurantItem?.updatedBy.firstName} ${currentRestaurantItem?.updatedBy.lastName}`}
                  secondary={
                    <Link
                      component={RouterLink}
                      href={paths.dashboard.user.view(currentRestaurantItem?.updatedBy._id ?? '')}
                    >
                      {`${currentRestaurantItem?.updatedBy.firstName} ${currentRestaurantItem?.updatedBy.lastName}`}
                    </Link>
                  }
                />
              </Grid>
            </Grid>
          )}

          <Stack direction="row" justifyContent="flex-end" gap={2} mt={2}>
            <Link
              component={RouterLink}
              href={paths.dashboard.restaurant.root()}
              sx={{
                width: {
                  xs: 1,
                  sm: 'auto',
                },
              }}
            >
              <Button
                variant="outlined"
                color="inherit"
                sx={{
                  width: 1,
                }}
              >
                Cancel
              </Button>
            </Link>
          </Stack>
        </Card>
      </Grid>
    </Grid>
  );
}

import {
  InventoryItemGroup,
  TRANSACTION_PAYMENT_STATUS,
  TRANSACTION_TYPE,
} from 'src/utils/common-types';

import { IListUser } from 'src/redux/slices/users';

import { IUnitListItem } from './unit';
import { ILocationListItem } from './location';
import { ISupplierListItem } from './supplier';

export function isMinMaxRange(value: IBarTableFilterValue): value is { min: number; max: number } {
  return typeof value === 'object' && value !== null && 'min' in value && 'max' in value;
}

export type IBarTableFilterValue = string | string[] | { min: number; max: number } | boolean;

export type IBarTableFilters = {
  generalSearch: string;
  minPrice: number | null;
  maxPrice: number | null;
  alertsEnabled: 'all' | 'enabled' | 'disabled';
  units: string[];
  status: 'active' | 'disabled';
};

export type IBarTableSort = {
  sortBy: string;
  order: 'asc' | 'desc';
};

export enum BarAvailabilityStatus {
  ALL = 'all',
  IN_STOCK = 'in-stock',
  OUT_OF_STOCK = 'out-of-stock',
  RUNNING_LOW = 'low-stock',
}

export const BAR_AVAILABILITY_STATUS_OPTIONS = [
  { value: BarAvailabilityStatus.IN_STOCK, label: 'In Stock' },
  { value: BarAvailabilityStatus.RUNNING_LOW, label: 'Low Stock' },
  { value: BarAvailabilityStatus.OUT_OF_STOCK, label: 'Out of Stock' },
];

export enum BarAlertStatus {
  ALL = 'all',
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}

export const BAR_ALERT_OPTIONS = [
  { value: BarAlertStatus.ENABLED, label: 'Enabled' },
  { value: BarAlertStatus.DISABLED, label: 'Disabled' },
];

export interface IBarListItem {
  _id: string;
  itemName: string;
  itemCode: string;
  quantity: number;
  price: number;
  unit: IUnitListItem;
  reorderThreshold: number;
  alertEnabled: boolean;
  updatedAt: string;
  availability: BarAvailabilityStatus;
  status: 'active' | 'disabled';
}

export interface IBarItem {
  _id: string;
  itemName: string;
  itemCode: string;
  price: number;
  quantity: number;
  unit: IUnitListItem;
  location: ILocationListItem[];
  suppliers: ISupplierListItem[];
  reorderThreshold: number;
  alertEnabled: boolean;
  createdAt: string;
  updatedAt: string;
  updatedBy: IListUser;
  availability: BarAvailabilityStatus;
  group: InventoryItemGroup;
  volume: number;
}

export interface IBarHistory {
  quantity: number;
  transactionType: TRANSACTION_TYPE;
  updatedAt: string;
  transactionDetails: IBarHistoryDetails;
  unit: IUnitListItem;
}

export interface IBarHistoryDetails {
  _id: string;
  transactionCode: string;
  paymentStatus: TRANSACTION_PAYMENT_STATUS;
  purchaseOrDispatchDate: string;
}

import { isEqual, lt, some } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import Tabs from '@mui/material/Tabs';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import { alpha } from '@mui/material/styles';
import { Stack, Tooltip } from '@mui/material';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';

import { useResponsive } from 'src/hooks/use-responsive';

import axiosInstance from 'src/utils/axios';
import { TAB_QUERY } from 'src/utils/common-types';
import { hasPermission } from 'src/utils/has-permissions';
import logToucodeEvent, { APP_EVENTS } from 'src/utils/log-google-events';

import { useAuthContext } from 'src/auth/hooks';
import { BAR_LOCATION_ID } from 'src/config-global';
import { AuthPermissions } from 'src/auth/permissions';
import { useAppDispatcher, useAppSelector } from 'src/redux/store';
import {
  deleteBarItem,
  disableBarItem,
  getBarItems,
  reActivateBarItem,
  removeBarItem,
} from 'src/redux/slices/bar';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import TableErrorData from 'src/components/table/table-error-data';
import {
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSkeleton,
  useTable,
} from 'src/components/table';

import { DocumentStatus, getDocumentAPIStatus } from 'src/types/common';
import {
  BAR_AVAILABILITY_STATUS_OPTIONS,
  BarAlertStatus,
  BarAvailabilityStatus,
  IBarListItem,
  IBarTableFilters,
  IBarTableFilterValue,
  IBarTableSort,
} from 'src/types/bar';

import BarTableRow from '../bar-table-row';
import BarTableToolbar from '../bar-table-toolbar';

const STATUS_OPTIONS = [{ value: 'all', label: 'All' }, ...BAR_AVAILABILITY_STATUS_OPTIONS];

const TABLE_HEAD = [
  { id: 'itemName', label: 'Item Name', align: 'left', primary: true },
  { id: 'quantity', label: 'Quantity', align: 'left', primary: true },
  { id: 'price', label: 'Selling Price', align: 'right', primary: true },
  { id: 'availability', label: 'Availability', align: 'left', primary: true },
  { id: 'updatedAt', label: 'Updated', align: 'left', primary: true },
  { id: '', primary: true },
];

const defaultFilters: IBarTableFilters = {
  generalSearch: '',
  units: [],
  alertsEnabled: BarAlertStatus.ALL,
  minPrice: null,
  maxPrice: null,
  status: DocumentStatus.ACTIVE,
};

const defaultSortBy: IBarTableSort = {
  sortBy: 'updatedAt',
  order: 'desc',
};

const disablingBarItemInitialState: {
  state: boolean;
  index: number | null;
  message: string;
  item: IBarListItem | null;
} = {
  state: false,
  index: null,
  message: '',
  item: null,
};

export default function BarListView() {
  const [searchParams, setSearchParams] = useSearchParams();

  const smDown = useResponsive('down', 'sm');

  const currentTab = searchParams.get(TAB_QUERY);

  const { user: authUser } = useAuthContext();

  const { status, bar, error } = useAppSelector((state) => state.bar);

  const table = useTable({
    defaultRowsPerPage: 25,
  });

  const dispatch = useAppDispatcher();

  const { page, rowsPerPage } = table;

  const settings = useSettingsContext();

  const denseHeight = table.dense ? 60 : 80;

  const router = useRouter();

  const [disablingBarItem, setDisablingBarItem] = useState(disablingBarItemInitialState);

  const [filters, setFilters] = useState(defaultFilters);

  const [sort, setSort] = useState(defaultSortBy);

  const canReset = !isEqual(defaultFilters, filters);

  const [statusTab, setStatusTab] = useState<string>(currentTab || BarAvailabilityStatus.ALL);

  const handleFilters = useCallback(
    (name: string, value: IBarTableFilterValue) => {
      table.onResetPage();

      setFilters((prev) => ({
        ...prev,
        [name]: value,
      }));
    },
    [table]
  );

  const handleEditRow = useCallback(
    (id: string) => {
      router.push(paths.dashboard.bar.edit(id));
    },
    [router]
  );

  const handleFilterStatus = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setStatusTab(newValue);

      setSearchParams({ [TAB_QUERY]: newValue });
    },
    [setSearchParams]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
    setSearchParams({ [TAB_QUERY]: BarAvailabilityStatus.ALL });
  }, [setSearchParams]);

  const fetchPaginatedBarItems = useCallback(() => {
    if (
      some([filters.minPrice, filters.maxPrice], (value) => value !== null && Number(value) < 0) ||
      (filters.minPrice !== null &&
        filters.maxPrice !== null &&
        lt(Number(filters.maxPrice), Number(filters.minPrice)))
    ) {
      return;
    }

    dispatch(
      getBarItems({
        page: page + 1,
        limit: rowsPerPage,
        sortBy: sort.sortBy,
        sort: sort.order,
        generalSearch: filters.generalSearch,
        availability: statusTab === BarAvailabilityStatus.ALL ? null : statusTab,
        units: filters.units,
        unitsSeparator: ',',
        minPrice: filters.minPrice,
        maxPrice: filters.maxPrice,
        alertEnabled:
          filters.alertsEnabled === BarAlertStatus.ALL
            ? null
            : filters.alertsEnabled === BarAlertStatus.ENABLED,
        status: getDocumentAPIStatus(filters.status),
      })
    );

    if (page > 1) {
      logToucodeEvent(APP_EVENTS.TABLE_PAGINATION_MORE_THAN_TWO, {
        page,
        rowsPerPage,
      });
    }
  }, [dispatch, page, rowsPerPage, sort, filters, statusTab]);

  const downloadBarReport = useCallback(async () => {
    try {
      const response = await axiosInstance.get(
        `inventory/generate-inventory-report?page=0&limit=0&generalSearch=${
          filters.generalSearch
        }&itemCode=&itemName=&minQuantity=&maxQuantity=&minPrice=${
          filters.minPrice ?? ''
        }&maxPrice=${
          filters.maxPrice ?? ''
        }&suppliers=&locations=${BAR_LOCATION_ID}&units=${filters.units.join(
          ','
        )}&group=&availability=${
          statusTab === BarAvailabilityStatus.ALL ? '' : statusTab
        }&alertEnabled=${
          filters.alertsEnabled === BarAlertStatus.ALL
            ? ''
            : filters.alertsEnabled === BarAlertStatus.ENABLED
        }&alertSent=&sortBy=itemName&sort=asc&status=&createdBy=`,
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/pdf',
          },
        }
      );

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const downloadUrl = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = 'bar-stock-report.pdf';
      link.click();

      window.URL.revokeObjectURL(downloadUrl);
    } catch (e) {
      console.error('Error downloading the PDF:', e);
    }
  }, [filters, statusTab]);

  const handleDeleteRow = useCallback(
    async (item: IBarListItem, index: number) => {
      dispatch(removeBarItem({ itemId: item._id }));

      try {
        await dispatch(deleteBarItem({ item, index })).unwrap();

        fetchPaginatedBarItems();
        enqueueSnackbar('Bar item deleted');
      } catch (e) {
        if ('name' in e && e.name === 'AxiosError') {
          setDisablingBarItem({
            state: true,
            message: e.message,
            item,
            index,
          });
        } else if (typeof e === 'string') {
          enqueueSnackbar(e, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(e?.message, {
            variant: 'error',
          });
        }
      }
    },
    [dispatch, fetchPaginatedBarItems]
  );

  const handleDisableRow = useCallback(
    async (item: IBarListItem, index: number) => {
      dispatch(removeBarItem({ itemId: item._id }));

      try {
        await dispatch(disableBarItem({ item, index })).unwrap();

        fetchPaginatedBarItems();
        enqueueSnackbar('Bar item disabled');
      } catch (e) {
        if (typeof e === 'string') {
          enqueueSnackbar(e, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(e?.message, {
            variant: 'error',
          });
        }
      }
    },
    [dispatch, fetchPaginatedBarItems]
  );

  const handleReActivateRow = useCallback(
    async (item: IBarListItem, index: number) => {
      try {
        await dispatch(reActivateBarItem({ item })).unwrap();

        setFilters(defaultFilters);

        enqueueSnackbar('Bar item re-activated');
      } catch (e) {
        if (typeof e === 'string') {
          enqueueSnackbar(e, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(e?.message, {
            variant: 'error',
          });
        }
      }
    },
    [dispatch]
  );

  useEffect(() => {
    fetchPaginatedBarItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPaginatedBarItems]);

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Bar Items"
          links={[]}
          action={
            <Stack direction="row" gap={2}>
              {hasPermission(
                authUser,
                'any',
                AuthPermissions.DOWNLOAD_INVENTORY_REPORTS,
                AuthPermissions.ADMIN_PERMISSION,
                AuthPermissions.SUPER_ADMIN_PERMISSION
              ) && (
                <Tooltip title="Download Bar Report">
                  <Button variant="outlined" color="primary" onClick={downloadBarReport}>
                    <Iconify icon="eva:arrow-circle-down-fill" width={24} />
                  </Button>
                </Tooltip>
              )}

              {hasPermission(
                authUser,
                'any',
                AuthPermissions.CREATE_BAR_ITEM,
                AuthPermissions.ADMIN_PERMISSION,
                AuthPermissions.SUPER_ADMIN_PERMISSION
              ) && (
                <Button
                  component={RouterLink}
                  href={paths.dashboard.bar.new}
                  variant="contained"
                  startIcon={<Iconify icon="mingcute:add-line" />}
                >
                  {smDown ? 'New' : ' New Bar Item'}
                </Button>
              )}
            </Stack>
          }
          sx={{
            mb: { xs: 3 },
          }}
        />

        <Card>
          <Tabs
            value={statusTab}
            onChange={handleFilterStatus}
            sx={{
              px: 2.5,
              boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
            }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab key={tab.value} iconPosition="end" value={tab.value} label={tab.label} />
            ))}
          </Tabs>

          <BarTableToolbar
            filters={filters}
            onFilters={handleFilters}
            handleResetFilters={handleResetFilters}
            canReset={canReset}
          />

          <TableContainer sx={{ position: 'relative', overflow: 'hidden', height: '450px' }}>
            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                <TableHeadCustom
                  order={sort.order}
                  orderBy={sort.sortBy}
                  headLabel={TABLE_HEAD.filter(({ primary }) => {
                    if (settings.themeLayout === 'vertical') {
                      return primary;
                    }
                    return true;
                  })}
                  rowCount={bar.items.length}
                  numSelected={table.selected.length}
                  onSort={(id) => {
                    if (id === sort.sortBy) {
                      setSort((prev) => ({
                        ...prev,
                        order: prev.order === 'asc' ? 'desc' : 'asc',
                      }));
                    } else {
                      setSort({
                        sortBy: id,
                        order: 'asc',
                      });
                    }
                  }}
                />

                <TableBody>
                  {status === 'loading' ? (
                    [...Array(table.rowsPerPage)].map((i, index) => (
                      <TableSkeleton key={index} sx={{ height: denseHeight }} />
                    ))
                  ) : (
                    <>
                      {bar?.items.map((row: IBarListItem, index: number) => (
                        <BarTableRow
                          key={row._id}
                          row={row}
                          selected={table.selected.includes(row._id)}
                          onDeleteRow={() => handleDeleteRow(row, index)}
                          onEditRow={() => handleEditRow(row._id)}
                          onReActivateRow={() => handleReActivateRow(row, index)}
                        />
                      ))}
                    </>
                  )}

                  {status !== 'failure' && bar.count === 0 && (
                    <TableNoData message="No bar items found" notFound={bar.count === 0} />
                  )}

                  {status === 'failure' && <TableErrorData notFound error={error} />}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={bar.count}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={(value) => {
              table.onChangeDense(value);

              logToucodeEvent(APP_EVENTS.TABLE_COMPACT_MODE_TOGGLE, {
                compact: value,
                table: 'bar_table',
              });
            }}
          />
        </Card>
      </Container>

      <ConfirmDialog
        open={disablingBarItem.state}
        onClose={() => setDisablingBarItem(disablingBarItemInitialState)}
        title={`Action Required: Cannot Delete ${disablingBarItem.item?.itemName}`}
        content={disablingBarItem.message}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              if (disablingBarItem.item !== null && disablingBarItem.index !== null) {
                handleDisableRow(disablingBarItem.item, disablingBarItem.index);
                setDisablingBarItem(disablingBarItemInitialState);
              }
            }}
          >
            Temporarily Delete
          </Button>
        }
      />
    </>
  );
}

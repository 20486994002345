export class AuthPermissions {
  public static SUPER_ADMIN_PERMISSION = 'SUPER_ADMIN_PERMISSION';

  public static ADMIN_PERMISSION = 'ADMIN_PERMISSION';

  public static CREATE_INVENTORY_ITEM = 'CREATE_INVENTORY_ITEM';

  public static READ_INVENTORY_ITEM = 'READ_INVENTORY_ITEM';

  public static UPDATE_INVENTORY_ITEM = 'UPDATE_INVENTORY_ITEM';

  public static DELETE_INVENTORY_ITEM = 'DELETE_INVENTORY_ITEM';

  public static CREATE_KITCHEN_ITEM = 'CREATE_KITCHEN_ITEM';

  public static READ_KITCHEN_ITEM = 'READ_KITCHEN_ITEM';

  public static UPDATE_KITCHEN_ITEM = 'UPDATE_KITCHEN_ITEM';

  public static DELETE_KITCHEN_ITEM = 'DELETE_KITCHEN_ITEM';

  public static CREATE_BAR_ITEM = 'CREATE_BAR_ITEM';

  public static READ_BAR_ITEM = 'READ_BAR_ITEM';

  public static UPDATE_BAR_ITEM = 'UPDATE_BAR_ITEM';

  public static DELETE_BAR_ITEM = 'DELETE_BAR_ITEM';

  public static CREATE_USER = 'CREATE_USER';

  public static READ_USER = 'READ_USER';

  public static UPDATE_USER = 'UPDATE_USER';

  public static DELETE_USER = 'DELETE_USER';

  public static CHANGE_PASSWORD = 'CHANGE_PASSWORD';

  public static CREATE_UNIT = 'CREATE_UNIT';

  public static READ_UNIT = 'READ_UNIT';

  public static UPDATE_UNIT = 'UPDATE_UNIT';

  public static DELETE_UNIT = 'DELETE_UNIT';

  public static CREATE_LOCATION = 'CREATE_LOCATION';

  public static READ_LOCATION = 'READ_LOCATION';

  public static UPDATE_LOCATION = 'UPDATE_LOCATION';

  public static DELETE_LOCATION = 'DELETE_LOCATION';

  public static CREATE_SUPPLIER = 'CREATE_SUPPLIER';

  public static READ_SUPPLIER = 'READ_SUPPLIER';

  public static UPDATE_SUPPLIER = 'UPDATE_SUPPLIER';

  public static DELETE_SUPPLIER = 'DELETE_SUPPLIER';

  public static CREATE_CUSTOMER = 'CREATE_CUSTOMER';

  public static READ_CUSTOMER = 'READ_CUSTOMER';

  public static UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';

  public static DELETE_CUSTOMER = 'DELETE_CUSTOMER';

  public static BAR_INVENTORY_ALERT = 'BAR_INVENTORY_ALERT';

  public static KITCHEN_INVENTORY_ALERT = 'KITCHEN_INVENTORY_ALERT';

  public static READ_INVENTORY_ITEM_AVAILABILITY = 'READ_INVENTORY_ITEM_AVAILABILITY';

  public static CREATE_KITCHEN_INVENTORY_TRANSACTION = 'CREATE_KITCHEN_INVENTORY_TRANSACTION';

  public static READ_KITCHEN_INVENTORY_TRANSACTION = 'READ_KITCHEN_INVENTORY_TRANSACTION';

  public static UPDATE_KITCHEN_INVENTORY_TRANSACTION = 'UPDATE_KITCHEN_INVENTORY_TRANSACTION';

  public static DELETE_KITCHEN_INVENTORY_TRANSACTION = 'DELETE_KITCHEN_INVENTORY_TRANSACTION';

  public static CREATE_BAR_INVENTORY_TRANSACTION = 'CREATE_BAR_INVENTORY_TRANSACTION';

  public static READ_BAR_INVENTORY_TRANSACTION = 'READ_BAR_INVENTORY_TRANSACTION';

  public static UPDATE_BAR_INVENTORY_TRANSACTION = 'UPDATE_BAR_INVENTORY_TRANSACTION';

  public static DELETE_BAR_INVENTORY_TRANSACTION = 'DELETE_BAR_INVENTORY_TRANSACTION';

  public static VIEW_OPERATIONAL_ANALYTICS = 'VIEW_OPERATIONAL_ANALYTICS';

  public static VIEW_ADVANCE_ANALYTICS = 'VIEW_ADVANCE_ANALYTICS';

  public static DOWNLOAD_INVENTORY_REPORTS = 'DOWNLOAD_INVENTORY_REPORTS';

  public static CREATE_RESTAURANT_ITEM = 'CREATE_RESTAURANT_ITEM';

  public static READ_RESTAURANT_ITEM = 'READ_RESTAURANT_ITEM';

  public static UPDATE_RESTAURANT_ITEM = 'UPDATE_RESTAURANT_ITEM';

  public static DELETE_RESTAURANT_ITEM = 'DELETE_RESTAURANT_ITEM';
}

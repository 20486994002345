import { TRANSACTION_PAYMENT_STATUS, TRANSACTION_STATUS } from 'src/utils/common-types';

import { IBarListItem } from './bar';
import { IUnitListItem } from './unit';
import { ISupplierListItem } from './supplier';

export type IBarPurchaseOrdersTableFilterValue = string | string[];

export type IBarPurchaseOrdersTableFilters = {
  generalSearch: string;
  paymentStatus: TRANSACTION_PAYMENT_STATUS | null;
  purchaseDate: Date | null;
};

export type IBarPurchaseOrdersTableSort = {
  sortBy: string;
  order: 'asc' | 'desc';
};

export enum BarPurchaseOrdersStatus {
  ALL = 'all',
  CONFIRMED = 'completed',
  DRAFT = 'draft',
}

export const BAR_PURCHASE_ORDERS_STATUS_OPTIONS = [
  { value: BarPurchaseOrdersStatus.CONFIRMED, label: 'Confirmed' },
  { value: BarPurchaseOrdersStatus.DRAFT, label: 'Draft' },
];

export interface IBarPurchaseOrderListItem {
  _id: string;
  transactionCode: string;
  reason: string;
  description: string | null;
  supplier: ISupplierListItem | null;
  discount: number;
  isPercentageDiscount: boolean;
  totalAfterDiscount: number;
  status: TRANSACTION_STATUS;
  paymentStatus: TRANSACTION_PAYMENT_STATUS;
  updatedAt: string;
  purchaseOrDispatchDate: string;
}

export interface IBarPurchaseOrderChildItem {
  _id: string;
  inventoryItem: Pick<IBarListItem, '_id' | 'itemName' | 'itemCode'>;
  unit: IUnitListItem;
  quantity: number;
  unitPrice: number;
  discount: number;
  totalAfterDiscount: number;
}

export interface IBarPurchaseOrderDetailedItem {
  _id: string;
  transactionCode: string;
  reason: string;
  description: string | null;
  supplier: ISupplierListItem;
  discount: number;
  paidAmount: number;
  isPercentageDiscount: boolean;
  totalAfterDiscount: number;
  status: TRANSACTION_STATUS;
  paymentStatus: TRANSACTION_PAYMENT_STATUS;
  updatedAt: string;
  purchaseOrDispatchDate: string;
  items: IBarPurchaseOrderChildItem[];
}

export interface IBarPurchaseOrderPayment {
  _id: string;
  amount: number;
  updatedAt: string;
  paymentMethod: string;
  paymentDate: string;
}

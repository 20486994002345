export enum DocumentStatus {
  ACTIVE = 'active',
  TEMPORARLY_DELETE = 'Temporarily Deleted',
}

export function getDocumentAPIStatus(status: string) {
  switch (status) {
    case DocumentStatus.ACTIVE:
      return 'active';
    case DocumentStatus.TEMPORARLY_DELETE:
      return 'disabled';
    default:
      return null;
  }
}

export enum PaymentDueStatus {
  ALL = 'all',
  OUTSTANDING = 'outstanding',
  SETTLED = 'settled',
  OVERPAID = 'over_paid',
}

export function getPaymentDueAPIStatus(status: string) {
  switch (status) {
    case PaymentDueStatus.OUTSTANDING:
      return 'outstanding';
    case PaymentDueStatus.SETTLED:
      return 'settled';
    case PaymentDueStatus.OVERPAID:
      return 'over_paid';
    default:
      return null;
  }
}

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { PAYMENT_METHOD, TRANSACTION_STATUS, TRANSACTION_TYPE } from 'src/utils/common-types';

import { KITCHEN_LOCATION_ID } from 'src/config-global';

import {
  IKitchenPurchaseOrderPayment,
  IKitchenPurchaseOrderDetailedItem,
  IKitchenPurchaseOrderListItem,
} from 'src/types/kitchen-purchase';

import axios from '../../utils/axios';

interface IInitialState {
  status: 'idle' | 'loading' | 'success' | 'failure';
  error: string | undefined;
  transactions: {
    count: number;
    page: number;
    pageSize: number;
    pageCount: number;
    transactions: IKitchenPurchaseOrderListItem[];
  };
  item: IKitchenPurchaseOrderDetailedItem | null;

  payments: {
    status: 'idle' | 'loading' | 'success' | 'failure';
    error: string | undefined;
    payments: {
      count: number;
      page: number;
      pageSize: number;
      pageCount: number;
      payments: IKitchenPurchaseOrderPayment[];
    };
  };
}

const initialState: IInitialState = {
  status: 'idle',
  error: undefined,
  transactions: {
    count: 0,
    page: 1,
    pageSize: 10,
    pageCount: 0,
    transactions: [],
  },
  item: null,

  payments: {
    status: 'idle',
    error: undefined,
    payments: {
      count: 0,
      page: 0,
      pageSize: 0,
      pageCount: 0,
      payments: [],
    },
  },
};

const kitchenPurchaseOrders = createSlice({
  name: 'kitchenPurchaseOrders',
  initialState,
  reducers: {
    removeKitchenPurchaseOrderItem: (state, action) => {
      const { itemId } = action.payload;

      const index = state.transactions.transactions.findIndex((item) => item._id === itemId);

      if (index > -1) {
        action.payload.index = index; // Store the index for rollback
        state.transactions.transactions = state.transactions.transactions.filter(
          (item) => item._id !== itemId
        );
      }
    },
    rollbackKitchenPurchaseOrderDeletion: (state, action) => {
      const { item, index } = action.payload;

      state.transactions.transactions.splice(index, 0, item);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getKitchenPurchaseOrders.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getKitchenPurchaseOrders.fulfilled, (state, action) => {
        state.status = 'success';
        state.transactions = action.payload;
      })
      .addCase(getKitchenPurchaseOrders.rejected, (state, action) => {
        state.status = 'failure';
        state.error = action.error.message;
      })
      .addCase(getKitchenPurchaseOrderById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getKitchenPurchaseOrderById.fulfilled, (state, action) => {
        state.status = 'success';
        state.item = action.payload;
      })
      .addCase(getKitchenPurchaseOrderById.rejected, (state, action) => {
        state.status = 'failure';
        state.error = action.error.message;
      })

      .addCase(getKitchenPurchasePayments.pending, (state) => {
        state.payments.status = 'loading';
      })
      .addCase(getKitchenPurchasePayments.fulfilled, (state, action) => {
        state.payments.status = 'success';
        state.payments.payments = action.payload;
      })
      .addCase(getKitchenPurchasePayments.rejected, (state, action) => {
        state.payments.status = 'failure';
        state.payments.error = action.error.message;
      });
  },
});

export default kitchenPurchaseOrders.reducer;

export const { removeKitchenPurchaseOrderItem, rollbackKitchenPurchaseOrderDeletion } =
  kitchenPurchaseOrders.actions;

export const getKitchenPurchaseOrders = createAsyncThunk(
  'kitchen/purchase/transactions',
  async (
    params: {
      page: number;
      limit: number;
      generalSearch?: string | null;
      status?: string | null;
      paymentStatus?: string | null;
      purchaseDate?: Date | null;
      minTotalValue?: number | null;
      maxTotalValue?: number | null;
      sortBy?: string | null;
      sort?: 'asc' | 'desc' | null;
    } = {
      page: 1,
      limit: 10,
      generalSearch: null,
      status: null,
      paymentStatus: null,
      purchaseDate: null,
      minTotalValue: null,
      maxTotalValue: null,
      sort: 'desc',
      sortBy: 'updatedAt',
    }
  ) => {
    const {
      page,
      limit,
      generalSearch,
      status,
      paymentStatus,
      purchaseDate,
      minTotalValue,
      maxTotalValue,
      sortBy,
      sort,
    } = params;

    try {
      const response = await axios.get(
        `inventory/kitchen/transaction?page=${page + 1}&limit=${limit}&generalSearch=${
          generalSearch ?? ''
        }&transactionCode=&status=${status ?? ''}&transactionType=purchase&paymentStatus=${
          paymentStatus ?? ''
        }&purchaseOrDispatchDate=${purchaseDate ?? ''}&minTotalValue=${
          minTotalValue ?? ''
        }&maxTotalValue=${
          maxTotalValue ?? ''
        }&isDiscounted=&suppliers=&locations=${KITCHEN_LOCATION_ID}&updatedBy=&sortBy=${
          sortBy ?? ''
        }&sort=${sort ?? ''}&createdBy=&customers=`
      );

      return response.data.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const getKitchenPurchaseOrderById = createAsyncThunk(
  'kitchen/purchase/transaction/id',
  async (params: { itemID: string }) => {
    try {
      const { itemID } = params;
      const response = await axios.get(`inventory/kitchen/transaction/${itemID}`);

      return response.data.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const deleteKitchenPurchaseOrder = createAsyncThunk(
  'kitchen/purchase/delete',
  async (params: { item: IKitchenPurchaseOrderListItem; index: number }, { dispatch }) => {
    const { item, index } = params;

    try {
      const response = await axios.delete(`inventory/kitchen/transaction/${item._id}`);
      return response.data.data;
    } catch (err) {
      dispatch(rollbackKitchenPurchaseOrderDeletion({ item, index }));
      throw err;
    }
  }
);

export interface TCreateKitchenPurchaseOrderItem {
  inventoryItem: string;
  quantity: number;
  unit: string;
  // description: string | null;
  unitPrice: number;
  discount: number;
  // isPercentageDiscount: boolean;
  totalAfterDiscount: number;
  // purchaseDate: Date;
  // expiryDate: Date;
}

export interface TCreateKitchenPurchaseOrder {
  location: string;
  supplier: string | null;
  reason: string | null;
  description: string | null;
  discount: number;
  isPercentageDiscount: boolean;
  totalAfterDiscount: number;
  roundOffTotal: number;
  transactionType: TRANSACTION_TYPE;
  purchaseOrDispatchDate: Date;
  status: TRANSACTION_STATUS;
  items: TCreateKitchenPurchaseOrderItem[];
  payments: TCreateKitchenPurchaseOrderPaymentRow[];
  paidAmount: number;
  subTotal: number;
}

export interface TCreateKitchenPurchaseOrderPayment {
  subTotal: number;
  totalAfterDiscount: number;
  roundOffTotal: number;
  discount: number;
  paidAmount: number;
  payments: TCreateKitchenPurchaseOrderPaymentRow[];
}

export interface TCreateKitchenPurchaseOrderPaymentRow {
  method: PAYMENT_METHOD;
  amount: number;
  paymentDate: Date;
  isNew: boolean;
  _id?: string;
}

export const createKitchenPurchaseOrder = createAsyncThunk(
  'kitchen/purchaseOrder/create',
  async (params: {
    poTransaction: Omit<TCreateKitchenPurchaseOrder, 'payments' | 'paidAmount'>;
  }) => {
    const { poTransaction } = params;
    const response = await axios.post(`inventory/kitchen/transaction`, poTransaction);
    return response.data.data;
  }
);

export const updateKitchenPurchaseOrder = createAsyncThunk(
  'kitchen/purchaseOrder/update',
  async (params: {
    purchaseOrderId: string;
    poTransaction: Omit<TCreateKitchenPurchaseOrder, 'payments' | 'paidAmount'>;
  }) => {
    const { poTransaction, purchaseOrderId } = params;
    const response = await axios.put(
      `inventory/kitchen/transaction/${purchaseOrderId}`,
      poTransaction
    );
    return response.data.data;
  }
);

export const updateKitchenPurchaseCompletedOrder = createAsyncThunk(
  'kitchen/purchaseOrder/completed/update',
  async (params: {
    purchaseOrderId: string;
    poTransaction: Omit<TCreateKitchenPurchaseOrder, 'payments' | 'paidAmount'>;
  }) => {
    const { poTransaction, purchaseOrderId } = params;
    const response = await axios.put(`inventory/transaction/${purchaseOrderId}`, poTransaction);
    return response.data.data;
  }
);

export const kitchenPurchaseOrderMarkAsCompleted = createAsyncThunk(
  'kitchen/purchaseOrder/marAsCompleted',
  async (params: { purchaseOrderId: string; purchaseOrder: IKitchenPurchaseOrderDetailedItem }) => {
    const { purchaseOrderId, purchaseOrder } = params;

    const response = await axios.put(`/inventory/kitchen/transaction/${purchaseOrderId}`, {
      ...purchaseOrder,
      status: TRANSACTION_STATUS.COMPLETED,
      supplier: purchaseOrder.supplier?._id,
      items: purchaseOrder.items.map((item) => ({
        ...item,
        inventoryItem: item.inventoryItem._id,
        unit: item.unit._id,
      })),
    });
    return response.data.data;
  }
);

export const getKitchenPurchasePayments = createAsyncThunk(
  'kitchen/purchaseOrder/payments',
  async (params: { purchaseOrderId: string; page?: number; limit?: number }) => {
    try {
      const response = await axios.get(
        `payment?page=${params.page ?? ''}&limit=${
          params.limit ?? ''
        }&generalSearch=&invoiceNumber=&inventoryTransactions=${
          params.purchaseOrderId
        }&inventoryTransactionDrafts&suppliers=&customers=&transactionType=&paymentMethod&minTotalValue=&maxTotalValue=&currency&status=&startDate&endDate&createdBy=&updatedBy=&sortBy=createdAt&sort=asc&transactionStatus=`
      );

      return response.data.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const kitchenPurchaseOrderMakePayment = createAsyncThunk(
  'kitchen/purchaseOrder/payments/create',
  async (params: {
    purchaseOrderId: string;
    payment: Omit<TCreateKitchenPurchaseOrderPaymentRow, '_id'>;
    supplier?: string;
  }) => {
    const { payment, purchaseOrderId, supplier } = params;
    const response = await axios.post('payment', {
      inventoryTransaction: purchaseOrderId,
      supplier: supplier ?? null,
      amount: payment.amount,
      paymentMethod: payment.method,
      paymentDate: payment.paymentDate,
    });
    return response.data.data;
  }
);

export const kitchenPurchaseOrderUpdatePayment = createAsyncThunk(
  'kitchen/purchaseOrder/payments/update',
  async (params: {
    paymentId: string;
    purchaseOrderId: string;
    payment: Omit<TCreateKitchenPurchaseOrderPaymentRow, '_id'>;
    supplier?: string;
  }) => {
    const { payment, purchaseOrderId, supplier, paymentId } = params;
    const response = await axios.put(`payment/${paymentId}`, {
      inventoryTransaction: purchaseOrderId,
      supplier: supplier ?? null,
      amount: payment.amount,
      paymentMethod: payment.method,
      paymentDate: payment.paymentDate,
    });
    return response.data.data;
  }
);

export const kitchenPurchaseOrderDeletePayment = createAsyncThunk(
  'kitchen/purchaseOrder/payments/delete',
  async (params: { paymentId: string }) => {
    const { paymentId } = params;
    const response = await axios.delete(`payment/${paymentId}`);
    return response.data.data;
  }
);

export const updateKitchenPurchaseDiscount = createAsyncThunk(
  'bar/dispatchOrder/update/discount',
  async (params: {
    purchaseOrderId: string;
    transaction: IKitchenPurchaseOrderDetailedItem;
    discount: number;
    totalAfterDiscount: number;
  }) => {
    const { transaction, purchaseOrderId, discount, totalAfterDiscount } = params;
    const response = await axios.put(`inventory/transaction/${purchaseOrderId}`, {
      ...{
        ...transaction,
        items: transaction.items.map((item) => ({
          ...item,
          inventoryItem: item.inventoryItem._id,
          unit: item.unit._id,
        })),
        supplier: transaction.supplier?._id,
      },
      discount,
      totalAfterDiscount,
    });
    return response.data.data;
  }
);

import { PaymentDueStatus } from './common';
import { ILocationListItem } from './location';

export type ICustomerTableFilterValue = string | string[];

export type ICustomerTableFilters = {
  generalSearch: string;
};

export type ICustomerTableSort = {
  sortBy: string;
  order: 'asc' | 'desc';
};

export const CUSTOMER_DEBT_STATUS_OPTIONS = [
  { value: PaymentDueStatus.OUTSTANDING, label: 'Outstanding' },
  { value: PaymentDueStatus.SETTLED, label: 'Settled' },
  { value: PaymentDueStatus.OVERPAID, label: 'Over Paid' },
];

export type ICustomerItem = {
  _id: string;
  customerName: string;
  email: string | null;
  phoneNumber: string | null;
  secondaryPhoneNumber: string | null;
  address: string | null;
  creditLimit: number | null;
  status: string;
  createdAt: string;
  updatedAt: string;
  location: ILocationListItem[];
  totalTransactionAmount?: number;
  totalPaidAmount?: number;
};
